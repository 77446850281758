<template>
  <div class="reputation">
    <streamer :title="title"></streamer>

    <div class="reputation-block">
      <div class="reputation-block-back">
        <img src="../../assets/my/my-reputation.png" alt="" />
      </div>

      <div class="reputation-block-display">
        <div class="display-list">
          <div class="display-list-left"><div class="left-title">{{ reputationNum }}</div></div>
          <div class="display-list-right" @click="reputationBtn">
            <div class="right-title">声望明细</div>
            <div class="right-img">
              <img src="../../assets/common/common-whilt-right.png" alt="" />
            </div>
          </div>
        </div>

        <div class="display-list">
          <div class="display-list-left">
            <div class="left-text">我的声望等级：</div>
            <div class="left-lv">V{{ reputationNum <= 50? 1 : reputationNum <= 210? 2: 3 }}</div>
          </div>
          <div class="display-list-right" @click="ruleBtn">
            <div class="right-title">规则</div>
            <div class="right-img">
              <img src="../../assets/common/common-whilt-right.png" alt="" />
            </div>
          </div>
        </div>

        <div class="display-text">声望值越高,权益越多!</div>
      </div>
    </div>

    <div class="reputation-equity">
      <div class="reputation-equity-title">
        <div class="title-img">
          <img src="../../assets/my/my-reputation-lv.png" alt="" />
        </div>
        <div class="title-text">声望等级权益声望等级</div>
      </div>

      <div class="reputation-equity-display">
        <div class="display-list" v-for="(item, index) in 3" :key="index">
          <div class="display-list-left">
            <div class="display-list-left-title">
              {{
                index == 0
                  ? "声望值<50"
                  : index == 1
                  ? "声望等级V2"
                  : "声望等级Vi "
              }}
            </div>
            <div class="display-list-left-title">
              {{
                index == 0
                  ? "声望值<50"
                  : index == 1
                  ? "声望值>=210"
                  : "声望值<210"
              }}
            </div>
          </div>
          <div class="display-list-right">
            {{
              index == 0
                ? "限制接单，限制提现"
                : index == 1
                ? "可以接单，可以提现"
                : "  新用户初始声望， 享有平台所有功能"
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import streamer from "../../components/streamer/streamer.vue";

import { my } from "../../api/my";

export default {
  components: {
    streamer,
  },
  data() {
    return {
      title: "我的声望", // 标题
      // 
      reputationNum: 0
    };
  },
  created() {
    this.infoMet()
  },
  methods: {
    infoMet() {
      my().then((res) => {
        console.log("获取个人信息", res);
        if (res.code == 200) {
          this.reputationNum = res.result.popularity
        }
      });
    },
    // 点击跳转 声望规则
    ruleBtn() {
      this.$router.push({
        path: "/my-reputation-rule",
      });
    },

    // 声望列表
    reputationBtn() {
      this.$router.push({
        path: "/signin-bill",
        query: {
          index: 2,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.reputation {
  height: 100vh;
  background: #fff;
  .reputation-block {
    position: relative;
    margin: 0 10px;
    min-height: 211px;
    margin-top: 16px;
    .reputation-block-back {
      position: absolute;
      width: 100%;
      display: flex;
      height: 211px;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .reputation-block-display {
      position: relative;
      padding-top: 42px;
      .display-list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 30px;
        box-sizing: border-box;

        .display-list-left {
          display: flex;
          align-items: center;
          .left-title {
            font-size: 30px;
            font-family: Hiragino Sans GB;
            font-weight: bold;
            color: #ffffff;
            letter-spacing: 1px;
          }

          .left-text {
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #fffefe;
          }

          .left-lv {
            font-size: 20px;
            font-family: Zapfino;
            font-weight: 400;
            color: #ffffff;
          }
        }
        .display-list-right {
          display: flex;
          align-items: baseline;
          .right-title {
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #fffefe;
          }
          .right-img {
            width: 6.5px;
            height: 10.5px;
            margin-left: 6px;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }

      .display-list:last-child {
        margin-top: 9px;
        margin-bottom: 0;
      }

      .display-text {
        font-size: 14.5px;
        font-family: Hiragino Sans GB;
        font-weight: bold;
        color: #fffefe;
        letter-spacing: 14px;
        text-align: center;
        margin-top: 50px;
      }
    }
  }

  .reputation-equity {
    padding: 0 27.5px;
    box-sizing: border-box;
    margin-top: 37.5px;
    .reputation-equity-title {
      display: flex;
      align-items: center;
      .title-img {
        width: 28px;
        height: 28px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .title-text {
        font-size: 15px;
        font-family: Hiragino Sans GB;
        font-weight: bold;
        color: #b9781f;
        margin-left: 8.5px;
      }
    }

    .reputation-equity-display {
      margin-top: 14px;
      .display-list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #e9e9e9;
        padding: 16px 32px;
        box-sizing: border-box;
        .display-list-left {
          .display-list-left-title {
            font-size: 13.5px;
            font-family: Hiragino Sans GB;
            font-weight: normal;
            color: #000000;
            margin-bottom: 5px;
          }
          .display-list-left-title:last-child {
            margin-bottom: 0;
          }
        }
        .display-list-right {
          width: 130px;
          font-size: 13.5px;
          font-family: Hiragino Sans GB;
          font-weight: normal;
          color: #000000;
          text-align: left;
        }
      }
    }
  }
}
</style>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"home-streamer"},[_vm._m(0),_c('div',{staticClass:"home-streamer-navigation"},[_c('div',{staticClass:"navigation-first"},[_c('div',{staticClass:"navigation-first-left",on:{"click":_vm.showPopupMet}},[_vm._m(1),_c('div',{staticClass:"left-title"},[_vm._v(" 赚"+_vm._s(_vm.recommendObj.amountUnit == undefined ? "0" : _vm.recommendObj.amountUnit)+"元 ")])]),_c('div',{staticClass:"navigation-first-center",on:{"click":_vm.searchBtn}},[_c('van-field',{attrs:{"left-icon":_vm.searchImg,"placeholder":"搜索任务标题、编号"},model:{value:(_vm.searchTask),callback:function ($$v) {_vm.searchTask=$$v},expression:"searchTask"}})],1)]),_c('div',{staticClass:"navigation-second"},[_c('van-swipe',{staticClass:"my-swipe",attrs:{"autoplay":3000,"indicator-color":"white"}},_vm._l((_vm.swiperList),function(item,index){return _c('van-swipe-item',{key:index},[_c('img',{attrs:{"src":item.imgUrl,"alt":""},on:{"click":function($event){return _vm.swiperMet(item)}}})])}),1)],1),_c('div',{staticClass:"navigation-thirdly"},[_c('div',{staticClass:"navigation-thirdly-notice"},[_c('inform',{attrs:{"title":_vm.informList}})],1)]),_c('div',{staticClass:"navigation-fourthly",style:({
          '--top': _vm.homeIndex == 0 ? '17.5px' : '15px',
        })},_vm._l((2),function(item,index){return _c('div',{key:index,staticClass:"navigation-fourthly-list",on:{"click":function($event){return _vm.tabsBtn(index)}}},[_c('div',{staticClass:"list-title",style:({ color: _vm.tabsIndex == index ? '#000000' : '' })},[_vm._v(" "+_vm._s(index == 0 ? "推荐赚钱" : "热门任务")+" ")]),(_vm.tabsIndex == index)?_c('div',{staticClass:"list-lint"}):_vm._e()])}),0)])]),_c('div',{staticClass:"home-streamer-lint"}),_c('div',{staticClass:"home-scrolling"},[_c('van-list',{attrs:{"finished":_vm.finished,"finished-text":_vm.list.length == 0 ? '' : ''},on:{"load":_vm.onLoad},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"home-scrolling-list",on:{"click":function($event){return _vm.particularsBtn(item.id)}}},[_c('div',{staticClass:"list-left"},[(index == 0 || index == 1 || index == 2)?_c('img',{staticClass:"list-left-back",attrs:{"src":index == 0
                ? require('../../assets/home/home-icon-first.png')
                : index == 1
                ? require('../../assets/home/home-icon-second.png')
                : index == 2
                ? require('../../assets/home/home-icon-thirdly.png')
                : '',"alt":""}}):_vm._e(),_c('img',{staticClass:"list-left-circle",attrs:{"src":item.mainImg,"alt":""}})]),_c('div',{staticClass:"list-right"},[_c('div',{staticClass:"list-right-block"},[_c('div',{staticClass:"block-title"},[_c('div',{staticClass:"block-title-headline"},[_vm._v(_vm._s(item.title))]),_vm._l((item.tabs),function(item,index){return _c('div',{key:index,staticClass:"block-title-explode"},[_vm._v(" "+_vm._s(item == 1 ? "推" : item == 2 ? "顶" : "")+" ")])})],2),_c('div',{staticClass:"block-label"},[_c('div',{staticClass:"block-label-list"},[_vm._v(" "+_vm._s(item.type == 0 ? "小程序体验" : item.type == 1 ? "关注体验" : item.type == 2 ? "砍掉互帮" : item.type == 3 ? "试用试玩" : item.type == 4 ? "高阶任务" : item.type == 5 ? "其他任务" : "")+" ")]),_c('div',{staticClass:"block-label-list"},[_vm._v(" "+_vm._s(item.name)+" ")])]),_c('div',{staticClass:"block-span"},[_vm._v(" 已赚"),_c('span',{staticStyle:{"color":"#e94e44"}},[_vm._v(_vm._s(item.totalEarnPrice))]),_vm._v("元 接单限时 "),_c('span',{staticStyle:{"color":"#e94e44"}},[_vm._v(_vm._s(_vm.formatTime(item.receivingTimeLimit)))])])])]),_c('div',{staticClass:"list-relocating"},[_vm._v(_vm._s(item.amountUnit == null ? '': item.amountUnit)+"元")])])}),_c('empty',{attrs:{"list":_vm.list}})],2)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-streamer-img"},[_c('img',{attrs:{"src":require("../../assets/home/home-streamer.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left-img"},[_c('img',{attrs:{"src":require("../../assets/home/home-money.gif"),"alt":""}})])
}]

export { render, staticRenderFns }
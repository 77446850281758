<template>
  <div class="login">
    <div class="login-first">
      <img src="../../assets/common/common-login.png" alt="" />
    </div>

    <div class="login-second">
      <img src="../../assets/common/logo-60@3x.png" alt="" />
    </div>
    <div class="login-thirdly">
      <van-field
        :left-icon="phoneImg"
        v-model="loginPhone"
        placeholder="请输入手机号"
      />
    </div>
    <div class="login-fourthly">
      <van-field
        v-model="loginVerification"
        :left-icon="verifiImg"
        center
        clearable
        placeholder="请输入验证码"
      >
        <template #button>
          <div class="login-fourthly-button" @click="codeMet" v-show="verifiIs">
            获取验证码
          </div>
          <div class="login-fourthly-button" v-show="!verifiIs">
            {{ count }}S
          </div>
        </template>
      </van-field>
    </div>
    <div class="login-fifth">
      <van-field
        :left-icon="invitationImg"
        v-model="loginInvitation"
        placeholder="请输入邀请码(选填)"
      />
    </div>

    <div class="login-sixth" @click="loginMet">登陆</div>

    <div class="login-seventh">
      <van-checkbox v-model="loginChecked" @change="checkedChange">
        我已阅读并同意赚蚂蚁赚钱<span @click.stop="agreementMet(0)"
          >用户协议</span
        >和<span @click.stop="agreementMet(1)">隐私协议</span>
      </van-checkbox>
    </div>
  </div>
</template>

<script>
import { loginRegister, loginPhone, loginVerification } from "../../api/login";
import { getVerifyCode, phoneLogin } from "@/api/apiService";
import { Toast } from "vant";
export default {
  data() {
    return {
      loginPhone: "", // 电话号码
      loginVerification: "", // 验证码
      loginInvitation: "", // 邀请码
      loginChecked: true, // 复选框
      //- --------
      // 发送验证码后进行倒计时操作
      verifiIs: true,
      // 倒计时的参数
      count: 0,
      // 图片暂缓
      imgs: "https://backendweb.661222.com/jeecg-bootsys/common/static/",
      //---------------------------
      phoneImg: require("../../assets/common/common-phone.png"), // 手机号图片
      verifiImg: require("../../assets/common/common-verifiIs.png"), // 验证码图片
      invitationImg: require("../../assets/common/common-invitecode.png"), // 邀请码图片
    };
  },
  created() {
    if (this.$route.query.invitation) {
      this.loginInvitation = this.$route.query.invitation;
    }
    // this.isH5Browser();
  },
  methods: {
    // 点击切换是否勾选
    checkedChange(e) {
      this.loginChecked = e;
    },
    // 点击获取验证码
    codeMet() {
      if (this.loginPhone == "") return Toast("请输入手机号！");
      this.verificationClick();
      getVerifyCode({ phone: this.loginPhone }).then((res) => {
        if (res.result !== 1) return Toast("手机格式错");
        // if(res.result === 1)
        // {
        //   this.loginVerification = res.code;
        // }else {
        //   Toast('手机格式错')
        // }
      });
    },

    // 获取倒计时
    verificationClick() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.verifiIs = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
            this.verifiIs = true;
          }
        }, 1000);
      }
    },

    // 点击登录
    loginMet() {
      console.log("--开始登录--");
      if (this.loginChecked == false) return Toast("请先勾选协议 ~");
      if (this.loginPhone == "") return Toast("请输入手机号！");
      if (this.loginVerification == "") return Toast("请输入验证码！");

      let params = {
        phone: this.loginPhone, // 登录
        code: this.loginVerification, // 验证码
      };

      phoneLogin(params).then((res) => {
        console.log("调用登录接口返回", res);
        if (res.result === 0) {
          this.loginVerification = "";
          return Toast("验证码错误");
        }
        if (res.code == 200) {
          if (res.result.userInfo.userIcon != null) {
            console.log("userIcon", res.result.userInfo.userIcon);
            if (res.result.userInfo.userIcon.substr(0, 3) != "htt") {
              res.result.userInfo.userIcon =
                this.imgs + res.result.userInfo.userIcon;
            }
          }

          // 获取token
          localStorage.setItem("token", res.result.token); // 存储token
          console.log("村粗对象", res.result.userInfo);
          localStorage.setItem("info", JSON.stringify(res.result.userInfo)); // 存储个人信息

          this.$router.replace({
            path: "/home",
          });

          this.appTokenMet(res.result.token);
        } else if (res.code == 500) {
          // 走注册
          this.registerMet();
        }
      });
    },
    // 注册请求
    registerMet() {
      if (this.loginChecked == false) {
        Toast("请先勾选协议 ~");
        return;
      }
      if (this.loginPhone == "") {
        Toast("请输入手机号！");
        return;
      }
      if (this.loginVerification == "") Toast("请输入验证码！");

      let params = {
        phone: this.loginPhone, // 手机号
        code: this.loginVerification, // 验证码
        inviteCode: this.loginInvitation, // 邀请码
      };

      loginRegister(params).then((res) => {
        console.log("点击注册");
        if (res.code == 200) {
          if (res.result.userInfo.userIcon != null) {
            if (res.result.userInfo.userIcon.substr(0, 3) != "htt") {
              res.result.userInfo.userIcon =
                this.imgs + res.result.userInfo.userIcon;
            }
          }
          // 获取token
          localStorage.setItem("token", res.result.token); // 存储token
          console.log("村粗对象", res.result.userInfo);
          localStorage.setItem("info", JSON.stringify(res.result.userInfo)); // 存储个人信息
          this.$router.replace({
            path: "/home",
          });
          this.appTokenMet(res.result.token);
        }
      });
    },

    // 点击跳转协议
    agreementMet(index) {
      if (index == 0) {
        this.$router.push({
          path: "/agreement-user",
        });
      } else {
        this.$router.push({
          path: "/agreement-privacy",
        });
      }
    },

    // 用户登录后传入token给app方
    appTokenMet(token) {
      var u = navigator.userAgent;
      //   // app = navigator.appVersion;
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android终端或者uc浏览器
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

      if (isAndroid) {
        console.log("安卓模块");
        window.androidShangJIn.userToken(token);
      } else if (isiOS) {
        console.log("ios模块");
        window.webkit.messageHandlers.userToken.postMessage(token);
      }
    },
    //驗證H5是在手机APP内或者是在手机浏览器打开
    isH5Browser() {
      const ua = navigator.userAgent;
      const android = ua.indexOf("Android") > -1;
      const ios = ua.indexOf("Ios") > -1;
      let uuid = "";
      let ip = "";
      if (android) {
        const array = ua.split(",");
        uuid = array[0].replace("Android_", "");
        ip = array[1].replace("IP_", "");
      } else {
        const array = ua.split(",");
        uuid = array[0].replace("iOS_", "");
        ip = array[1].replace("IP_", "");
      }
      console.log(ua);
      console.log("UUID", uuid);
      console.log("IP", ip);
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .van-field__value {
  display: flex;
  align-items: center;
}
.login {
  min-height: 100vh;
  background: #fff;
  .login-first {
    width: 100%;
    height: 175px;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .login-second {
    width: 86.5px;
    height: 86.5px;
    border-radius: 5px;
    margin: 0 auto;
    margin-top: 41px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 5px;
    }
  }

  .login-thirdly {
    border-radius: 5px;
    margin: 0 auto;
    margin-top: 42px;
    display: flex;
    border-radius: 5px;
    margin: 0 auto;
    margin-top: 16.5px;
    align-items: center;
    justify-content: center;

    /deep/ .van-field__left-icon {
      display: flex;
      align-items: center;
    }

    /deep/ .van-field__control {
      padding-left: 12px;
    }

    /deep/ .van-cell {
      padding: 4.5px 7px;
      box-sizing: border-box;
      border-radius: 5px;
      background: #f7f7f7;
      height: 40px;
      width: 100%;
      margin: 0 33px;
    }
  }

  .login-fourthly {
    display: flex;
    border-radius: 5px;
    margin: 0 auto;
    margin-top: 16.5px;
    align-items: center;
    justify-content: center;

    /deep/ .van-field__left-icon {
      display: flex;
      align-items: center;
    }

    /deep/ .van-field__control {
      padding-left: 12px;
    }

    /deep/ .van-cell {
      width: 100%;
      padding: 4.5px 7px;
      box-sizing: border-box;
      border-radius: 5px;
      background: #f7f7f7;
      height: 40px;
      margin: 0 33px;
    }

    .login-fourthly-button {
      font-size: 12.5px;
      font-family: Hiragino Sans GB;
      font-weight: normal;
      color: #e74c45;
      text-align: center;
      border-left: 1px solid;
      padding: 0 0 0 9px;
      height: 20px;
      line-height: 20px;
    }
  }

  .login-fifth {
    display: flex;
    border-radius: 5px;
    margin: 0 auto;
    margin-top: 16.5px;
    align-items: center;
    justify-content: center;

    /deep/ .van-field__left-icon {
      display: flex;
      align-items: center;
    }

    /deep/ .van-field__control {
      padding-left: 12px;
    }

    /deep/ .van-cell {
      width: 100%;
      padding: 4.5px 7px;
      box-sizing: border-box;
      height: 40px;
      border-radius: 5px;
      background: #f7f7f7;
      margin: 0 33px;
    }
  }

  .login-sixth {
    margin: 0 52px;
    height: 35px;
    background: #e74c45;
    border-radius: 16px;
    text-align: center;
    line-height: 35px;
    font-size: 16px;
    font-family: Hiragino Sans GB;
    font-weight: normal;
    color: #ffffff;
    margin-top: 20.5px;
  }

  .login-seventh {
    font-size: 11.5px;
    font-family: Hiragino Sans GB;
    font-weight: normal;
    color: #000000;
    display: flex;
    align-items: center;
    height: 30px;
    letter-spacing: 1px;
    justify-content: center;
    margin-top: 20px;

    span {
      color: #3886ed;
    }

    /deep/ .van-checkbox__icon {
      height: 16px !important;
      font-size: 13px;
    }

    /deep/ .van-icon-success:before {
      margin-left: 1px;
    }

    /deep/ .van-checkbox__icon--checked .van-icon {
      width: 16px;
      height: 16px;
      line-height: 16px;
      margin-right: 4px;
    }

    /deep/ .van-checkbox__icon--round .van-icon {
      width: 16px;
      height: 16px;
      line-height: 16px;
      margin-right: 4px;
    }
  }
}
</style>
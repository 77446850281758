var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c('router-view'),(
    _vm.route == 'home' ||
    _vm.route == 'ranking' ||
    _vm.route == 'fission' ||
    _vm.route == 'signin' ||
    _vm.route == 'my'
    )?_c('div',[_c('tab-bar')],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/login/login.vue'  // 登录页面
import Login_google from '../components/login/login_google.vue'  // 登录页面
import Home from '../views/home/home.vue' // 任务大厅
import Ranking from '../views/ranking/ranking.vue' // 排行榜
import Search from '../views/search/search.vue' // 搜索页
import Fission from '../views/fission/fission.vue'  // 超级裂变
import Signin from '../views/signin/signin.vue' // 签到奖励
import My from '../views/my/my.vue' // 我的页面

// 详情区域
import Particulars from '../components/particulars/particulars.vue'  // 任务详情
import rankingRule from '../views/ranking/ranking-rule.vue' // 排行规则详情
import History from '../views/ranking/ranking-history.vue' // 排行榜-查看历史
import Bill from '../views/signin/signin-bill.vue' // 签到-
import signinRule from '../views/signin/signin-rule.vue' // 签到-规则
import fissionInvitation from '../views/fission/fission-invitation.vue' // 超级裂变 --  立即邀请
import Squeeze from '../components/squeeze/squeeze.vue' // 落地页
import Information from '../views/my/my-information.vue' // 我的页面 --  我的信息
import Modification from '../views/my/my-modification.vue' //  我的页面 -- 修改名称
import Regard from '../views/my/my-regard.vue' // 我的页面 -- 关于我们
import agreementUser from '../components/agreement/agreement-user.vue' // 我的页面 -- 用户协议
import agreementPrivacy from '../components/agreement/agreement-privacy.vue'  // 我的页面 -- 隐私协议
import Deal from '../views/my/my-deal.vue' // 我的页面 -- 交易流水
import orderReceiving from '../views/my/my-order-receiving.vue' // 我的页面 -- 接单模块
import browsingHistory from '../views/my/my-browsing-history.vue' // 我的页面 -- 浏览记录
import Contact from '../views/my/my-contact.vue' // 我的页面 -- 联系我们
import Advice from '../views/my/my-acceptance-advice.vue' // 我的页面 -- 消息通知
import bankCard from '../views/my/my-bank-card.vue' // 我的页面 -- 银行卡
import Reputation from '../views/my/my-reputation.vue' // 我的页面 -- 我的声望
import reputationRule from '../views/my/my-reputation-rule.vue' // 我的页面 -- 声望规则
import Set from '../views/my/my-set.vue' // 我的页面 --  设置

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


const routes = [
  // ------------任务大厅-------------------
  // {
  //   path: '/',
  //   name: 'home',
  //   meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
  //   component: Home
  // },
  {
    path: '/',
    name: 'index',
    component: ()=> import('../views/LandingPage.vue')
  },
  {
    path: '/home',
    name: 'home',
    meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: Home
  },
  // {
  //   path: '/index',
  //   name: 'index',
  //   component: ()=> import('../views/LandingPage.vue')
  // },
  // ------------登录 功能 页面-------------------
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/login_google',
    name: 'login_google',
    component: Login_google
  },
  {
    path: '/squeeze',
    name: 'squeeze',
    component: Squeeze
  },
  {
    path: '/agreement-user',
    name: 'agreementUser',
    component: agreementUser
  },
  {
    path: '/agreement-privacy',
    name: 'agreementPrivacy',
    component: agreementPrivacy
  },
  // ------------搜索页面-------------------
  {
    path: '/search',
    name: 'search',
    meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: Search
  },
  // ------------排行榜-------------------
  {
    path: '/ranking',
    name: 'ranking',
    meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: Ranking
  },
  {
    path: '/ranking-rule', // 排行榜规则详情
    name: 'ranking-rule',
    meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: rankingRule
  },
  {
    path: '/ranking-history', // 排行榜规则详情
    name: 'history',
    meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: History
  },
  // --------------超级裂变-----------------
  {
    path: '/fission',
    name: 'fission',
    meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: Fission
  },
  {
    path: '/fission-invitation',  // 立即邀请
    name: 'fission-invitation',
    meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: fissionInvitation
  },
  // --------------签到奖励-----------------
  {
    path: '/signin',
    name: 'signin',
    meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: Signin
  },
  {
    path: '/signin-bill', // 
    name: 'bill',
    meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: Bill
  },
  {
    path: '/signin-rule',
    name: 'signin-rule',
    meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: signinRule
  },
  // ------------我的页面-------------------
  {
    path: '/my', // 我的页面
    name: 'my',
    meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: My
  },
  {
    path: '/my-information', // 我的信息
    name: 'information',
    meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: Information
  },
  {
    path: '/my-modification', // 修改昵称
    name: 'modification',
    meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: Modification
  },
  {
    path: '/my-regard', // 关于我们
    name: 'regard',
    meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: Regard
  },
  {
    path: '/my-deal', // 交易流水
    name: 'Deal',
    meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: Deal
  },
  {
    path: '/my-order-receiving', // 关于我们
    name: 'orderReceiving',
    meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: orderReceiving
  },

  {
    path: '/my-browsing-history', // 浏览记录
    name: 'browsingHistory',
    meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: browsingHistory
  },
  // {
  //   path: '/my-buil', // 浏览记录
  //   name: 'myBuil',
  //   meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
  //   component: myBuil
  // },
  {
    path: '/my-contact', // 联系我们
    name: 'contact',
    meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: Contact
  },
  {
    path: '/my-advice', // 联系我们
    name: 'advice',
    meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: Advice
  },
  {
    path: '/my-bankcard', // 银行卡
    name: 'bankcard',
    meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: bankCard
  },
  {
    path: '/my-reputation', // 声望
    name: 'Reputation',
    meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: Reputation
  },
  {
    path: '/my-reputation-rule', // 声望
    name: 'reputationRule',
    meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: reputationRule
  },
  {
    path: '/my-set', // 设置
    name: 'set',
    meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: Set
  },

  // ------------任务详情-------------------
  {
    path: '/particulars',
    name: 'particulars',
    meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: Particulars
  },
]

const router = new VueRouter({
  // mode: 'hash',
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  base: process.env.BASE_URL,
  routes
})

// 路由守卫
router.beforeEach((to, from, next) => {
  if (to.matched.some(res => res.meta.isLogin)) {//判断是否需要登录
    console.log(to);
    if (localStorage['token']) {
      next();
    } else {
      next({
        path: "/login"
      });
    }
  } else {
    next()
  }
})

export default router

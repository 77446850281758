<template>
  <div class="modification">
    <streamer :title="title"></streamer>

    <div class="modification-input">
      <van-field
        v-model="value"
        label="昵称"
        clearable
        placeholder="修改昵称"
      />
    </div>

    <div class="modification-button">
      <div class="modification-button-title" @click="alterMet">保 存</div>
    </div>
  </div>
</template>

<script>
import streamer from "../../components/streamer/streamer.vue";

import { myNicknamealter } from '../../api/my' 

import { Toast } from "vant";

export default {
  components: {
    streamer,
  },
  data() {
    return {
      value: "", // 昵称
      title: "修改昵称", // 标题
    };
  },
  created()  {
    this.value = this.$route.query.name
  },
  methods: {
    // 返回上一层
    backRight() {
      this.$router.go(-1);
    },
    // 修改昵称
    alterMet() {
      let params = {
        username: this.value
      }

      myNicknamealter(params).then(res => {
        if(res.code == 200) {
          Toast.success(res.message);
          this.backRight()
        }
      })
    }
  },
};
</script>

<style lang="less" scoped>
.modification {
  height: 100vh;
  background: #ffffff;
  position: relative;
  .modification-streamer {
    position: relative;
    height: 90.5px;
    width: 100%;

    .modification-streamer-img {
      width: 100%;
      height: 100%;
      position: absolute;
      display: flex;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .modification-streamer-display {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 54.5px 13.5px 0;
      box-sizing: border-box;
      position: relative;
      .display-left {
        width: 9.5px;
        height: 15.5px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .display-center {
        font-size: 15.5px;
        font-family: Hiragino Sans GB;
        font-weight: bold;
        color: #ffffff;
      }
      .display-right {
        width: 9.5px;
        height: 15.5px;
      }
    }
  }
  .modification-input {
    height: 65px;
    border-bottom: 1px solid #e9e9e9;
    display: flex;
    align-items: center;

    /deep/ .van-cell {
      padding: 10px 33.5px;
    }
  }

  .modification-button {
    width: 100%;
    position: fixed;
    bottom: 70px;
    display: flex;
    justify-content: center;
    .modification-button-title {
      font-size: 18.5px;
      font-family: Hiragino Sans GB;
      font-weight: normal;
      color: #ffffff;

      width: 290px;
      height: 42px;
      background: linear-gradient(0deg, #ed4034 0%, #ed3325 51%, #da261a 100%);
      border-radius: 5px;

      text-align: center;
      line-height: 42px;
    }
  }
}
</style>
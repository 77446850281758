var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"squeeze"},[_c('van-swipe',{staticStyle:{"height":"100vh"},attrs:{"vertical":"","loop":false}},_vm._l((4),function(item,index){return _c('van-swipe-item',{key:index},[_c('div',{staticClass:"squeeze-back"},[_c('img',{staticClass:"squeeze-back-img",attrs:{"src":index == 0
              ? require('../../assets/common/common-squeeze-swiper1.png')
              : index == 1
              ? require('../../assets/common/common-squeeze-swiper2.png')
              : index == 2
              ? require('../../assets/common/common-squeeze-swiper3.png')
              : require('../../assets/common/common-squeeze-swiper4.png'),"alt":""}})])])}),1),_c('div',{staticClass:"squeeze-button",on:{"click":_vm.dowloadMet}},[_c('div',{staticClass:"squeeze-button-title"},[_vm._v("开始赚钱")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
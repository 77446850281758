<template>
  <div class="my">
    <div class="my-streamer">
      <div class="my-streamer-block">
        <div class="my-streamer-img">
          <img src="../../assets/my/my-streamer.png" alt="" />
        </div>

        <div
          class="my-streamer-right"
          v-if="userInfo.isCheck == 0"
          @click="userAudit"
        >
          账号待审核
        </div>

        <div class="my-streamer-information">
          <div class="information-block">
            <div class="information-block-left" @click="informationBtn">
              <div class="left-portrait">
                <img :src="userInfo.userIcon" alt="" />
              </div>
            </div>
            <div class="information-block-right">
              <div class="right-name">{{ userInfo.username }}</div>
              <div class="right-id">ID: {{ userInfo.id }}</div>
              <div class="right-display" @click="reputationBtn">
                <div class="right-display-prestige">
                  声望等级: V{{
                    userInfo.popularity <= 50
                      ? 1
                      : userInfo.popularity <= 210
                      ? 2
                      : 3
                  }}
                </div>
                <div class="right-display-schedule">
                  <div class="schedule">
                    <div
                      v-if="userInfo.popularity <= 50"
                      class="schedule-back"
                      :style="{
                        '--width':
                          (Number(userInfo.popularity) / 50) * 100 + '%',
                      }"
                    ></div>
                    <div
                      v-else-if="userInfo.popularity <= 210"
                      class="schedule-back"
                      :style="{
                        '--width':
                          (Number(userInfo.popularity) / 210) * 100 + '%',
                      }"
                    ></div>
                    <div
                      v-else
                      class="schedule-back"
                      :style="{
                        '--width': '100%',
                      }"
                    ></div>
                  </div>
                  <div class="title">{{ userInfo.popularity }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="my-streamer-lint"></div>

    <div class="my-earnings">
      <div class="my-earnings-back">
        <img src="../../assets/my/my-earnings.png" alt="" />
      </div>

      <div class="my-earnings-block">
        <div class="block-first">
          <div class="block-first-display">
            <div class="display-img">
              <img src="../../assets/my/my-earnings-diamond.png" alt="" />
            </div>
            <div class="display-title">账户余额</div>
          </div>
          <div class="block-first-right" @click="withdrawBtn">去提现</div>
        </div>

        <div class="block-second">
          <div class="block-second-title">
            {{
              this.userInfo.usedMoney == undefined
                ? 0
                : this.userInfo.usedMoney
            }}元
          </div>
          <div class="block-second-deal" @click="DealBtn">
            <div class="deal-title">交易流水</div>
            <div class="deal-img">
              <img src="../../assets/my/my-earnings-right.png" alt="" />
            </div>
          </div>
        </div>

        <div class="block-thirdly">
          <div
            class="block-thirdly-list"
            v-for="(item, index) in 4"
            :key="index"
          >
            <div class="block-thirdly-list-title">
              {{
                index == 0
                  ? "任务赏金"
                  : index == 1
                  ? "裂变赏金"
                  : index == 2
                  ? "签到赏金"
                  : "每日任务"
              }}
            </div>
            <div class="block-thirdly-list-price">
              {{
                index == 0
                  ? userInfo.yesterdayTaskPrice == undefined
                    ? 0 + "元"
                    : userInfo.yesterdayTaskPrice + "元"
                  : index == 1
                  ? userInfo.yesterdayFissionPrice == undefined
                    ? 0 + "元"
                    : userInfo.yesterdayFissionPrice + "元"
                  : index == 2
                  ? userInfo.yesterdaySignPrice == undefined
                    ? 0 + "元"
                    : userInfo.yesterdaySignPrice + "元"
                  : userInfo.yesterdayEveryPrice == undefined
                  ? 0 + "元"
                  : userInfo.yesterdayEveryPrice + "元"
              }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="my-orderReceiving">
      <div class="my-orderReceiving-headline">
        <div class="headline-left">我的接单</div>
        <div class="headline-right" @click="historyBtn">
          <div class="headline-right-title">浏览记录</div>
          <div class="headline-right-img">
            <img src="../../assets/my/my-earnings-black-right.png" alt="" />
          </div>
        </div>
      </div>

      <div class="my-orderReceiving-display">
        <div
          class="display-list"
          v-for="(item, index) in 4"
          :key="index"
          @click="orderBtn(index)"
        >
          <div
            class="display-list-img"
            :style="{
              width:
                index == 1
                  ? '17.5px'
                  : index == 2
                  ? '20.5px'
                  : index == 3
                  ? '24.5px'
                  : '24.5px',
            }"
          >
            <img
              :src="
                index == 0
                  ? require('../../assets/my/my-receiving-icon-1.png')
                  : index == 1
                  ? require('../../assets/my/my-receiving-icon-2.png')
                  : index == 2
                  ? require('../../assets/my/my-receiving-icon-3.png')
                  : require('../../assets/my/my-receiving-icon-4.png')
              "
              alt=""
            />
          </div>
          <div class="display-list-title">
            {{
              index == 0
                ? "待提交"
                : index == 1
                ? "审核中"
                : index == 2
                ? "已通过"
                : "未通过"
            }}
          </div>
        </div>
      </div>
    </div>

    <div class="my-apprentice" @click="apprenticeMet">
      <div class="my-apprentice-img">
        <img src="../../assets/my/my-apprentice.png" alt="" />
      </div>

      <div class="my-apprentice-block">
        <div class="apprentice-block-display">
          <div class="display-title">代理收益</div>
          <div class="display-img">
            <img src="../../assets/my/my-apprentice-icon.png" alt="" />
          </div>
        </div>

        <div class="apprentice-block-title">无限级代理，收益无限叠加</div>
      </div>
    </div>

    <div class="my-modules">
      <div
        class="my-modules-list"
        v-for="(item, index) in 6"
        :key="index"
        @click="modulesBtn(index)"
      >
        <div
          class="my-modules-list-img"
          :style="{
            width:
              index == 0
                ? '23px'
                : index == 1
                ? '27px'
                : index == 2
                ? '28.5px'
                : index == 3
                ? '29.5px'
                : index == 4
                ? '23px'
                : '28.5px',
            height:
              index == 0
                ? '27px'
                : index == 1
                ? '27px'
                : index == 2
                ? '28.5px'
                : index == 3
                ? '28px'
                : index == 4
                ? '28.5px'
                : '28px',
          }"
        >
          <img
            :src="
              index == 0
                ? require('../../assets/my/my-modules-icon-1.png')
                : index == 1
                ? require('../../assets/my/my-modules-icon-2.png')
                : index == 2
                ? require('../../assets/my/my-modules-icon-3.png')
                : index == 3
                ? require('../../assets/my/my-modules-icon-4.gif')
                : index == 4
                ? require('../../assets/my/my-modules-icon-5.png')
                : index == 5
                ? require('../../assets/my/my-modules-icon-6.png')
                : ''
            "
            alt=""
          />
        </div>
        <div class="my-modules-list-title">
          {{
            index == 0
              ? "账单"
              : index == 1
              ? "我的消息"
              : index == 2
              ? "联系我们"
              : index == 3
              ? "超级裂变赚钱"
              : index == 4
              ? "每日任务"
              : index == 5
              ? "签到奖励 "
              : ""
          }}
        </div>
      </div>
    </div>

    <!-- 没有提现事件 -->
    <div class="withdraw">
      <van-popup v-model="withdrawShow">
        <div class="withdraw-div">
          <div class="withdraw-div-img">
            <img src="../../assets/my/my-withdrawPopup.png" alt="" />
          </div>
          <div class="withdraw-div-close" @click="closeBtn">
            <img src="../../assets/common/common-close.png" alt="" />
          </div>

          <div class="withdraw-div-block">
            <div class="div-block-title">
              {{
                auditShow == 0
                  ? "您提交的提现账号审核失败,请提交其它账号提交其它账号"
                  : auditShow == 1
                  ? "您提交的提现账号正在审核中，请耐心等待!"
                  : auditShow == 2
                  ? "确定将尾号****的账号设置为默认提现方式?"
                  : auditShow == 3
                  ? "您当前还没有任何提现方式 "
                  : ""
              }}
            </div>
            <div class="div-block-button" @click="bindingBtn">去绑定</div>
          </div>
        </div>
      </van-popup>
    </div>

    <!-- 有提现事件 -->
    <div class="deposit">
      <van-popup v-model="depositShow">
        <div class="deposit-block">
          <div class="deposit-block-img">
            <img src="../../assets/my/my-withdrawPopup-deposit.png" alt="" />
          </div>

          <div class="deposit-block-div">
            <div class="block-div-list" v-for="(item, index) in 5" :key="index">
              <div class="block-div-list-left">
                {{
                  index == 0
                    ? "账号类型"
                    : index == 1
                    ? "银行名称"
                    : index == 2
                    ? "分行名称"
                    : index == 3
                    ? "持卡人姓名"
                    : index == 4
                    ? "银行账号"
                    : ""
                }}
              </div>
              <div class="block-div-list-right">
                <div class="right-input" v-if="index == 0">
                  <van-radio-group v-model="bankType" direction="horizontal">
                    <van-radio name="0">银行卡</van-radio>
                    <!-- <van-radio name="1">USDT</van-radio> -->
                  </van-radio-group>
                </div>
                <div class="right-input" v-if="index == 1">
                  <van-field v-model="bank" placeholder="请输入银行名称" />
                </div>
                <div class="right-input" v-if="index == 2">
                  <van-field
                    v-model="bankDetail"
                    placeholder="请输入分行名称"
                  />
                </div>
                <div class="right-input" v-if="index == 3">
                  <van-field
                    v-model="bankUser"
                    placeholder="请输入持卡人姓名"
                  />
                </div>
                <div class="right-input" v-if="index == 4">
                  <van-field v-model="bankNo" placeholder="请输入银行账户" />
                </div>
                <!-- <div class="right-input" v-if="index == 5">
                  <van-field
                    v-model="usdtAddress"
                    placeholder="请输入usdt收款地址"
                  />
                </div> -->
              </div>
            </div>

            <div class="block-div-button" @click="submitMet">提交</div>
          </div>
        </div>
      </van-popup>
    </div>

    <!--账号审核-->
    <div class="audit">
      <van-popup v-model="auditIsShow">
        <div class="audit-div">
          <div class="audit-div-img">
            <img src="../../assets/my/my-withdrawPopup.png" alt="" />
          </div>

          <div class="audit-div-close" @click="closeBtn">
            <img src="../../assets/common/common-close.png" alt="" />
          </div>

          <div class="audit-div-block">
            <div class="block-title">账号审核</div>
            <div class="block-text">
              您的账号还未通过审核，请根据要求，上传本人提现的银行卡拍照，审核时间约为1-2个工作日(可配)
            </div>

            <div class="block-link">
              {{ fileimg.substring(fileimg.length - 17) }}
            </div>

            <div class="block-button" @click="uploadingMet">点击上传</div>
            <div class="block-button" @click="subMet">提交</div>
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>


<script>
import { my, mybank, myNewbank, myuserCheck } from "../../api/my";

import { Toast } from "vant";

export default {
  data() {
    return {
      withdrawShow: false, // 未绑定弹出框
      depositShow: false, // 已绑定弹出框
      auditShow: 3, // 审核失败 0  正在审核 1 默认提示 2 未绑定
      // 用户点击审核
      auditIsShow: false,
      //---个人信息存储------------
      userInfo: {},
      // ---银行卡绑定------
      bankType: "0", // 银行卡类型 0银行卡 1USDT
      bank: "", // 银行名称
      bankDetail: "", // 分行名称
      bankUser: "", //	持卡人姓名
      bankNo: "", // 银行账户
      usdtAddress: "", // usdt收款地址
      isDefault: "", // 是否默认提现方式 1是
      // 图片暂缓
      // imgs: "https://after.byqp99.com/jeecg-bootsys/common/static/",
      // imgs: "https://backendweb.661222.com/jeecg-boot/sys/common/static/",
      imgs: process.env.VUE_APP_IMAGE_URL,
      fileimg: "",
    };
  },
  created() {
    // 获取用户个人信息
    this.myMet();
  },
  mounted() {
    window.transferConsultResult = this.transferConsultResult;
  },
  methods: {
    transferConsultResult(value) {
      console.log("接收到ios参数1", value);
      let result = JSON.parse(value);

      if (result.img != null) {
        if (result.img.substr(0, 3) != "htt") {
          this.fileimg = this.imgs + result.img;
        }
      }

      console.log("第一个地方的上传");
    },
    // 获取个人信息接口数据
    myMet() {
      my().then((res) => {
        console.log("获取个人信息", res);
        if (res.code == 200) {
          if (res.result.userIcon != null) {
            if (res.result.userIcon.substr(0, 3) != "htt") {
              res.result.userIcon = this.imgs + res.result.userIcon;
            }
          }

          this.userInfo = res.result;

          localStorage.setItem("userImg", res.result.userIcon);
        }
      });
    },
    // 点击跳转我的信息
    informationBtn() {
      this.$router.push({
        path: "/my-information",
      });
    },

    // 点击跳转声望界面
    reputationBtn() {
      this.$router.push({
        path: "/my-reputation",
      });
    },
    // 点击提现按钮
    withdrawBtn() {
      // return Toast("提现通道维护,如有疑问请洽客服！");
      if (this.userInfo.isCheck == 0) {
        this.userAudit();
        return;
      }

      // 判断当前用户是否绑定过银行卡
      this.mybankMet();
    },

    mybankMet() {
      let params = {};

      mybank(params).then((res) => {
        if (res.code == 200) {
          this.withdrawShow = false;

          // 某个判断条件 成立跳转
          this.$router.push({
            path: "/my-bankcard",
          });
        } else {
          this.auditShow = 3;
          this.withdrawShow = true;
        }
      });
    },

    submitMet() {
      let params = {
        bankType: this.bankType, // 银行卡类型 0银行卡 1USDT
        bank: this.bank, // 银行名称
        bankDetail: this.bankDetail, // 分行名称
        bankUser: this.bankUser, //	持卡人姓名
        bankNo: this.bankNo, // 银行账户
        usdtAddress: this.usdtAddress, // usdt收款地址
        isDefault: "0", // 是否默认提现方式 1是
      };

      myNewbank(params).then((res) => {
        if (res.code == 200) {
          Toast.success(res.message);
          this.depositShow = false;
          this.withdrawShow = false;
        } else {
          Toast(res.message);
          this.depositShow = false;
          this.withdrawShow = false;
        }
      });

      console.log("提交参数", params);
    },

    // 点击跳转  交易流水
    DealBtn() {
      this.$router.push({
        path: "/my-deal",
      });
    },
    // 点击 关闭
    closeBtn() {
      this.withdrawShow = false;
      this.auditIsShow = false;
    },
    // 点击去绑定
    bindingBtn() {
      this.depositShow = true;
    },
    // 用户点击接单，进入接单页面
    orderBtn(index) {
      index = index + 1;

      this.$router.push({
        path: "/my-order-receiving",
        query: {
          index: index,
        },
      });
    },
    // 点击接单模块  浏览记录
    historyBtn() {
      this.$router.push({
        path: "/my-browsing-history",
      });
    },
    // 点击模块区域 0 账单
    modulesBtn(index) {
      if (index == 0) {
        this.$router.push({
          path: "/signin-bill",
        });
      } else if (index == 1) {
        this.$router.push({
          path: "/my-advice",
        });
      } else if (index == 2) {
        this.$router.push({
          path: "/my-contact",
        });
      } else if (index == 3) {
        this.$router.replace({
          path: "/fission",
        });
      } else if (index == 4) {
        this.$router.replace({
          path: "/signin",
        });
      } else if (index == 5) {
        this.$router.replace({
          path: "/signin",
        });
      }
    },

    // 点击邀请好友
    apprenticeMet() {
      this.$router.replace({
        path: "/fission",
      });
    },

    // 用户点击账号待审核
    userAudit() {
      this.auditIsShow = true;
    },
    // 用户点击上传
    uploadingMet() {
      var u = navigator.userAgent,
        app = navigator.appVersion;
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android终端或者uc浏览器
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isAndroid) {
        window.androidShangJIn.uploadingImgid("1");
        console.log("安卓模块");
      } else if (isiOS) {
        console.log("ios模块");
        window.webkit.messageHandlers.uploadingImgId.postMessage("1");
      }
    },

    // 账号审核用户点击提交
    subMet() {
      if (this.fileimg == "") {
        Toast("请上传对应资格!");
        return;
      }

      let params = {
        wxImage: this.fileimg,
      };

      myuserCheck(params).then((res) => {
        if (res.code == 200) {
          Toast("提交成功");
          this.auditIsShow = false;
          // 获取用户个人信息
          this.myMet();
        }
      });
    },
  },
};
</script>


<style lang="less" scoped>
.my {
  .my-streamer {
    height: 136.5px;
    position: fixed;
    z-index: 3;
    width: 100%;
    .my-streamer-block {
      height: 136.5px;
      width: 100%;
      position: relative;
      display: flex;
      align-items: flex-end;
      margin-bottom: 10px;

      .my-streamer-img {
        width: 100%;
        height: 100%;
        position: absolute;
        display: flex;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .my-streamer-right {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 15px;
        font-family: Hiragino Sans GB;
        font-weight: normal;
        color: #000;
        text-decoration: underline;
      }

      .my-streamer-information {
        position: relative;
        margin-bottom: 10px;
        width: 100%;
        padding: 0 15px;
        box-sizing: border-box;

        .information-block {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;

          .information-block-left {
            .left-portrait {
              width: 43px;
              height: 43px;
              border-radius: 50%;

              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
              }
            }
          }
          .information-block-right {
            margin-left: 12px;
            .right-name {
              font-size: 15px;
              font-family: Hiragino Sans GB;
              font-weight: normal;
              color: #ffffff;
            }
            .right-id {
              font-size: 14px;
              font-family: Hiragino Sans GB;
              font-weight: normal;
              color: #ffffff;
              margin: 12px 0;
            }

            .right-display {
              display: flex;
              align-items: center;
              .right-display-prestige {
                font-size: 14px;
                font-family: Hiragino Sans GB;
                font-weight: normal;
                color: #ffffff;
              }
              .right-display-schedule {
                display: flex;
                align-items: center;

                .schedule {
                  width: 122.5px;
                  height: 9.5px;
                  background: #ffffff;
                  border-radius: 4.5px;
                  display: flex;
                  align-items: center;
                  padding: 0 1%;
                  box-sizing: border-box;
                  margin-left: 12px;
                  .schedule-back {
                    width: var(--width);
                    height: 6.5px;
                    background: linear-gradient(
                      0deg,
                      #ffff00 0%,
                      #ef876c 0%,
                      #f25344 51%,
                      #e42a1e 100%
                    );
                    border-radius: 3px;
                  }
                }
                .title {
                  font-size: 10px;
                  font-family: Hiragino Sans GB;
                  font-weight: normal;
                  color: #ffffff;
                  margin-left: 9px;
                }
              }
            }
          }
        }
      }
    }
  }
  .my-streamer-lint {
    width: 100%;
    height: 136.5px;
  }
  .my-earnings {
    position: relative;
    height: 180.5px;
    margin: 11.5px 20px 0;

    .my-earnings-back {
      width: 100%;
      height: 100%;
      position: absolute;
      display: flex;

      img {
        width: 100%;
        height: 100%;
      }
    }
    .my-earnings-block {
      position: relative;
      .block-first {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 21.5px;
        box-sizing: border-box;

        .block-first-display {
          display: flex;
          align-items: center;
          .display-img {
            width: 18px;
            height: 16px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .display-title {
            font-size: 12px;
            font-family: Hiragino Sans GB;
            font-weight: normal;
            color: #ffffff;
            margin-left: 6.5px;
          }
        }
        .block-first-right {
          width: 58px;
          height: 19.5px;
          background: #e32f23;
          border-radius: 5px;
          border: 1px solid;
          position: absolute;
          right: 8.5px;

          font-size: 12px;
          font-family: Hiragino Sans GB;
          font-weight: normal;
          color: #ffffff;
          text-align: center;
          line-height: 19.5px;
        }
      }
      .block-second {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-top: 9.5px;
        .block-second-title {
          font-size: 25px;
          font-family: Hiragino Sans GB;
          font-weight: bold;
          color: #ffffff;
        }
        .block-second-deal {
          display: flex;
          align-items: center;
          position: absolute;
          right: 8.5px;
          top: 0;

          .deal-title {
            font-size: 11px;
            font-family: Hiragino Sans GB;
            font-weight: normal;
            color: #ffffff;
            margin-top: 2px;
          }
          .deal-img {
            width: 7px;
            margin-left: 4.5px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .block-thirdly {
        display: flex;
        justify-content: space-around;
        margin-top: 30px;
        .block-thirdly-list {
          text-align: center;
          .block-thirdly-list-title {
            font-size: 13.5px;
            font-family: Hiragino Sans GB;
            font-weight: normal;
            color: #ffffff;
          }
          .block-thirdly-list-price {
            font-size: 13.5px;
            font-family: Hiragino Sans GB;
            font-weight: bold;
            color: #ffffff;
            margin-top: 12.5px;
          }
        }
      }
    }
  }
  .my-orderReceiving {
    height: 125.5px;
    background: #ffffff;
    border-radius: 5px;
    margin: 14px 12.5px 0;
    .my-orderReceiving-headline {
      height: 40px;
      padding: 0 20px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .headline-left {
        font-size: 15px;
        font-family: Hiragino Sans GB;
        font-weight: normal;
        color: #000000;
      }
      .headline-right {
        display: flex;
        align-items: center;
        .headline-right-title {
          font-size: 14px;
          font-family: Hiragino Sans GB;
          font-weight: normal;
          color: #696767;
        }
        .headline-right-img {
          width: 9.5px;
          margin-top: 3px;
          margin-left: 6px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .my-orderReceiving-display {
      display: flex;
      justify-content: space-around;
      margin-top: 20px;
      .display-list {
        text-align: center;
        .display-list-img {
          width: 25px;
          height: 24px;
          margin: 0 auto;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .display-list-title {
          margin-top: 10.5px;
          font-size: 14px;
          font-family: Hiragino Sans GB;
          font-weight: normal;
          color: #000000;
        }
      }
    }
  }
  .my-apprentice {
    height: 93.5px;
    margin: 0 12.5px;
    position: relative;

    .my-apprentice-img {
      width: 100%;
      height: 100%;
      position: absolute;
      display: flex;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .my-apprentice-block {
      position: relative;
      padding-top: 30.5px;
      padding-left: 50px;

      .apprentice-block-display {
        display: flex;
        align-items: center;
        width: 100px;
        .display-title {
          font-size: 12px;
          font-family: Hiragino Sans GB;
          font-weight: bold;
          color: #ffda22;
        }
        .display-img {
          width: 17.5px;
          height: 21.5px;
          margin-left: 2.5px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .apprentice-block-title {
        font-size: 15px;
        font-family: Hiragino Sans GB;
        font-weight: bold;
        color: #ffffff;
        margin-top: 5px;
      }
    }
  }
  .my-modules {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    background: #ffffff;
    border-radius: 5px;
    margin: 15px 12px 80px;
    padding: 20px 15px;
    box-sizing: border-box;
    .my-modules-list {
      width: calc(100% / 3);
      text-align: center;
      margin: 10px 0;
      .my-modules-list-img {
        margin: 0 auto;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .my-modules-list-title {
        margin-top: 4.5px;
        font-size: 14px;
        font-family: Hiragino Sans GB;
        font-weight: 500;
        color: #000000;
      }
    }
  }

  // 未绑定弹出框
  .withdraw {
    /deep/ .van-popup {
      border-radius: 10px !important;
    }

    /deep/.van-popup {
      background-color: rgba(255, 255, 255, 0) !important;
    }

    .withdraw-div {
      width: 285px;
      height: 364.5px;
      position: relative;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      .withdraw-div-img {
        width: 100%;
        height: 364.5px;
        position: absolute;
        display: flex;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .withdraw-div-close {
        width: 12px;
        height: 12px;
        position: absolute;
        right: 20.5px;
        top: 58px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .withdraw-div-block {
        position: relative;
        // padding-top: 204px;
        padding: 0 30px;
        box-sizing: border-box;
        margin-bottom: 40px;
        .div-block-title {
          font-size: 17px;
          font-family: Hiragino Sans GB;
          font-weight: bold;
          color: #000000;
          text-align: center;
        }
        .div-block-button {
          width: 205px;
          height: 37.5px;
          background: linear-gradient(
            0deg,
            #ffff00 0%,
            #ef876c 0%,
            #f25344 51%,
            #e42a1e 100%
          );
          border-radius: 5px;
          margin: 39px auto 0;
          font-size: 14.5px;
          font-family: Hiragino Sans GB;
          font-weight: normal;
          color: #ffffff;
          text-align: center;
          line-height: 37.5px;
        }
      }
    }
  }

  // 已绑定弹出框
  .deposit {
    /deep/ .van-popup {
      border-radius: 10px !important;
    }

    /deep/.van-popup {
      background-color: rgba(255, 255, 255, 0) !important;
    }

    /deep/ .van-field__control {
      padding-left: 10px;
    }

    .deposit-block {
      width: 319px;
      height: 434.5px;
      position: relative;
      .deposit-block-img {
        height: 142.5px;
        width: 100%;
        position: absolute;
        display: flex;
        z-index: 1;
        img {
          width: 100%;
          height: 100%;
        }
      }

      .deposit-block-div {
        background: #ffffff;
        height: 359px;
        width: 100%;
        position: relative;
        top: 75px;
        padding-top: 90px;
        box-sizing: border-box;

        .block-div-list {
          width: 100%;
          height: 23px;
          margin-bottom: 10px;
          padding: 0 20px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .block-div-list-left {
            font-size: 15px;
            font-family: Hiragino Sans GB;
            font-weight: normal;
            color: #000000;
            text-align: left;
            width: 100px;
          }
          .block-div-list-right {
            .right-input {
              width: 181px;
              display: flex;
              align-items: center;
              /deep/ .van-cell {
                padding: 0;
                background-color: #efeeee;
              }

              /deep/ .van-radio__icon .van-icon {
                width: 16px;
                height: 16px;
                line-height: 16px;
                margin-right: 4px;
              }

              /deep/ .van-radio__icon.van-icon {
                width: 16px;
                height: 16px;
                line-height: 16px;
                margin-right: 4px;
              }
              /deep/ .van-radio__icon {
                height: 16px !important;
                font-size: 13px;
              }

              /deep/ .van-icon-success:before {
                margin-left: 1px;
              }
            }
          }
        }

        .block-div-list:last-child {
          margin-bottom: 0;
        }

        .block-div-button {
          width: 133.5px;
          height: 37.5px;
          background: linear-gradient(
            0deg,
            #ffff00 0%,
            #ef876c 0%,
            #f25344 51%,
            #e42a1e 100%
          );
          border-radius: 5px;
          margin: 0 auto;

          font-size: 16px;
          font-family: Hiragino Sans GB;
          font-weight: normal;
          color: #ffffff;
          text-align: center;
          line-height: 37.5px;
          margin-top: 19px;
        }
      }
    }
  }
  .audit {
    /deep/ .van-popup {
      border-radius: 10px !important;
    }

    /deep/.van-popup {
      background-color: rgba(255, 255, 255, 0) !important;
    }

    .audit-div {
      width: 285px;
      height: 460px;
      position: relative;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      .audit-div-img {
        width: 100%;
        height: 364.5px;
        position: absolute;
        display: flex;
        top: 0;
        img {
          width: 100%;
          height: 100%;
        }
      }

      .audit-div-close {
        width: 12px;
        height: 12px;
        position: absolute;
        right: 20.5px;
        top: 58px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .audit-div-block {
        position: relative;
        padding: 0 20px 15px;
        box-sizing: border-box;
        background: #fff;

        .block-title {
          font-size: 17px;
          font-family: Hiragino Sans GB;
          font-weight: bold;
          color: #000000;
          text-align: center;
        }

        .block-text {
          margin: 15px 0;
          font-size: 14.5px;
          font-family: Hiragino Sans GB;
          font-weight: normal;
          color: #000000;
          text-align: left;
        }

        .block-link {
          height: 40px;
          width: 100%;
          margin-bottom: 15px;
          text-align: center;

          font-size: 14.5px;
          font-family: Hiragino Sans GB;
          font-weight: normal;
          color: #000000;

          line-height: 40px;
        }
        .block-button {
          width: 150px;
          height: 37px;
          border: 1px solid;
          margin: 0 auto;
          margin-bottom: 10px;
          text-align: center;
          line-height: 37px;

          font-size: 16px;
          font-family: Hiragino Sans GB;
          font-weight: normal;
          color: #ed4034;
          border: 1px solid #e52c20;
          border-radius: 5px;
        }

        .block-button:last-child {
          background: linear-gradient(
            0deg,
            #ffff00 0%,
            #ef876c 0%,
            #f25344 51%,
            #e42a1e 100%
          );
          color: #fff;
        }
      }
    }
  }
}
</style>
// 界面接口
import { get, post } from "../api/request";

// 签到界面  我的签到信息
export const mySign = params => get(`v1/front/mySign`, params)

// 签到界面  签到明细
export const siginDetail = params => get(`v1/front/mySignLog`, params)

// 签到界面 签到
export const mySigintext = params => post(`v1/front/mySign`, params)

// 签到界面 我的任务领取奖励
export const mySigintask = params => post(`v1/front/todayTask?id=${params.id}`)

// 签到界面 声望明细
export const mySiginReputation = params => get(`v1/front/user/popularity`, params)

// 签到界面 提现明细
export const myWithdraw = params => get(`v1/front/deposit/withdrawal`, params)


import axios from 'axios';

const ApiRequest = axios.create({
    // baseURL:'https://api.661222.com',
    baseURL:process.env.VUE_APP_PYAPI_BASE_URL,
    headers: { 'Content-Type': 'application/json;charset=utf-8' },
    timeout :100000
});
const FileRequest = axios.create({
    // baseURL:'https://backendweb.661222.com/jeecg-boot',
    baseURL:process.env.VUE_APP_API_BASE_URL,
    headers:{ 'Content-Type' : 'multipart/form-data' }
});
FileRequest.interceptors.request.use(config => {
    config.headers['X-Access-Token'] = localStorage.getItem('token');
    return config;
});

ApiRequest.interceptors.response.use(
    res => {
        return res.data;
    },
    // err => {
    //     return err.message;
    // }
);
export const getVerifyCode = data => ApiRequest.post('user_login/sms_captcha',data);
export const phoneLogin = data => ApiRequest.post('user_login/user_login',data);
export const getDownloadLink = () => ApiRequest.get('/sys/url')
export const deleteBankAccount = data => ApiRequest.delete('/sys/bank_card',{params:data})

export const Upload = data => FileRequest.post('/sys/common/upload',data);
export const third_party_login = data =>  ApiRequest.post('google_sign_in',data);

<template>
  <div class="signin">
    <div class="signin-streamer">
      <div class="signin-streamer-img">
        <img src="../../assets/signin/signin-streamer.png" alt="" />
      </div>

      <div class="signin-streamer-navigation">
        <div class="navigation-left">连续签到</div>
        <div class="navigation-right">
          <div class="right-title" @click="billBtn">明细</div>
          <div class="right-span"></div>
          <div class="right-title" @click="billRuleBtn">规则</div>
        </div>
      </div>

      <div class="signin-streamer-packet">
        <div class="packet-lint"></div>
        <div
          class="packet-list"
          v-for="(item, index) in signList"
          :key="index"
          @click="signinBtn(item)"
        >
          <div class="packet-list-block">
            <div
              class="block-img"
              :style="{ height: index == 6 ? '49.5px' : '' }"
            >
              <img
                :src="
                  index == 6
                    ? require('../../assets/signin/signin-red-packet.png')
                    : item.isSign == 0
                    ? require('../../assets/signin/signin-icon-show.png')
                    : require('../../assets/signin/signin-icon.png')
                "
                alt=""
              />
            </div>

            <div
              class="block-price"
              :style="{
                'margin-top': index == 6 ? '23px' : '',
                color: index == 6 ? '#FFFFFF' : '',
              }"
            >
              {{ item.signPrice }}元
            </div>
          </div>

          <div class="packet-list-price">第{{ item.day }}天</div>
        </div>
      </div>

      <div
        class="signin-streamer-button"
        :style="{
          color: siginObj.isSign == 1 ? '#FF7A74' : '',
          animation: siginObj.isSign == 1 ? 'none' : '',
          width: siginObj.isSign == 1 ? '275.5px' : '',
        }"
        @click="signinBtn(item)"
      >
        {{
          siginObj.isSign == 1 ? "今日已签到明日再来" : "连续7天签到领5元红包"
        }}
      </div>
    </div>

    <div class="signin-task">
      <div class="signin-task-title">每日任务奖励</div>

      <div
        class="signin-task-list"
        v-for="(item, index) in todayTaskList"
        :key="index"
      >
        <div class="signin-task-list-block">
          <div class="block-left">
            <div class="block-left-img">
              <img src="../../assets/signin/signin-task.png" alt="" />
            </div>
            <div class="block-left-div">
              <div class="block-left-div-title">
                {{ item.title }}
              </div>
              <div class="block-left-div-schedule">
                <div class="schedule-lint">
                  <div
                    class="schedule-lint-background"
                    :style="{
                      '--width': (item.finishNum / item.needNum) * 100 + '%',
                    }"
                  ></div>
                </div>
                <div class="schedule-text">
                  {{ item.finishNum }}/{{ item.needNum }}
                </div>
              </div>
            </div>
          </div>
          <div class="block-right">
            <div class="block-right-display">
              <div class="display-img">
                <img src="../../assets/signin/signin-gold.png" alt="" />
              </div>
              <div class="display-title">+{{ item.awardPrice }}元</div>
            </div>
            <div class="block-right-accomplish" @click="isAwardMet(item)">
              {{ item.isAward == 1 ? "已领取" : item.finishNum >= item.needNum ? "已完成" : "去完成" }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="signin-bottom"></div>
  </div>
</template>+

<script>
import { mySign, mySigintext, mySigintask } from "../../api/signin";
import { Toast } from "vant";
export default {
  data() {
    return {
      // 我的签到信息
      siginObj: {},
      // 我的签到列表
      signList: [],
      // 每日任务信息
      todayTaskList: [],
    };
  },
  created() {
    this.siginMet();
  },
  methods: {
    // 签到个人信息
    siginMet() {
      let params = {};
      mySign(params).then((res) => {
        console.log("获取个人信息", res);
        if (res.code == 200) {
          this.siginObj = res.result;
          this.signList = res.result.signList;
          this.todayTaskList = res.result.todayTaskList;
        }
      });
    },
    // 用户点击签到
    signinBtn(item) {
      let params = {};
      mySigintext(params).then((res) => {
        if (res.code == 200) {
          Toast(res.message);
          this.siginMet();
        }
      });
    },

    // 任务完成领取接口
    isAwardMet(item) {
      // 未完成跳轉到指定頁面
      if (item.finishNum < item.needNum) {
        switch (item.id){
          case(2):{
            return this.$router.push({path:'/fission'});
          }
           case(5):{
            return this.$router.push({path:'/fission'});
          }
           case(6):{
            return this.$router.push({path:'/fission'});
          }      
          default:{
            return this.$router.push({path:'/home'});
          }
        }
      };
      // 已領取不做任何動作
      if (item.isAward == 1) return; // 已领取
      // 已完成執行領取動作
      let params = {
        id: item.id, // 每日任务id
      };
      console.log("----", params);
      mySigintask(params).then((res) => {
        if (res.code == 200) {
          Toast(res.message);
          this.siginMet();
        }
      });
    },

    // 点击明细 进入收支账单
    billBtn() {
      this.$router.push({
        path: "/signin-bill",
        query: {
          index: 1,
        },
      });
    },
    // 点击规则
    billRuleBtn() {
      this.$router.push({
        path: "/signin-rule",
      });
    },
  },
};
</script>

<style lang="less" scoped>
@keyframes scaleDraw {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.2);
  }
}

.signin {
  .signin-streamer {
    height: 316px;
    .signin-streamer-img {
      height: 316px;
      position: absolute;
      display: flex;
      width: 100%;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .signin-streamer-navigation {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 83px 25px 0;
      box-sizing: border-box;
      .navigation-left {
        font-size: 17.5px;
        font-family: Hiragino Sans GB;
        font-weight: bold;
        color: #ffffff;
      }
      .navigation-right {
        display: flex;
        align-items: center;
        .right-title {
          font-size: 14.5px;
          font-family: Hiragino Sans GB;
          font-weight: normal;
          color: #ffffff;
        }
        .right-span {
          width: 0.5px;
          height: 22.5px;
          background: #ffffff;
          margin: 0 13.5px;
        }
      }
    }
    .signin-streamer-packet {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 60.5px 25px 0;
      position: relative;

      .packet-list {
        width: 40px;
        text-align: center;

        .packet-list-block {
          width: 100%;
          height: 40px;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;

          .block-img {
            width: 40px;
            height: 40px;
            position: absolute;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .block-price {
            position: relative;
            font-size: 9.5px;
            font-family: Hiragino Sans GB;
            font-weight: bold;
            color: #f6503c;
          }
        }

        .packet-list-price {
          font-size: 12px;
          font-family: Hiragino Sans GB;
          font-weight: normal;
          color: #ffffff;
          margin-top: 4px;
        }
      }

      .packet-list:last-child {
        margin-right: 0;
      }

      .packet-lint {
        width: 90%;
        height: 1px;
        background: #ffffff;
        position: absolute;
        margin: 0 10px;
        margin-top: -20px;
      }
    }
    .signin-streamer-button {
      width: 250px;
      height: 40px;
      background: #c61509;
      border-radius: 5px;
      margin: 0 auto;
      position: relative;
      margin-top: 25px;
      text-align: center;
      line-height: 40px;
      font-size: 15px;
      font-family: Hiragino Sans GB;
      font-weight: bold;
      animation: scaleDraw 2s ease-in-out infinite;
      color: #ffffff;
    }
  }
  .signin-task {
    background: #fff;
    margin: 0 10px;
    margin-top: 8.5px;
    padding-bottom: 20px;
    border-radius: 5px;

    .signin-task-title {
      height: 60px;
      display: flex;
      align-items: center;
      padding: 0 15px;
      box-sizing: border-box;
      font-size: 19px;
      font-family: Hiragino Sans GB;
      font-weight: bold;
      color: #000000;
      line-height: 60px;
      text-align: center;
    }

    .signin-task-list {
      height: 85px;
      padding: 0 10.5px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #e9e9e9;
      .signin-task-list-block {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        .block-left {
          display: flex;
          .block-left-img {
            width: 44.5px;
            height: 60.5px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .block-left-div {
            margin-left: 12px;
            .block-left-div-title {
              font-size: 14px;
              font-family: Hiragino Sans GB;
              font-weight: bold;
              color: #000000;
              margin-top: 12.5px;
            }
            .block-left-div-schedule {
              display: flex;
              align-items: center;
              height: 13px;
              margin-top: 15.5px;

              .schedule-lint {
                width: 136.5px;
                height: 12px;
                background: #ffffff;
                border: 1px solid #ea5950;
                border-radius: 5px;
                display: flex;
                align-items: center;
                padding: 0 1%;
                box-sizing: border-box;

                .schedule-lint-background {
                  width: var(--width);
                  height: 9px;
                  background: linear-gradient(
                    0deg,
                    #ffff00 0%,
                    #ef876c 0%,
                    #f25344 51%,
                    #e42a1e 100%
                  );
                  border-radius: 4.5px;
                }
              }
              .schedule-text {
                margin-left: 7px;
                font-size: 14px;
                font-family: Hiragino Sans GB;
                font-weight: bold;
                color: #000000;
              }
            }
          }
        }

        .block-right {
          .block-right-display {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .display-img {
              width: 20.5px;
              height: 16px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .display-title {
              font-size: 14px;
              font-family: Hiragino Sans GB;
              font-weight: bold;
              color: #000000;
            }
          }
          .block-right-accomplish {
            width: 64px;
            height: 27.5px;
            background: #e74c45;
            border-radius: 5px;
            text-align: center;
            line-height: 27.5px;
            font-size: 13px;
            font-family: Hiragino Sans GB;
            font-weight: bold;
            color: #ffffff;
            margin-top: 7.5px;
          }
        }
      }
    }

    .signin-task-list:last-child {
      border-bottom: 0;
    }
  }
  .signin-bottom {
    height: 67.5px;
    width: 100%;
  }
}
</style>
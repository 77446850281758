<template>
  <div class="search">
    <div class="search-navigation">
      <img
        class="search-navigation-back"
        src="../../assets/common/common-search-top.png"
        alt=""
      />
      <div class="search-navigation-block">
        <div class="block-left" @click="backRight">
          <img src="../../assets/common/common-left.png" alt="" />
        </div>
        <div class="block-center">
          <van-field
            v-model="searchTask"
            :left-icon="searchImg"
            placeholder="搜索任务标题、编号"
          />
        </div>
        <div class="block-right" @click="searchMet">搜索</div>
      </div>
    </div>

    <div class="navigation-lint"></div>

    <div class="search-scrolling">
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="list.length == 0 ? '' : ''"
        @load="onLoad"
      >
        <div
          class="search-scrolling-list"
          v-for="(item, index) in list"
          :key="index"
          @click="particularsBtn(item.id)"
        >
          <div class="list-left">
            <img
              class="list-left-back"
              v-if="index == 0 || index == 1 || index == 2"
              :src="
                index == 0
                  ? require('../../assets/home/home-icon-first.png')
                  : index == 1
                  ? require('../../assets/home/home-icon-second.png')
                  : index == 2
                  ? require('../../assets/home/home-icon-thirdly.png')
                  : ''
              "
              alt=""
            />

            <img class="list-left-circle" :src="item.mainImg" alt="" />
          </div>
          <div class="list-right">
            <div class="list-right-block">
              <div class="block-title">
                <div class="block-title-headline">{{ item.title }}</div>
                <div
                  class="block-title-explode"
                  v-for="(item, index) in item.tabs"
                  :key="index"
                >
                  {{ item == 1 ? "推" : item == 2 ? "顶" : "" }}
                </div>
              </div>
              <div class="block-label">
                <div class="block-label-list">
                  {{
                    item.type == 0
                      ? "小程序体验"
                      : item.type == 1
                      ? "关注体验"
                      : item.type == 2
                      ? "砍掉互帮"
                      : item.type == 3
                      ? "试用试玩"
                      : item.type == 4
                      ? "高阶任务"
                      : item.type == 5
                      ? "其他任务"
                      : ""
                  }}
                </div>

                <div class="block-label-list">
                  {{ item.name }}
                </div>
              </div>

              <div class="block-span">
                已赚<span style="color: #e94e44">{{ item.totalEarnPrice }}</span>元
                接单限时 <span style="color: #e94e44">{{ formatTime(item.receivingTimeLimit) }}</span>
                <!-- 已赚<span style="color: #e94e44">0</span>人 人均用{{
                  item.usedTimeAverage
                }}
                <span style="color: #e94e44">--</span> 平均审核<span
                  style="color: #e94e44"
                  >{{ item.auditTimeAverage }}</span
                >分钟 -->
              </div>
            </div>
          </div>
          <div class="list-relocating">{{ item.amountUnit }}元</div>
        </div>

        <empty :list="list" :index="2"></empty>
      </van-list>
    </div>
  </div>
</template>

<script>
import { homeTask } from "../../api/home";
import empty from "@/components/empty/empty.vue";
import { timeVerification } from "../../plugins/date";

export default {
  components: {
    empty,
  },
  data() {
    return {
      //  搜索区域 搜索名称
      searchTask: "",
      //  搜索区域 左侧图标 本地图片
      searchImg: require("../../assets/home/home-seek.png"),
      // 首页列表数组
      page: 1, // 当前页码
      limit: 10, // 当前页数
      list: [], // 当前数组列表
      loading: false, // 数组列表加载圈
      finished: false, // 是否暂时没有更多了
      refreshing: false, // 是否进行滚动加载

      // 图片暂缓
      // imgs: "https://after.byqp99.com/jeecg-bootsys/common/static/",
      // imgs: "https://backendweb.661222.com/jeecg-boot/sys/common/static/",
      imgs: process.env.VUE_APP_IMAGE_URL,
    };
  },
  created() {},
  methods: {
    // 返回上一层
    backRight() {
      this.$router.go(-1);
    },
    // 获取当前列表中对应的数组数据
    onLoad() {
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      if (this.refreshing) {
        this.list = [];
        this.page = 1;
        this.refreshing = false;
      }

      let params = {
        keyword: this.searchTask, //关键字
        pageSize: this.limit, // 页码
        pageNo: this.page, // 页数
      };

      homeTask(params).then((res) => {
        if (res.code == 200) {
          this.loading = false;
          this.finished = false;
          res.result.linkLogList.forEach((item) => {
            console.log("排查");
            item.tabs = item.tag.split(",");
            if (item.mainImg != null) {
              if (item.mainImg.substr(0, 3) != "htt") {
                item.mainImg = this.imgs + item.mainImg;
              }
            }
          });
          this.list.push(...res.result.linkLogList);
          console.log("获取list", this.list);
        } else {
        }

        // 加载状态结束
        // this.loading = false;

        // 数据全部加载完成
        if (this.list.length >= res.result.totalNum) {
          this.finished = true; // 到了滚动极限则返回true
        } else {
          this.page++;
        }
      });
    },
    // 搜索输入框
    searchMet() {
      this.refreshing = true;
      this.finished = true;
      this.onLoad();
    },

    // 点击任务详情列表 跳转任务详情
    particularsBtn(id) {
      this.$router.push({
        path: "/particulars",
        query: {
          id: id,
        },
      });
    },
     formatTime(_seconds){
      return timeVerification(_seconds); 
    }
  },
};
</script>

<style lang="less" scoped>
.search {
  .search-navigation {
    width: 100%;
    height: 95.5px;
    position: fixed;
    z-index: 9;

    .search-navigation-back {
      width: 100%;
      height: 100%;
      position: absolute;
      display: flex;
    }

    .search-navigation-block {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 13px;
      padding-top: 52px;
      box-sizing: border-box;

      .block-left {
        width: 9.5px;
        height: 15.5px;
        margin-right: 14.5px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .block-center {
        width: 285.5px;
        height: 33px;
        background: #ffffff;
        opacity: 0.9;
        border-radius: 5px;
        display: flex;
        align-items: center;

        /deep/ .van-cell {
          padding: 0px;
          display: flex;
          align-items: center;
          border-radius: 10px;
          padding: 0 16px;
          box-sizing: border-box;
        }

        /deep/ .van-cell__value {
          margin-left: 5px;
        }

        /deep/ .van-field__control {
          font-size: 11.5px;
          font-family: Hiragino Sans GB;
          font-weight: normal;
          color: #e74c45 !important;
        }

        /deep/ .van-icon__image {
          width: 11.5px;
          height: 12px;
          opacity: 1;
          z-index: 1;
        }

        /deep/ .van-field__control::-webkit-input-placeholder {
          color: #e74c45;
        }
      }

      .block-right {
        font-size: 15px;
        font-family: Hiragino Sans GB;
        font-weight: normal;
        color: #ffffff;
        margin-left: 6.5px;
      }
    }
  }
  .navigation-lint {
    height: 95.5px;
  }
  .search-scrolling {
    margin-top: 10px;
    min-height: 100vh;
    background: #ffffff;
    .search-scrolling-list {
      position: relative;
      height: 91px;
      border-bottom: 1px solid #e9e9e9;
      display: flex;
      margin: 0 12.5px;
      box-sizing: border-box;
      .list-left {
        width: 38.5px;
        height: 53px;
        margin-top: 8.5px;
        position: relative;
        display: flex;
        justify-content: center;

        .list-left-back {
          width: 100%;
          height: 100%;
          position: absolute;
        }

        .list-left-circle {
          width: 35px;
          height: 35px;
          position: absolute;
          bottom: 1.5px;
          z-index: 1;
          border-radius: 50%;
        }
      }
      .list-right {
        margin-left: 9px;

        display: flex;
        align-items: center;
        .list-right-block {
          .block-title {
            display: flex;
            align-items: center;
            .block-title-headline {
              font-size: 14.5px;
              font-family: Hiragino Sans GB;
              font-weight: bold;
              color: #000000;
              margin-right: 12px;
            }
            .block-title-explode {
              width: 16.5px;
              height: 17px;
              line-height: 17px;
              text-align: center;
              border: 1px solid #f6503c;
              border-radius: 3.5px;
              font-size: 10.5px;
              font-family: Hiragino Sans GB;
              font-weight: normal;
              color: #f6503c;
              margin-right: 5px;
            }

            .block-title-explode:last-child {
              margin-left: 0;
            }
          }

          .block-label {
            display: flex;
            margin-top: 11.5px;
            .block-label-list {
              height: 18px;
              background: #e9e9e9;
              border-radius: 6px;
              padding: 0 3px;
              box-sizing: border-box;
              font-size: 11.5px;
              font-family: Hiragino Sans GB;
              font-weight: normal;
              color: #a8a7a7;
              margin-right: 5px;
            }
          }
          .block-span {
            margin-top: 11.5px;
            font-size: 11.5px;
            font-family: Hiragino Sans GB;
            font-weight: normal;
            color: #a8a7a7;
          }
        }
      }

      .list-relocating {
        position: absolute;
        right: 12.5px;
        top: 8.5px;
        font-size: 13px;
        font-family: Hiragino Sans GB;
        font-weight: bold;
        color: #f6503c;
      }
    }

    .search-scrolling-list:last-child {
      border-bottom: 0;
    }
  }
}
</style>
<template>
  <div class="contact">
    <div class="contact-stremer">
      <streamer :title="title"></streamer>
    </div>
    <div class="contact-lint"></div>

    <div class="contact-block">
      <div class="contact-block-title">标题</div>
      <div class="contact-block-input">
        <van-field v-model="inputTitle" placeholder="请输入" />
      </div>
      <div class="contact-block-title">问题描述</div>
      <div class="contact-block-textarea">
        <van-field
          v-model="inputText"
          rows="4"
          autosize
          type="textarea"
          maxlength="150"
          placeholder="请输入留言"
          show-word-limit
        />
      </div>

      <div class="contact-block-display">
        <div class="display-list" @click="uploadingMet">上传视频或图片</div>
        <div class="display-list" @click="submitMet">提交</div>
      </div>

      <div
        class="contact-block-list"
        v-for="(item, index) in fileListImg"
        :key="index"
      >
        <div class="contact-block-list-left">{{ item }}</div>
        <div class="contact-block-list-right">
          <img src="../../assets/my/my-contact-icon-1.png" alt="" />
        </div>
      </div>
    </div>

    <div class="contact-div">
      <div class="contact-div-display">
        <div class="display-left">
          <div class="display-left-img">
            <img src="../../assets/my/my-contact-xin.png" alt="" />
          </div>
          <div class="display-left-title">收信箱</div>
        </div>
        <!-- <div class="display-right">一键删除</div> -->
      </div>

      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="list.length == 0 ? '' : ''"
        @load="onLoad"
      >
        <div
          class="contact-div-list"
          v-for="(item, index) in list"
          :key="index"
        >
          <div class="list-left">
            <div class="list-left-img">
              <img src="../../assets/my/my-contact-icon-2.png" alt="" />
            </div>
            <div class="list-left-block">
              <div class="list-left-block-title">{{ item.questionTitle }}</div>
              <div class="list-left-block-time">{{ item.createTime }}</div>
              <div style="margin-top:8px;">
                <div class="list-left-block-title">{{ item.answerTitile }}</div>
                <div class="list-left-block-title" style="margin-top:8px;">{{ item.answerDescription }}</div>
                <div class="list-left-block-time">{{ item.updateTime }}</div>
              </div>
            </div>
          </div>
          <div class="list-right" @click="deleteMet(item.id)">删除</div>
        </div>

        <empty :list="list"></empty>
      </van-list>
    </div>
  </div>
</template>

<script>
import streamer from "../../components/streamer/streamer.vue";

import { myContactList, myContactsponsor, myContactDelete } from "../../api/my";
import empty from "@/components/empty/empty.vue";

import { Toast } from "vant";

export default {
  components: {
    streamer,
    empty,
  },
  data() {
    return {
      title: "联系我们", // 标题
      inputTitle: "", // 输入框标题
      inputText: "", // 富文本框
      // 首页列表数组
      page: 1, // 当前页码
      limit: 10, // 当前页数
      list: [], // 当前数组列表
      loading: false, // 数组列表加载圈
      finished: false, // 是否暂时没有更多了
      refreshing: false, // 是否进行滚动加载
      totalNum: 0,
      //--------------------------------------
      fileList: [],
      fileListImg: [],
      // 图片暂缓
      // imgs: "https://after.byqp99.com/jeecg-bootsys/common/static/",
      // imgs: "https://backendweb.661222.com/jeecg-boot/sys/common/static/",
      imgs: process.env.VUE_APP_IMAGE_URL,
      fileimg: "",
    };
  },
  created() {},
  mounted() {
    window.transferConsultResult = this.transferConsultResult;
  },
  methods: {
    transferConsultResult(value) {
      console.log("接收到ios参数1", value);
      let result = JSON.parse(value);

      this.fileListImg.push(result.img.substring(result.img.length - 17));
      console.log("后缀名称", this.fileListImg);

      if (result.img != null) {
        if (result.img.substr(0, 3) != "htt") {
          result.img = this.imgs + result.img;
          this.fileList.push(result.img);
        }
      }

      console.log("上传成功", this.fileList);

      console.log("第二个地方的上传");
    },
    // 获取当前列表中对应的数组数据
    onLoad() {
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      if (this.refreshing) {
        this.list = [];
        this.refreshing = false;
      }

      let params = {
        pageSize: this.limit, // 页码
        pageNo: this.page, // 页数
      };

      myContactList(params).then((res) => {
        if (res.code == 200) {
          this.list.push(...res.result.feedbackList);
          this.totalNum = res.result.totalNum;
        } else {
        }

        // 加载状态结束
        this.loading = false;

        // 数据全部加载完成
        if (this.list.length >= this.totalNum) {
          this.finished = true; // 到了滚动极限则返回true
        } else {
          this.page++;
        }
      });
    },
    // 提交填写问题
    submitMet() {
      // 视频
      let viode = "";
      let viodeList = [];
      // 图片
      let str = "";
      let strList = [];

      this.fileList.forEach((item) => {
        if (item.substring(item.length - 3) == "mp4") {
          viodeList.push(item);
          // viode =
        } else {
          strList.push(item);
        }
      });

      viode = viodeList.join(";");
      str = strList.join(";");

      let params = {
        questionTitle: this.inputTitle, // 问题标题
        questionDescription: this.inputText, // 问题描述
        video: viode, // 视频
        imgUrl: str, // 图片
      };

      myContactsponsor(params).then((res) => {
        if (res.code == 200) {
          Toast.success(res.message);
          this.inputTitle = "";
          this.inputText = "";
          this.fileList = [];
          this.fileListImg = [];
          this.refreshing = true;
          this.onLoad();
        }
      });
    },
    // 点击删除信件
    deleteMet(id) {
      let params = id;
      myContactDelete(params).then((res) => {
        if (res.code == 200) {
          Toast.success(res.message);
          this.inputTitle = "";
          this.inputText = "";
          this.refreshing = true;
          this.onLoad();
        }
      });
    },

    // 用户点击上传
    uploadingMet() {
      var u = navigator.userAgent,
        app = navigator.appVersion;
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android终端或者uc浏览器
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isAndroid) {
        window.androidShangJIn.uploadingImgid('-1');
        console.log("安卓模块");
      } else if (isiOS) {
        console.log("ios模块");
        window.webkit.messageHandlers.uploadingImgId.postMessage("1");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.contact {
  .contact-stremer {
    width: 100%;
    height: 90.5px;
    position: fixed;
    z-index: 9;
    top: 0;
  }

  .contact-lint {
    height: 90.5px;
  }
  .contact-block {
    background: #ffffff;
    border-radius: 5px;
    margin: 10px 16px;
    padding: 20px 25px;
    box-sizing: border-box;
    .contact-block-title {
      font-size: 15px;
      font-family: Hiragino Sans GB;
      font-weight: bold;
      color: #000000;
    }

    .contact-block-input {
      height: 36px;
      background: #ffffff;
      border: 1px solid #e74c45;
      border-radius: 5px;
      margin-top: 11px;
      margin-bottom: 16px;
      display: flex;
      align-items: center;

      /deep/ .van-cell {
        padding: 0 0 0 10px;
      }
    }

    .contact-block-textarea {
      background: #ffffff;
      border-radius: 5px;
      margin-top: 11px;
      margin-bottom: 16px;
      height: 138px;

      /deep/ .van-cell {
        border-radius: 5px;
        border: 1px solid #e74c45;
      }
    }

    .contact-block-display {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .display-list {
        width: 125.5px;
        height: 37.5px;
        background: linear-gradient(
          0deg,
          #ffff00 0%,
          #ef876c 0%,
          #f25344 51%,
          #e42a1e 100%
        );
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

        font-size: 14.5px;
        font-family: Hiragino Sans GB;
        font-weight: normal;
        border: 1px solid #e74c45;
        color: #ffffff;
        position: relative;

        .display-list-file {
          position: absolute;
          width: 125.5px;
          height: 37.5px;
          left: 0;
          opacity: 0;

          /deep/ .van-uploader__upload {
            width: 125.5px;
            height: 37.5px;
            margin: 0;
          }
        }
      }

      .display-list:last-child {
        background: #ffffff;
        color: #e73225;
      }
    }

    .contact-block-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 11px;
      .contact-block-list-left {
        font-size: 13px;
        font-family: Hiragino Sans GB;
        font-weight: normal;
        color: #535353;
        width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .contact-block-list-right {
        width: 15px;
        height: 15px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .contact-div {
    background: #ffffff;
    border-radius: 5px;
    margin: 10px 16px;
    padding: 20px 0;

    box-sizing: border-box;
    .contact-div-display {
      padding: 0 0 25px 25px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #e9e9e9;
      .display-left {
        display: flex;
        align-items: center;
        .display-left-img {
          width: 24.5px;
          height: 22px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .display-left-title {
          font-size: 15px;
          font-family: Hiragino Sans GB;
          font-weight: bold;
          color: #000000;
          margin-left: 10px;
        }
      }
      .display-right {
        width: 75px;
        height: 27.5px;
        border: 1px solid #a10e07;
        background: linear-gradient(
          0deg,
          #ed4034 0%,
          #ed3325 51%,
          #ce190d 100%
        );
        border-radius: 12.5px;
        font-size: 13px;
        font-family: Hiragino Sans GB;
        font-weight: normal;
        color: #fffefe;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .contact-div-list {
      padding: 27.5px 10px 25px 20px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #e9e9e9;
      .list-left {
        display: flex;
        align-items: center;
        .list-left-img {
          width: 30.5px;
          height: 29.5px;
          flex:0 0 auto;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .list-left-block {
          // margin-left: 8.5px;
          margin:0 8.5px;
          .list-left-block-title {
            font-size: 14px;
            font-family: Hiragino Sans GB;
            font-weight: normal;
            color: #000000;
            word-break:break-all;
            word-wrap:break-word;
          }
          .list-left-block-time {
            font-size: 13px;
            font-family: Hiragino Sans GB;
            font-weight: normal;
            color: #4c4745;
            margin-top: 15px;
          }
        }
      }
      .list-right {
        width: 64px;
        height: 27.5px;
        background: #e74c45;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex:0 0 auto;
        font-size: 13px;
        font-family: Hiragino Sans GB;
        font-weight: normal;
        color: #ffffff;
      }
    }

    .contact-div-list:last-child {
      border: 0;
    }
  }

  // .contact-block {
  //   .contact-block-display {
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     margin-top: 48.5px;
  //     .display-title {
  //       font-size: 20px;
  //       font-family: Hiragino Sans GB;
  //       font-weight: normal;
  //       color: #000000;
  //     }
  //     .display-text {
  //       width: 60px;
  //       height: 16.5px;
  //       background: #ffffff;
  //       border: 0.5px solid #e74c45;
  //       border-radius: 5px;
  //       margin-left: 6.5px;
  //       text-align: center;
  //       line-height: 16.5px;
  //       font-size: 11.5px;
  //       font-family: Hiragino Sans GB;
  //       font-weight: normal;
  //       color: #e74c45;
  //     }
  //   }
  //   .contact-block-qrcode {
  //     width: 172px;
  //     height: 172px;
  //     margin: 0 auto;
  //     margin-top: 27px;

  //     img {
  //       width: 100%;
  //       height: 100%;
  //     }
  //   }
  //   .contact-block-center {
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     margin-top: 50px;
  //     .center-list {
  //       font-size: 13.5px;
  //       font-family: Hiragino Sans GB;
  //       font-weight: normal;
  //       color: #e74c45;

  //       width: 75px;
  //       height: 27.5px;
  //       background: #ffffff;
  //       border: 0.5px solid #e74c45;
  //       border-radius: 5px;
  //       text-align: center;
  //       line-height: 27.5px;
  //     }

  //     .center-list:last-child {
  //       color: #ffffff;
  //       background: #e74c45;
  //       margin-left: 15px;
  //     }
  //   }
  // }
}
</style>
<template>
  <div id="app">
    <router-view />
    <div
      v-if="
      route == 'home' ||
      route == 'ranking' ||
      route == 'fission' ||
      route == 'signin' ||
      route == 'my'
      "
    >
      <tab-bar></tab-bar>
    </div>
  </div>
</template>

<script>
import tabBar from "./components/tabbar/tab-bar.vue";

import { Toast } from "vant";

window.onload = function () {
  document.addEventListener("touchstart", function (event) {
    if (event.touches.length > 1) {
      event.preventDefault();
    }
  });
  document.addEventListener("gesturestart", function (event) {
    event.preventDefault();
  });
};

export default {
  components: {
    tabBar,
  },
  data() {
    return {
      route: "",
    };
  },
  watch: {
    $route(to) {
      console.log("监听路径", to);
      this.route = to.name;

      Toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 500,
        overlay: true,
      });
    },
  },
  created() {
    this.route = this.$route.name;
    (function(a, b, c, d, e, j, s) {
        a[d] = a[d] || function() {
            (a[d].a = a[d].a || []).push(arguments)
        };
        j = b.createElement(c),
            s = b.getElementsByTagName(c)[0];
        j.async = true;
        j.charset = 'UTF-8';
        j.src = 'https://static.meiqia.com/widget/loader.js';
        s.parentNode.insertBefore(j, s);
    })(window, document, 'script', '_MEIQIA');
    _MEIQIA('entId', '657165e973d23a76bfa15e4859a69ade');
  },
  beforeMount() {
    // 禁用双指放大
    document.documentElement.addEventListener(
      "touchstart",
      function (event) {
        if (event.touches.length > 1) {
          event.preventDefault();
        }
      },
      {
        passive: false,
      }
    );

    // 禁用双击放大
    var lastTouchEnd = 0;
    document.documentElement.addEventListener(
      "touchend",
      function (event) {
        var now = Date.now();
        if (now - lastTouchEnd <= 300) {
          event.preventDefault();
        }
        lastTouchEnd = now;
      },
      {
        passive: false,
      }
    );
  },
};
</script>


<style lang="less">
body {
  margin: 0;
  background: rgb(247, 247, 247);
  padding: 0;
}

.van-overlay {
  background-color: rgba(0, 0, 0, 0.4) !important;
}

* {
  /* 禁止长按屏幕复制内容*/
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
input,
textarea {
  /*用上边的禁止复制方法后 此时在ios手机有问题，所有的输入框自动失去焦点无法输入内容,*/
  -webkit-user-select: auto;
}
</style>

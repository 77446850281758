<template>
  <div class="deal">
    <div class="deal-streamer">
      <div class="deal-streamer-img">
        <img src="../../assets/my/my-deal-streamer.png" alt="" />
      </div>

      <div class="deal-streamer-display">
        <div class="display-left" @click="backRight">
          <img src="../../assets/common/common-left.png" alt="" />
        </div>
        <div class="display-center">交易流水</div>
        <div class="display-right"></div>
      </div>

      <div class="deal-streamer-price">
        {{ totalPrice == undefined ? 0 : totalPrice }}元
      </div>
    </div>

    <div class="deal-streamer-lint"></div>

    <div class="deal-scrolling">
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="list.length == 0 ? '' : ''"
        @load="onLoad"
      >
        <div
          class="deal-scrolling-list"
          v-for="(item, index) in list"
          :key="index"
        >
          <div class="scrolling-list-left">
            <div class="list-left-img">
              <img :src="item.userIcon" alt="" />
            </div>
            <div class="list-left-block">
              <div class="list-left-block-title">{{ item.source }}</div>
              <div class="list-left-block-time">{{ item.createTime }}</div>
            </div>
          </div>

          <div class="scrolling-list-right">
            <div class="scrolling-list-right-price">
              {{ item.regulationType == 0 ? "-" : "+" }}{{ item.amount }}元
            </div>
          </div>
        </div>
        <empty :list="list"></empty>
      </van-list>
    </div>
  </div>
</template>

<script>
import { myDealwater } from "../../api/my";

import empty from "@/components/empty/empty.vue";
export default {
  components: {
    empty,
  },
  data() {
    return {
      // 首页列表数组
      page: 1, // 当前页码
      limit: 10, // 当前页,,数
      list: [], // 当前数组列表
      loading: false, // 数组列表加载圈
      finished: false, // 是否暂时没有更多了
      refreshing: false, //

      //---总数---
      totalPrice: 0,
      // 图片暂缓
      imgs: "",
    };
  },
  created() {
    this.imgs = localStorage.getItem("userImg");
  },
  methods: {
    // 返回上一层
    backRight() {
      this.$router.go(-1);
    },
    // 获取当前列表中对应的数组数据
    onLoad() {
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      if (this.refreshing) {
        this.list = [];
        this.refreshing = false;
      }

      let params = {
        pageSize: this.limit,
        pageNo: this.page,
      };

      myDealwater(params).then((res) => {
        if (res.code == 200) {
          res.result.withdrawalLogList.forEach((item) => {
            item.userIcon = this.imgs;
          });

          this.list.push(...res.result.withdrawalLogList);

          this.totalPrice = res.result.totalPrice;
        }

        // 加载状态结束
        this.loading = false;

        // 数据全部加载完成
        if (this.list.length >= res.result.totalNum) {
          this.finished = true; // 到了滚动极限则返回true
        } else {
          this.page++;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.deal {
  .deal-streamer {
    width: 100%;
    height: 174.5px;
    position: fixed;
    touch-action: 0;
    .deal-streamer-img {
      width: 100%;
      height: 100%;
      position: absolute;
      display: flex;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .deal-streamer-display {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 58.5px 13.5px 0;
      box-sizing: border-box;
      position: relative;
      .display-left {
        width: 9.5px;
        height: 15.5px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .display-center {
        font-size: 15.5px;
        font-family: Hiragino Sans GB;
        font-weight: bold;
        color: #ffffff;
      }
      .display-right {
        width: 9.5px;
        height: 15.5px;
      }
    }

    .deal-streamer-price {
      position: relative;
      font-size: 25px;
      font-family: Hiragino Sans GB;
      font-weight: bold;
      color: #ffffff;
      text-align: center;
      margin-top: 44.5px;
    }
  }
  .deal-streamer-lint {
    width: 100%;
    height: 174.5px;
  }
  .deal-scrolling {
    .deal-scrolling-list {
      height: 84.5px;
      border-bottom: 1px solid #e9e9e9;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 28.5px;
      box-sizing: border-box;

      .scrolling-list-left {
        display: flex;
        align-items: center;
        .list-left-img {
          width: 40px;
          height: 40px;
          border-radius: 50%;

          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
        .list-left-block {
          margin-left: 12px;
          .list-left-block-title {
            font-size: 14.5px;
            font-family: Hiragino Sans GB;
            font-weight: bold;
            color: #000000;
          }
          .list-left-block-time {
            font-size: 11.5px;
            font-family: Hiragino Sans GB;
            font-weight: normal;
            color: #696767;
            margin-top: 10 px;
          }
        }
      }
      .scrolling-list-right {
        .scrolling-list-right-price {
          font-size: 16px;
          font-family: Hiragino Sans GB;
          font-weight: bold;
          color: #e74c45;
        }
      }
    }
  }
}
</style>
<template>
  <div class="order">
    <div class="order-streamer">
      <div class="order-streamer-img">
        <img src="../../assets/signin/signin-bill-streamer.png" alt="" />
      </div>

      <div class="order-streamer-back">
        <div class="back-img">
          <img src="../../assets/signin/signin-bill-back.png" alt="" />
        </div>

        <div class="back-display">
          <div class="display-left" @click="backRight">
            <img src="../../assets/common/common-left.png" alt="" />
          </div>
          <div class="display-center">接单管理</div>
          <div class="display-right"></div>
        </div>
      </div>

      <div class="order-streamer-tabs">
        <van-tabs v-model="activeTabs" @change="activeMet">
          <van-tab
            v-for="(item, index) in 5"
            :key="index"
            :title="
              index == 0
                ? '全部'
                : index == 1
                ? '待提交'
                : index == 2
                ? '审核中'
                : index == 3
                ? '已通过'
                : index == 4
                ? '未通过'
                : ''
            "
          ></van-tab>
        </van-tabs>
      </div>
    </div>

    <div class="order-lint"></div>

    <div class="order-table">
      <!-- <van-pull-refresh v-model="refreshing" @refresh="onRefresh"> -->
        <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="list.length == 0 ? '' : ''"
          @load="onLoad"
        >
          <div class="order-table-block">
            <div class="block-list" v-for="(item, index) in list" :key="index">
              <div class="block-list-left">
                <div class="left-img">
                  <img :src="item.mainImg" alt="" />
                </div>
                <div class="left-center">
                  <div class="left-center-title">{{ item.title }}</div>
                  <div class="left-center-text">
                    <div class="text-list">
                      {{
                        item.type == 0
                          ? "小程序体验"
                          : item.type == 1
                          ? "关注体验"
                          : item.type == 2
                          ? "砍掉互帮"
                          : item.type == 3
                          ? "试用试玩"
                          : item.type == 4
                          ? "高阶任务"
                          : item.type == 5
                          ? "其他任务"
                          : ""
                      }}
                    </div>
                    <div class="text-list">
                      {{ item.name }}
                    </div>
                  </div>

                  <div class="left-center-lint" v-if="item.status == 1"></div>
                </div>
              </div>

              <div class="block-list-right">
                <div class="right-title">+{{ item.amountUnit }}</div>
                <div
                  class="right-status"
                  :style="{
                    background:
                      item.status == 1
                        ? '#F7D134'
                        : item.status == 2
                        ? '#FF7E27'
                        : item.status == 3
                        ? '#47B645'
                        : item.status == 4
                        ? '#DE1B12'
                        : item.status == 5
                        ? '#999999'
                        : '',
                  }"
                >
                  {{
                    item.status == 1
                      ? "待提交"
                      : item.status == 2
                      ? "审核中"
                      : item.status == 3
                      ? "已通过"
                      : item.status == 4
                      ? "未通过"
                      : item.status == 5
                      ? "已放弃"
                      : ""
                  }}
                </div>
              </div>

              <div class="block-list-time" v-if="item.status == 1">
                请在
                <span style="color: #d52921; margin: 0 5px">
                  <van-count-down :time="item.submitResidueTime" />
                </span>
                内完成提交，逾期自期自期自期自动取消
              </div>
            </div>
          </div>

          <empty :list="list"></empty>
        </van-list>
      <!-- </van-pull-refresh> -->
    </div>
  </div>
</template>

<script>
import { orderReceiving } from "../../api/my";

import empty from "@/components/empty/empty.vue";

export default {
  components: {
    empty,
  },
  data() {
    return {
      activeTabs: 0, // 标签页
      // 首页列表数组
      limit: 10, // 页数
      page: 1, // 页码
      list: [], // 当前数组列表
      loading: false, // 数组列表加载圈
      finished: false, // 是否暂时没有更多了
      refreshing: false, // 是否进行滚动加载
      // 图片暂缓
      // imgs: "https://after.byqp99.com/jeecg-bootsys/common/static/",
      // imgs: "https://backendweb.661222.com/jeecg-boot/sys/common/static/",
      imgs: process.env.VUE_APP_IMAGE_URL,
    };
  },
  created() {
    this.activeTabs = Number(this.$route.query.index)
    console.log(this.activeTabs)
  },
  methods: {
    // 返回上一层
    backRight() {
      this.$router.go(-1);
    },
    // 点击切换tabs栏
    activeMet(e) {
      this.activeTabs = e;
      this.refreshing = true;
      this.page = 1
      this.finished = true;
      this.onLoad();
    },
    // 获取当前列表中对应的数组数据
    onLoad() {
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      if (this.refreshing) {
        this.list = [];
        this.refreshing = false;
      }

      let params = {
        status:
          this.activeTabs == 0
            ? -1
            : this.activeTabs == 1
            ? 1
            : this.activeTabs == 2
            ? 2
            : this.activeTabs == 3
            ? 3
            : this.activeTabs == 4
            ? 4
            : "", // 状态
        pageSize: this.limit, // 页码
        pageNo: this.page, // 页数
      };

      orderReceiving(params).then((res) => {
        console.log("状态接口请求", res);
        if (res.code == 200) {
          this.loading = false;
          this.finished = false;
          res.result.taskList.forEach((item) => {
            item.tabs = item.tag.split(",");
            item.submitResidueTime = item.submitResidueTime * 1000;

            if (item.mainImg != null) {
              if (item.mainImg.substr(0, 3) != "htt") {
                item.mainImg = this.imgs + item.mainImg;
              }
            }
          });
          this.list.push(...res.result.taskList);
        }
        // 加载状态结束
        this.loading = false;
        // // 数据全部加载完成
        if (this.list.length >= res.result.totalNum) {
          this.finished = true; // 到了滚动极限则返回true
        }else{
          this.page += 1;
        }
      });
    },

    onRefresh() {
      // 清空列表数据
      this.refreshing = true;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
  },
};
</script>

<style lang="less" scoped>
.order {
  .order-streamer {
    height: 139px;
    position: fixed;
    z-index: 9;
    width: 100%;
    .order-streamer-img {
      height: 139px;
      position: absolute;
      display: flex;
      width: 100%;

      img {
        width: 100%;
        height: 100%;
      }
    }
    .order-streamer-back {
      height: 90.5px;
      position: relative;
      .back-img {
        width: 100%;
        height: 100%;
        display: flex;
        position: absolute;
        img {
          width: 100%;
          height: 100%;
        }
      }

      .back-display {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 54.5px 10px 0;
        box-sizing: border-box;
        position: relative;
        .display-left {
          width: 9.5px;
          height: 15.5px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .display-center {
          font-size: 15.5px;
          font-family: Hiragino Sans GB;
          font-weight: bold;
          color: #ffffff;
        }
        .display-right {
          width: 9.5px;
          height: 15.5px;
        }
      }
    }
    .order-streamer-tabs {
      padding: 0 10px;
      box-sizing: border-box;
      /deep/ .van-tabs__nav {
        background-color: rgba(255, 255, 255, 0);
      }

      /deep/ .van-tab {
        color: #393838;
        font-size: 14.5px;
      }

      /deep/ .van-tab--active {
        font-weight: bold;
      }

      /deep/ .van-tabs__line {
        height: 5.5px;
        width: 46.5px;
        bottom: 18px;
      }
    }
  }
  .order-lint {
    height: 139px;
  }
  .order-table {
    .order-table-block {
      .block-list {
        padding: 25px 24px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        position: relative;
        border-bottom: 1px solid #e9e9e9;

        .block-list-left {
          display: flex;
          width: 100%;
          position: relative;
          .left-img {
            width: 40px;
            height: 40px;
            background: #fdebe8;
            border-radius: 50%;

            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }
          .left-center {
            margin-left: 11px;
            .left-center-title {
              font-size: 14.5px;
              font-family: Hiragino Sans GB;
              font-weight: bold;
              color: #000000;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .left-center-text {
              margin-top: 9.5px;
              display: flex;
              align-items: center;
              .text-list {
                padding: 3.5px 4.5px;
                box-sizing: border-box;
                margin-right: 3px;
                background: #efeded;
                font-size: 11.5px;
                font-family: Hiragino Sans GB;
                font-weight: normal;
                color: #696767;
                border-radius: 6px;
              }

              .text-list:last-child {
                margin-right: 0px;
              }
            }
            .left-center-lint {
              height: 30px;
              margin-top: 10px;
            }
          }
        }
        .block-list-right {
          text-align: center;
          .right-title {
            font-size: 13px;
            font-family: Hiragino Sans GB;
            font-weight: normal;
            color: #e74c45;
          }
          .right-status {
            width: 60.5px;
            height: 24px;
            background: #999999;
            border-radius: 6px;
            margin-top: 4px;
            text-align: center;
            line-height: 24px;
            font-size: 15px;
            font-family: Hiragino Sans GB;
            font-weight: bold;
            color: #ffffff;
          }
        }
        .block-list-time {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          bottom: 25px;
          width: 87%;
          height: 30px;
          font-size: 11.5px;
          font-family: Hiragino Sans GB;
          font-weight: normal;
          color: #9c9b9b;

          /deep/ .van-count-down {
            color: #d52921;
          }
        }
      }
    }
  }
}
</style>
<template>
  <div class="browsing">
    <div class="browsing-streamer">
      <div class="browsing-streamer-img">
        <img src="../../assets/signin/signin-bill-streamer.png" alt="" />
      </div>

      <div class="browsing-streamer-back">
        <div class="back-img">
          <img src="../../assets/signin/signin-bill-back.png" alt="" />
        </div>

        <div class="back-display">
          <div class="display-left" @click="backRight">
            <img src="../../assets/common/common-left.png" alt="" />
          </div>
          <div class="display-center">浏览记录</div>
          <div class="display-right"></div>
        </div>
      </div>

      <div class="browsing-streamer-tabs">
        <div class="tabs-img">
          <img src="../../assets/my/my-history-message.png" alt="" />
        </div>
        <div class="tabs-title">该页面只显示最近15天的浏览记录</div>
      </div>
    </div>

    <div class="browsing-lint"></div>

    <div class="home-scrolling">
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="list.length == 0 ? '' : ''"
        @load="onLoad"
      >
        <div
          class="home-scrolling-list"
          v-for="(item, index) in list"
          :key="index"
          @click="particularsBtn(item.taskId)"
        >
          <div class="list-left">
            <img
              class="list-left-back"
              v-if="index == 0 || index == 1 || index == 2"
              :src="
                index == 0
                  ? require('../../assets/home/home-icon-first.png')
                  : index == 1
                  ? require('../../assets/home/home-icon-second.png')
                  : index == 2
                  ? require('../../assets/home/home-icon-thirdly.png')
                  : ''
              "
              alt=""
            />

            <img class="list-left-circle" :src="item.mainImg" alt="" />
          </div>
          <div class="list-right">
            <div class="list-right-block">
              <div class="block-title">
                <div class="block-title-headline">{{ item.name }}</div>
                <div
                  class="block-title-explode"
                  v-for="(item, index) in item.tabs"
                  :key="index"
                >
                  {{ item == 1 ? "推" : item == 2 ? "顶" : "" }}
                </div>
              </div>
              <div class="block-label">
                <div class="block-label-list">
                  {{
                    item.type == 0
                      ? "小程序体验"
                      : item.type == 1
                      ? "关注体验"
                      : item.type == 2
                      ? "砍掉互帮"
                      : item.type == 3
                      ? "试用试玩"
                      : item.type == 4
                      ? "高阶任务"
                      : item.type == 5
                      ? "其他任务"
                      : ""
                  }}
                </div>
                <div class="block-label-list">
                  {{ item.name }}
                </div>
              </div>

              <div class="block-span">
                已赚<span style="color: #e94e44">0</span>人 人均用{{
                  item.usedTimeAverage
                }}
                <span style="color: #e94e44">--</span> 平均审核<span
                  style="color: #e94e44"
                  >{{ item.auditTimeAverage }}</span
                >分钟
              </div>
            </div>
          </div>
          <div class="list-relocating">{{ item.amountUnit }}元</div>
        </div>

        <empty :list="list"></empty>
      </van-list>
    </div>
  </div>
</template>

<script>
import { myTaskbrowse } from "../../api/my";
import empty from "@/components/empty/empty.vue";

export default {
  components: {
    empty,
  },
  data() {
    return {
      // 首页列表数组
      page: 1, // 当前页码
      limit: 10, // 当前页数
      list: [], // 当前数组列表
      loading: false, // 数组列表加载圈
      finished: false, // 是否暂时没有更多了
      refreshing: false, // 是否进行滚动加载
      // 图片暂缓
      // imgs: "https://after.byqp99.com/jeecg-bootsys/common/static/",
      // imgs: "https://backendweb.661222.com/jeecg-boot/sys/common/static/",
      imgs: process.env.VUE_APP_IMAGE_URL,
    };
  },
  methods: {
    // 返回上一层
    backRight() {
      this.$router.go(-1);
    },
    // 获取当前列表中对应的数组数据
    onLoad() {
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      if (this.refreshing) {
        this.list = [];
        this.refreshing = false;
      }

      let params = {
        pageSize: this.limit,
        pageNo: this.page,
      };

      myTaskbrowse(params).then((res) => {
        if (res.code == 200) {
          res.result.linkLogList.forEach((item) => {
            item.tabs = item.tag.split(",");

            if (item.mainImg != null) {
              if (item.mainImg.substr(0, 3) != "htt") {
                item.mainImg = this.imgs + item.mainImg;
              }
            }
          });

          this.list.push(...res.result.linkLogList);
        }

        // 加载状态结束
        this.loading = false;

        // 数据全部加载完成
        if (this.list.length >= res.result.totalNum) {
          this.finished = true; // 到了滚动极限则返回true
        } else {
          this.page++;
        }
      });
    },

    // 点击任务详情列表 跳转任务详情
    particularsBtn(id) {
      this.$router.push({
        path: "/particulars",
        query: {
          id: id,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.browsing {
  .browsing-streamer {
    height: 139px;
    position: fixed;
    z-index: 9;
    width: 100%;
    .browsing-streamer-img {
      height: 139px;
      position: absolute;
      display: flex;
      width: 100%;

      img {
        width: 100%;
        height: 100%;
      }
    }
    .browsing-streamer-back {
      height: 90.5px;
      position: relative;
      .back-img {
        width: 100%;
        height: 100%;
        display: flex;
        position: absolute;
        img {
          width: 100%;
          height: 100%;
        }
      }

      .back-display {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 54.5px 10px 0;
        box-sizing: border-box;
        position: relative;
        .display-left {
          width: 9.5px;
          height: 15.5px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .display-center {
          font-size: 15.5px;
          font-family: Hiragino Sans GB;
          font-weight: bold;
          color: #ffffff;
        }
        .display-right {
          width: 9.5px;
          height: 15.5px;
        }
      }
    }
    .browsing-streamer-tabs {
      padding: 0 32.5px;
      box-sizing: border-box;
      height: 48px;
      display: flex;
      align-items: center;
      position: relative;

      .tabs-img {
        width: 12px;
        height: 17px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .tabs-title {
        font-size: 12.5px;
        font-family: Hiragino Sans GB;
        font-weight: normal;
        color: #e74c45;
        margin-left: 9.5px;
      }
    }
  }
  .browsing-lint {
    height: 139px;
  }

  .home-scrolling {
    margin-top: 10px;
    .home-scrolling-list {
      position: relative;
      height: 91px;
      // height: 200px;
      border-bottom: 1px solid #e9e9e9;
      display: flex;
      padding: 0 12.5px;
      box-sizing: border-box;
      .list-left {
        width: 38.5px;
        height: 53px;
        margin-top: 8.5px;
        position: relative;
        display: flex;
        justify-content: center;

        .list-left-back {
          width: 100%;
          height: 100%;
          position: absolute;
        }

        .list-left-circle {
          width: 35px;
          height: 35px;
          position: absolute;
          bottom: 1.5px;
          z-index: 1;
          border-radius: 50%;
        }
      }
      .list-right {
        margin-left: 9px;

        display: flex;
        align-items: center;
        .list-right-block {
          .block-title {
            display: flex;
            align-items: center;
            .block-title-headline {
              font-size: 14.5px;
              font-family: Hiragino Sans GB;
              font-weight: bold;
              color: #000000;
            }
            .block-title-explode {
              width: 16.5px;
              height: 17px;
              line-height: 17px;
              text-align: center;
              border: 1px solid #f6503c;
              border-radius: 3.5px;
              font-size: 10.5px;
              font-family: Hiragino Sans GB;
              font-weight: normal;
              color: #f6503c;
              margin-right: 5px;
              margin-left: 12px;
            }

            .block-title-explode:last-child {
              margin-left: 0;
            }
          }

          .block-label {
            display: flex;
            margin-top: 11.5px;
            .block-label-list {
              height: 18px;
              background: #e9e9e9;
              border-radius: 6px;
              padding: 0 3px;
              box-sizing: border-box;
              font-size: 11.5px;
              font-family: Hiragino Sans GB;
              font-weight: normal;
              color: #a8a7a7;
            }
          }
          .block-span {
            margin-top: 11.5px;
            font-size: 11.5px;
            font-family: Hiragino Sans GB;
            font-weight: normal;
            color: #a8a7a7;
          }
        }
      }

      .list-relocating {
        position: absolute;
        right: 12.5px;
        top: 8.5px;
        font-size: 13px;
        font-family: Hiragino Sans GB;
        font-weight: bold;
        color: #f6503c;
      }
    }

    .home-scrolling-list:last-child {
      border-bottom: 0;
    }
  }
}
</style>
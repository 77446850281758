// 我的界面接口
import { get, post, put, deletefn } from "../api/request";

// 我的界面   个人信息
export const my = params => get(`v1/front/user/info`, params)

// 我的界面接口 接单列表
export const orderReceiving = params => get(`v1/front/myTsak`, params)

// 我的界面接口 修改昵称
export const myNicknamealter = params => post(`v1/front/user/name`, params)

// 我的界面接口 任务浏览记录
export const myTaskbrowse = params => get(`v1/front/user/link/task`, params)

// 我的界面接口 交易流水
export const myDealwater = params => get(`v1/front/withdrawal`, params)

// 我的界面接口 我的银行卡
export const mybank = params => get(`v1/front/bank`, params)

// 我的界面接口 新增银行卡
export const myNewbank = params => post(`v1/front/bank`, params)

// 我的界面接口 确认提现
export const mywithdrawConfirm = params => post(`v1/front/withdraw`, params)

// 我的界面接口 确认设置默认
export const mydefaultConfirm = params => put(`v1/front/bank`, params)

// 我的界面接口 接单通知
export const myinformationMessgin = params => get(`v1/front/information`, params)

// 我的界面接口 联系我们界面列表
export const myContactList = params => get(`v1/front/feedback`, params)

// 我的界面接口 发起联系我们
export const myContactsponsor = params => post(`v1/front/feedback`, params)

// 我的界面接口 删除联系我们
export const myContactDelete = params => deletefn(`v1/front/feedback/${params}` )

// 我的界面接口 修改头像
export const myIcon = params => post(`v1/front/user/icon`, params )

// 我的界面  关于我们
export const myVersions = params => get(`common/versions`, params )

// 我的界面  账号审核
export const myuserCheck = params => post(`v1/front/user/check`, params )

// 我的界面  银行卡配置信息
export const mybankMessage = params => get(`v1/front/deploy`, params )

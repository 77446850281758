<template>
  <div class="particulars">
    <div class="particulars-top">
      <div class="particulars-top-back">
        <img src="../../assets/particulars/particulars-back.png" alt="" />
      </div>

      <div class="particulars-top-navigation">
        <img
          src="../../assets/common/common-left.png"
          alt=""
          @click="backRight"
        />
      </div>
    </div>

    <div class="particulars-center">
      <div class="center-first">
        <div class="center-first-top">
          <div class="center-first-top-left">
            <div class="left-img">
              <img :src="ParticularsObj.mainImg" alt="" />
            </div>
            <div class="left-block">
              <div class="left-block-top">
                <div class="left-block-top-title">
                  {{ ParticularsObj.title }}
                </div>
                <div class="left-block-top-img"></div>
              </div>
              <div class="left-block-center">
                <div class="center-title">
                  {{
                    ParticularsObj.type == 0
                      ? "小程序体验"
                      : ParticularsObj.type == 1
                      ? "关注体验"
                      : ParticularsObj.type == 2
                      ? "砍掉互帮"
                      : ParticularsObj.type == 3
                      ? "试用试玩"
                      : ParticularsObj.type == 4
                      ? "高阶任务"
                      : ParticularsObj.type == 5
                      ? "其他任务"
                      : ""
                  }}
                </div>

                <div class="center-title">
                  {{ ParticularsObj.name }}
                </div>
              </div>
            </div>
          </div>
          <div class="center-first-top-right">
            <div class="right-price">
              {{ ParticularsObj.amountUnit
              }}<span style="font-size: 15px">元</span>
            </div>
          </div>
        </div>

        <div class="center-first-center">
          <div class="center-first-center-display">
            <div class="display-list" v-for="(item, index) in 2" :key="index">
              <div class="display-list-block">
                <div class="display-list-block-title">
                  {{ index == 0 ? ParticularsObj.totalEarnPrice : ParticularsObj.receivingTimeLimit }}
                </div>
                <div class="display-list-block-text">
                  {{ index == 0 ? "总金额" : "接单限时" }}
                </div>
              </div>
            </div>
            <!-- <div class="display-list" v-for="(item, index) in 4" :key="index">
              <div class="display-list-block">
                <div class="display-list-block-title">
                  {{
                    index == 0
                      ? ParticularsObj.usedTimeAverage
                      : index == 1
                      ? ParticularsObj.totalEarnPrice
                      : index == 2
                      ? ParticularsObj.auditTimeAverage
                      : ParticularsObj.receivingTimeLimit
                  }}
                </div>
                <div class="display-list-block-text">
                  {{
                    index == 0
                      ? "人均用时"
                      : index == 1
                      ? "总金额"
                      : index == 2
                      ? "平均审核"
                      : "接单限时"
                  }}
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>

      <div class="center-second">
        <inform :title="informList" :color="'#E74C45'"></inform>
      </div>

      <div class="center-thirdly">
        <div class="center-thirdly-step">
          <div class="step-headline">
            <div class="step-headline-img">
              <img
                src="../../assets/particulars/particulars-step-title.png"
                alt=""
              />
            </div>
            <div class="step-headline-title">任务步骤/流程</div>
          </div>

          <div class="step-lint"></div>

          <div
            class="step-list"
            v-for="(item, index) in taskStepList"
            :key="index"
          >
            <div class="step-list-left">
              <img
                class="left-img"
                :style="{ height: index == 0 ? '23.5px' : '' }"
                :src="
                  item.step == 0
                    ? require('../../assets/particulars/particulars-step-icon-1.png')
                    : item.step == 1
                    ? require('../../assets/particulars/particulars-step-icon-2.png')
                    : item.step == 2
                    ? require('../../assets/particulars/particulars-step-icon-3.png')
                    : item.step == 3
                    ? require('../../assets/particulars/particulars-step-icon-4.png')
                    : item.step == 4
                    ? require('../../assets/particulars/particulars-step-icon-5.png')
                    : ''
                "
                alt=""
              />

              <div class="left-lint"></div>
            </div>
            <div class="step-list-right">
              <div
                class="step-list-right-title"
                :style="{ color: item.type == -1 ? '#E74C45' : '' }"
              >
                {{ item.type == -1 ? "领取条件/限制: " : item.description }}
              </div>
              <div class="step-list-right-text" v-if="item.type == -1">
                {{ item.content }}
              </div>

              <div class="step-list-right-input" v-if="item.type == 2">
                <van-field v-model="item.content" placeholder="请输入用户名" />
                <div class="input-bottom" @click="copyMet(item.content)">
                  复制
                </div>
              </div>

              <div class="step-list-right-block" v-if="item.type == 0">
                <div class="block-img">
                  <img :src="item.content" alt="" />
                </div>
                <div class="block-button" @click="saveMet(item.content)">
                  保存说明图
                </div>
              </div>

              <div class="step-list-right-block" v-if="item.type == 1">
                <div class="block-img">
                  <img :src="item.content" alt="" />
                </div>
                <div class="block-button" @click="saveMet(item.content)">
                  保存二维码
                </div>
              </div>

              <div class="step-list-right-input" v-if="item.type == 3">
                <van-field
                  v-model="item.content"
                  id="codeInput"
                  placeholder="口令或者邀请码"
                />
                <div class="input-bottom" @click="copyMet(item.content)">
                  复制
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="center-fourthly">
        <div class="center-fourthly-step">
          <div class="step-headline">
            <div class="step-headline-img">
              <img
                src="../../assets/particulars/particulars-step-uploading.png"
                alt=""
              />
            </div>
            <div class="step-headline-title">上传验证/考核</div>
          </div>
          <div class="step-lint"></div>

          <div
            class="step-list"
            v-for="(item, index) in taskExamineList"
            :key="index"
          >
            <div class="step-list-left">
              <img
                class="left-img"
                :src="
                  index == 0
                    ? require('../../assets/particulars/particulars-step-icon-2.png')
                    : index == 1
                    ? require('../../assets/particulars/particulars-step-icon-3.png')
                    : index == 2
                    ? require('../../assets/particulars/particulars-step-icon-4.png')
                    : require('../../assets/particulars/particulars-step-icon-5.png')
                "
                alt=""
              />

              <div class="left-lint"></div>
            </div>
            <div class="step-list-right">
              <div class="step-list-right-title">
                {{ item.description }}
              </div>
              <div class="step-list-right-block" v-if="item.type == 0">
                <div class="block-img" @click="PreviewMet(item.content)">
                  <div class="block-img-title">说明图</div>
                  <div class="block-img-radio">验证图</div>
                  <img class="block-img-mes" :src="item.content" alt="" />
                </div>
                <div
                  class="block-button"
                  v-if="item.auditContent == null && particularsStatus == 1"
                >
                  <div class="block-button-img" @click="uploadingMet(item.id)">
                    <img
                      src="../../assets/particulars/particulars-use-dowload.png"
                      alt=""
                    />
                  </div>
                </div>

                <div
                  class="block-rightimg"
                  v-if="item.auditContent != null"
                  @click="PreviewMet(item.auditContent)"
                >
                  <img :src="item.auditContent" alt="" />
                </div>
              </div>

              <div class="step-list-right-input" v-if="item.type == 1">
                <van-field
                  v-model="item.auditContent"
                  placeholder="请输入用户名"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="particulars-receiving">
      <div v-if="particularsStatus == 1">
        <div class="receiving-title">
          请在
          <van-count-down
            :time="ParticularsObj.residueTime"
          />内完成提交，逾期自动取消
        </div>
        <div class="receiving-display">
          <div
            class="receiving-display-list"
            v-for="(item, index) in 2"
            :key="index"
            @click="abandonMet(index)"
          >
            {{ index == 0 ? "放弃接单" : "提交验证" }}
          </div>
        </div>
      </div>

      <div v-else>
        <div
          v-if="particularsStatus == 0"
          class="receiving-bottom bottomActive0"
          @click="registerMet()"
        >
          点击报名
        </div>
        <div
          v-if="particularsStatus == 2"
          class="receiving-bottom bottomActive2"
        >
          正在审核
        </div>
        <div
          v-if="particularsStatus == 3 && ParticularsObj.isAgain == 1"
          @click="registerMet()"
          class="receiving-bottom bottomActive3"
        >
          审核通过（可重新报名）
        </div>

        <div
          v-if="particularsStatus == 3 && ParticularsObj.isAgain != 1"
          class="receiving-bottom bottomActive3"
        >
          审核通过
        </div>
        <div
          v-if="particularsStatus == 4 && ParticularsObj.isAgain == 1"
          class="receiving-bottom bottomActive4"
          @click="registerMet()"
        >
          不合格（可重新报名）
        </div>

        <div
          v-if="particularsStatus == 4 && ParticularsObj.isAgain != 1"
          class="receiving-bottom bottomActive4"
          @click="registerMet()"
        >
          不合格
        </div>

        <div
          v-if="particularsStatus == 5 && ParticularsObj.isAgain == 1"
          class="receiving-bottom bottomActive1"
          @click="registerMet()"
        >
          已放弃（可重新报名）
        </div>

        <div
          v-if="particularsStatus == 5 && ParticularsObj.isAgain != 1"
          class="receiving-bottom bottomActive1"
        >
          已放弃
        </div>
      </div>
    </div>

    <!--弹出框-->
    <div class="particulars-popup">
      <van-popup v-model="popupShow">
        <div class="particulars-popup-div">
          <div class="popup-div-back">
            <img src="../../assets/particulars/particulars-popup.png" alt="" />
          </div>
          <div class="popup-div-area">
            <div class="area-navigation">
              <div class="area-navigation-title">赚赏金 就三步</div>
            </div>
            <div class="area-step">
              <div
                class="area-step-list"
                v-for="(item, index) in 3"
                :key="index"
              >
                <div class="area-step-list-left">
                  <img
                    class="left-img"
                    :src="
                      index == 0
                        ? require('../../assets/particulars/particulars-step-icon-2.png')
                        : index == 1
                        ? require('../../assets/particulars/particulars-step-icon-3.png')
                        : require('../../assets/particulars/particulars-step-icon-4.png')
                    "
                    alt=""
                  />

                  <div class="left-lint" v-if="index != 2"></div>
                </div>
                <div class="area-step-list-right">
                  <div class="right-title">
                    {{
                      index == 0
                        ? "报名，做悬赏"
                        : index == 1
                        ? "提交，等审核"
                        : "通过，得赏金"
                    }}
                  </div>
                  <div class="right-text">
                    {{
                      index == 0
                        ? "选择感兴趣的悬赏任务，仔细阅读任务步骤，点击报名任务。"
                        : index == 1
                        ? "按要求提交指定信息、截图，等待审核。"
                        : "悬赏主审核通过后，发放佣金。"
                    }}
                  </div>
                </div>
              </div>

              <div class="area-step-hint">
                <div class="area-step-hint-img">
                  <van-checkbox v-model="hintChecked" @change="checkedChange">
                    不再提示
                    <!-- <template #icon="props">
                      <img
                        class="img-icon"
                        :src="
                          props.hintChecked
                            ? require('../../assets/common/common-isCheck.png')
                            : require('../../assets/common/common-isCheck.png')
                        "
                      />
                    </template> -->
                  </van-checkbox>
                </div>
              </div>

              <div class="area-step-button" @click="beginMet">开始赚钱</div>
            </div>
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import inform from "../../components/inform/inform.vue"; // 消息通知组件

import {
  homeParticulars,
  homeApply,
  homeAbandon,
  homeSubreview,
} from "../../api/home";

import { Toast } from "vant";

import { ImagePreview } from "vant"; // 引入Vant图片预览组件

import { timeVerification } from "../../plugins/date";

export default {
  components: {
    inform,
  },
  data() {
    return {
      // 获取当前任务的id
      id: "",
      // 消息通知
      informList: ["禁止私下交易,打钱或涉及支付的任务一律不能做"],
      // 审核
      stepValue: "",
      // 任务详情弹出框
      popupShow: true,
      // 任务详情弹出框 复选
      hintChecked: false,
      // 0 点击报名 1 已接单 2 已提交 3 审核通过 4 审核不通过 5 已放弃
      particularsStatus: 0,
      // --------接口存放数据--------------
      ParticularsObj: {},
      taskStepList: [], // 任务步骤流程
      taskExamineList: [], //
      taskExamineArray: [], // 用于存放当前数组
      // 图片暂缓
      // imgs: "https://after.byqp99.com/jeecg-bootsys/common/static/",
      // imgs: "https://backendweb.661222.com/jeecg-boot/sys/common/static/",
      imgs: process.env.VUE_APP_IMAGE_URL,
      assignment: false, // 赋值总体
    };
  },
  created() {
    this.id = this.$route.query.id;
    // 获取当前任务详情
    this.homeParticularsMet();

    // window.transferConsultResult = this.transferConsultResult;

    if (localStorage.getItem("hintChecked")) {
      this.popupShow = !localStorage.getItem("hintChecked");
    }
  },
  mounted() {
    window.transferConsultResult = this.transferConsultResult;
  },
  methods: {
    // // ios传递参数
    transferConsultResult(value) {
      console.log("接收到ios参数1", value);

      let result = JSON.parse(value);

      // let result = {
      //   id: "1633492479809159170",
      //   img: "temp/u31556179482476898094fm253fmtautoapp138fJPEG_1678335014268.webp",
      // };
      console.log("接收到ios参数", result);

      if (result.img != null) {
        if (result.img.substr(0, 3) != "htt") {
          var fileimg = this.imgs + result.img;
        }
      }

      console.log("查询打印", fileimg);

      this.taskExamineList.forEach((item) => {
        if (item.id == result.id) {
          item.auditContent = fileimg;
        }
      });
    },

    // 返回上一层
    backRight() {
      this.$router.go(-1);
    },
    // 获取当前任务详情接口数据
    homeParticularsMet() {
      let params = this.id; // 任务id
      homeParticulars(params).then((res) => {
        if (res.code == 200) {
          res.result.tabs = res.result.tag.split(",");

          if (res.result.mainImg != null) {
            if (res.result.mainImg.substr(0, 3) != "htt") {
              res.result.mainImg = this.imgs + res.result.mainImg;
            }
          }
          res.result.residueTime = res.result.residueTime * 1000;
          res.result.receivingTimeLimit = timeVerification(res.result.receivingTimeLimit);
          res.result.auditTimeAverage = timeVerification(res.result.auditTimeAverage);
          res.result.usedTimeAverage = timeVerification(res.result.usedTimeAverage);
          this.ParticularsObj = res.result;
          this.particularsStatus = res.result.usedStatus;
          res.result.taskStepList.forEach((item) => {
            if (item.type == 0 || item.type == 1) {
              if (item.content != null) {
                if (item.content.substr(0, 3) != "htt") {
                  item.content = this.imgs + item.content;
                }
              }
            }
          });

          this.taskStepList = res.result.taskStepList;

          res.result.taskExamineList.forEach((item) => {
            if (item.type == 0) {
              if (item.content != null) {
                if (item.content.substr(0, 3) != "htt") {
                  item.content = this.imgs + item.content;
                }
              }
            }
          });

          this.taskExamineList = res.result.taskExamineList;
          console.log("步骤id", this.taskExamineList);
        }
      });
    },

    // 点击底部状态按钮事件
    StatusMet() {
      console.log("点击事件", this.particularsStatus);
      if (this.particularsStatus == 0 || this.particularsStatus == 4)
        this.registerMet();
    },

    // 用户点击报名参加接口
    registerMet() {
      let params = {
        id: this.id, // 任务详情
      };
      homeApply(params).then((res) => {
        console.log("申请报名", res);
        if (res.code == 200) {
          this.homeParticularsMet();
        } else {
          Toast(res.message);
        }
      });
    },

    abandonMet(index) {
      if (index == 0) this.abandonOrderMet();
      else this.reviewsubMet();
    },

    // 放弃接单
    abandonOrderMet() {
      let params = this.ParticularsObj.userTaskId;

      homeAbandon(params).then((res) => {
        if (res.code == 200) {
          Toast(res.message);
          this.homeParticularsMet();
        }
      });
    },

    // 提交审核
    reviewsubMet() {
      if (this.taskExamineList.length == 0) {
        Toast("请完善任务流程，奖励送不停~");
        return;
      }

      let array = [];
      this.taskExamineList.forEach((item) => {
        if (item.auditContent != null) {
          array.push({
            taskExamineId: item.id,
            auditContent: item.auditContent,
          });
        }
      });

      console.log("array", array);

      for (let i = 0; i < array.length; i++) {
        if (array[i].auditContent == "") {
          this.assignment = true;
        }
      }

      if (
        array.length == 0 ||
        this.assignment == true ||
        array.length != this.taskExamineList.length
      ) {
        Toast("请完善任务流程，奖励送不停~");
        return;
      }

      console.log("最后一步");

      let params = {
        userTaskId: this.ParticularsObj.userTaskId, //用户任务id
        taskExamineList: array,
      };

      console.log("输入打印审核", params);

      homeSubreview(params).then((res) => {
        if (res.code == 200) {
          Toast(res.message);
          this.homeParticularsMet();
        }
      });
    },

    // 用户点击复制
    copyMet(item) {
      this.copy(item);
    },
    // 复制功能
    copy(data) {
      // 存储传递过来的数据
      let OrderNumber = data;
      // 创建一个input 元素
      // createElement() 方法通过指定名称创建一个元素
      let newInput = document.createElement("input");
      // 讲存储的数据赋值给input的value值
      newInput.value = OrderNumber;
      // appendChild() 方法向节点添加最后一个子节点。
      document.body.appendChild(newInput);
      // 选中input元素中的文本
      // select() 方法用于选择该元素中的文本。
      newInput.select();
      // 执行浏览器复制命令
      //  execCommand方法是执行一个对当前文档，当前选择或者给出范围的命令
      document.execCommand("Copy");
      // 清空输入框
      newInput.remove();
      // 下面是element的弹窗 不需要的自行删除就好
      Toast("复制成功");
    },

    // -------------------------

    // 图片预览
    PreviewMet(images) {
      let array = [];
      array.push(images);
      ImagePreview({
        images: array, //需要预览的图片 URL 数组
        showIndex: true, //是否显示页码
        loop: false, //是否开启循环播放
        startPosition: 0, //图片预览起始位置索引
      });
    },
    // 用户进入的时候判断是否开启任务步骤
    checkedChange(e) {
      console.log("查看是否勾选", e);
      this.hintChecked = e;
    },
    // 开始赚钱
    beginMet() {
      localStorage.setItem("hintChecked", this.hintChecked);
      this.popupShow = false;
    },

    // 用户点击保存图片
    saveMet(item) {
      var u = navigator.userAgent,
        app = navigator.appVersion;
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android终端或者uc浏览器
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isAndroid) {
        window.androidShangJIn.actionImg(item);
        console.log("安卓模块");
      } else if (isiOS) {
        console.log("ios模块");
        window.webkit.messageHandlers.ActionImg.postMessage(item);
      }
    },

    // 用户点击上传
    uploadingMet(id) {
      console.log("获取当前审核步骤id", id);

      var u = navigator.userAgent,
        app = navigator.appVersion;
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android终端或者uc浏览器
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isAndroid) {
        window.androidShangJIn.uploadingImgid(id);
        console.log("安卓模块");
      } else if (isiOS) {
        console.log("ios模块");
        window.webkit.messageHandlers.uploadingImgId.postMessage(id);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.particulars {
  padding-bottom: 30px;
  .particulars-top {
    position: relative;
    .particulars-top-back {
      height: 187.5px;
      width: 100%;
      position: absolute;
      display: flex;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .particulars-top-navigation {
      position: relative;
      display: flex;
      padding: 0 13px;
      padding-top: 57px;
      box-sizing: border-box;

      img {
        width: 9.5px;
        height: 15.5px;
      }
    }
  }
  .particulars-center {
    margin-top: 13.5px;
    position: relative;
    padding: 0 16px;
    box-sizing: border-box;
    .center-first {
      width: 100%;
      background: #ffffff;
      border-radius: 5px;
      padding-bottom: 20px;
      .center-first-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 13px;
        padding-top: 20px;
        box-sizing: border-box;
        .center-first-top-left {
          width: 218px;
          display: flex;
          .left-img {
            width: 49.5px;
            height: 49.5px;
            border-radius: 5px;
            margin-right: 10px;

            img {
              width: 100%;
              height: 100%;
            }
          }
          .left-block {
            width: 157px;
            .left-block-top {
              display: flex;
              height: 20px;
              align-items: center;
              .left-block-top-title {
                font-size: 14.5px;
                font-family: Hiragino Sans GB;
                font-weight: bold;
                color: #000000;
              }
              .left-block-top-img {
                display: flex;
                margin-left: 6px;
                img {
                  width: 19px;
                  height: 19px;
                  margin-right: 3.5px;
                }
              }
            }
            .left-block-center {
              display: flex;
              flex-wrap: wrap;
              margin-top: 5px;
              .center-title {
                font-size: 11.5px;
                font-family: Hiragino Sans GB;
                font-weight: normal;
                color: #ffffff;
                padding: 3px 6px;
                background: #4a92ea;
                border-radius: 5px;
                margin-right: 5px;
                margin-bottom: 5px;
                min-width: 56.5px;
                text-align: center;
              }
            }
          }
        }
        .center-first-top-right {
          .right-price {
            font-size: 22.5px;
            font-family: Hiragino Sans GB;
            font-weight: bold;
            color: #e74c45;
          }
        }
      }

      .center-first-center {
        padding: 0 5px;
        margin-top: 20px;
        box-sizing: border-box;
        .center-first-center-display {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .display-list {
            width: calc(100% / 2);
            .display-list-block {
              text-align: center;
              border-right: 1px solid #e9e9e9;

              .display-list-block-title {
                font-size: 14.5px;
                font-family: Hiragino Sans GB;
                font-weight: bold;
                color: #000000;
              }
              .display-list-block-text {
                font-size: 16.5px;
                font-family: Hiragino Sans GB;
                font-weight: normal;
                color: #696767;
                margin-top: 9.5px;
                font-weight: bold;
              }
            }
          }
          .display-list:last-child {
            .display-list-block {
              border-right: 0;
            }
          }
        }
      }
    }

    .center-second {
      display: flex;
      align-items: center;
      height: 37px;
    }
    .center-thirdly {
      width: 100%;
      padding: 20px 6.5px;
      box-sizing: border-box;
      background: #ffffff;
      .center-thirdly-step {
        width: 100%;
        .step-headline {
          display: flex;
          align-items: center;
          height: 20px;
          .step-headline-img {
            width: 18px;
            height: 16.5px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .step-headline-title {
            font-size: 15.5px;
            font-family: Hiragino Sans GB;
            font-weight: bold;
            color: #e74c45;
            margin-left: 10px;
          }
        }
        .step-lint {
          width: 100%;
          background: #e9e9e9;
          height: 1px;
          margin: 20px 0;
        }

        .step-list {
          display: flex;
          padding-bottom: 20px;
          .step-list-left {
            width: 27.5px;
            .left-img {
              width: 27.5px;
              height: 27.5px;
            }
            .left-lint {
              height: 100%;
              background: red;
              width: 1px;
              margin: 0 auto;
              margin-top: -7px;
            }
          }
          .step-list-right {
            margin-left: 20px;
            width: 100%;
            .step-list-right-title {
              font-size: 14.5px;
              font-family: Hiragino Sans GB;
              font-weight: bold;
              color: #000000;
            }
            .step-list-right-text {
              font-size: 14.5px;
              font-family: Hiragino Sans GB;
              font-weight: normal;
              color: #e74c45;
              margin-top: 10px;
            }
            .step-list-right-input {
              height: 31px;
              display: flex;
              align-items: center;
              margin-top: 10px;

              /deep/ .van-cell {
                padding: 0 10px;
                width: 208.5px;
                height: 31px;
                border: 1px solid #e74c45;
                border-radius: 5px;
              }

              /deep/ .van-field__control {
                font-size: 11.5px;
                font-family: Hiragino Sans GB;
                font-weight: normal;
                color: #e74c45 !important;
              }

              /deep/ .van-field__control::-webkit-input-placeholder {
                color: #e74c45;
              }

              /deep/ .van-field__body {
                height: 31px;
              }

              .input-bottom {
                width: 60.5px;
                height: 31px;
                background: #e74c45;
                border-radius: 5px;
                margin-left: 5px;
                text-align: center;
                line-height: 31px;
                color: #ffffff;
                font-size: 14px;
              }
            }

            .step-list-right-block {
              display: flex;
              margin-top: 10px;
              align-items: flex-end;
              justify-content: space-between;

              .block-img {
                width: 124.5px;
                height: 200px;
                background: #eae9e9;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                  width: 124.5px;
                  height: 200px;
                  display: flex;
                  position: absolute;
                }

                .block-img-title {
                  width: 49.5px;
                  height: 20px;
                  background: #9c9b9b;
                  border-radius: 0px 5px 5px 0px;
                  text-align: center;
                  line-height: 20px;

                  font-size: 11px;
                  font-family: Hiragino Sans GB;
                  font-weight: normal;
                  color: #ffffff;
                  position: absolute;
                  top: 0;
                  left: 0;
                }

                .block-img-radio {
                  width: 71px;
                  height: 71px;
                  border-radius: 50%;

                  font-size: 13px;
                  font-family: Hiragino Sans GB;
                  font-weight: normal;
                  color: #181717;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
              }

              .block-button {
                width: 83.5px;
                height: 28.5px;
                background: #f6bc0c;
                border-radius: 5px;
                text-align: center;
                line-height: 28.5px;

                font-size: 14px;
                font-family: Hiragino Sans GB;
                font-weight: normal;
                color: #ffffff;
              }
            }
          }
        }
      }
    }
    .center-fourthly {
      margin-top: 12.5px;
      width: 100%;
      padding: 0 16.5px;
      box-sizing: border-box;
      background: #ffffff;
      .center-fourthly-step {
        width: 100%;
        padding: 20px 0;

        .step-headline {
          display: flex;
          align-items: center;
          height: 20px;
          .step-headline-img {
            width: 21.5px;
            height: 16.5px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .step-headline-title {
            font-size: 14.5px;
            font-family: Hiragino Sans GB;
            font-weight: bold;
            color: #e74c45;
            margin-left: 20px;
          }
        }
        .step-lint {
          width: 100%;
          background: #e9e9e9;
          height: 1px;
          margin: 20px 0;
        }

        .step-list {
          display: flex;
          padding-bottom: 20px;
          .step-list-left {
            width: 27.5px;
            .left-img {
              width: 27.5px;
              height: 27.5px;
            }
            .left-lint {
              height: 100%;
              background: red;
              width: 1px;
              margin: 0 auto;
              margin-top: -7px;
            }
          }
          .step-list-right {
            margin-left: 20px;
            width: 100%;

            .step-list-right-input {
              height: 31px;
              display: flex;
              align-items: center;
              margin-top: 10px;

              /deep/ .van-cell {
                padding: 0 10px;
                width: 100%;
                height: 31px;
                border: 1px solid #e74c45;
                border-radius: 5px;
              }

              /deep/ .van-field__control {
                font-size: 11.5px;
                font-family: Hiragino Sans GB;
                font-weight: normal;
                color: #e74c45 !important;
              }

              /deep/ .van-field__control::-webkit-input-placeholder {
                color: #e74c45;
              }

              /deep/ .van-field__body {
                height: 31px;
              }

              .input-bottom {
                width: 60.5px;
                height: 31px;
                background: #e74c45;
                border-radius: 5px;
                margin-left: 5px;
                text-align: center;
                line-height: 31px;
                color: #ffffff;
                font-size: 14px;
              }
            }

            .step-list-right-block {
              display: flex;
              margin-top: 10px;
              align-items: flex-start;
              justify-content: space-between;
              .block-img {
                width: 124.5px;
                height: 200px;
                background: #eae9e9;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;

                .block-img-title {
                  width: 49.5px;
                  height: 20px;
                  background: #9c9b9b;
                  border-radius: 0px 5px 5px 0px;
                  text-align: center;
                  line-height: 20px;

                  font-size: 11px;
                  font-family: Hiragino Sans GB;
                  font-weight: normal;
                  color: #ffffff;
                  position: absolute;
                  top: 0;
                  left: 0;
                  z-index: 1;
                }

                .block-img-radio {
                  width: 71px;
                  height: 71px;
                  border-radius: 50%;

                  font-size: 13px;
                  font-family: Hiragino Sans GB;
                  font-weight: normal;
                  color: #181717;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  position: relative;
                  z-index: 1;
                }

                .block-img-mes {
                  width: 124.5px;
                  height: 200px;
                  position: absolute;
                  display: flex;
                }
              }
              .block-button {
                width: 60px;
                height: 60px;

                .block-button-img {
                  width: 60px;
                  height: 60px;
                  position: relative;
                  img {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    display: flex;
                  }

                  .block-button-img-file {
                    position: absolute;
                    width: 60px;
                    height: 60px;
                    left: 0;
                    opacity: 0;
                  }

                  /deep/ .van-uploader__upload {
                    width: 60px;
                    height: 60px;
                  }
                }
              }

              .block-rightimg {
                width: 124.5px;
                height: 200px;

                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }
      }
    }
  }

  .particulars-receiving {
    margin-top: 25px;
    .receiving-title {
      font-size: 16px;
      color: #000000;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;

      /deep/ .van-count-down {
        font-size: 16px;
        margin: 0 5px;
      }
    }

    .receiving-display {
      display: flex;
      justify-content: center;
      align-items: center;
      .receiving-display-list {
        margin-right: 20px;
        width: 140.5px;
        height: 42px;
        background: #ffffff;
        border: 1px solid #7c7a7a;
        border-radius: 5px;
        font-size: 18.5px;
        font-family: Hiragino Sans GB;
        font-weight: 500;
        color: #696767;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .receiving-display-list:last-child {
        margin-right: 0;
        background: linear-gradient(
          0deg,
          #ed4034 0%,
          #ed3325 51%,
          #da261a 100%
        );

        border: 0;
        color: #ffffff;
      }
    }

    .receiving-bottom {
      width: 290px;
      height: 42px;
      border-radius: 5px;
      margin: 0 auto;
      font-size: 18.5px;
      font-family: Hiragino Sans GB;
      font-weight: normal;
      color: #ffffff;
      text-align: center;
      line-height: 42px;
      margin-top: 25px;
      border: 1px solid;
    }

    .bottomActive0 {
      background: linear-gradient(0deg, #ed4034 0%, #ed3325 51%, #da261a 100%);
    }
    .bottomActive1 {
      background: #a8a7a7;
    }
    .bottomActive2 {
      background: #ff7e27;
    }
    .bottomActive3 {
      background: #47b645;
    }
    .bottomActive4 {
      background: #de1b12;
    }
  }

  // 弹出框
  .particulars-popup {
    /deep/.van-popup {
      background-color: rgba(255, 255, 255, 0) !important;
    }

    /deep/ .van-overlay {
      background-color: rgba(0, 0, 0, 0.45) !important;
    }
    .particulars-popup-div {
      width: 294.5px;
      height: 100%;
      position: relative;
      .popup-div-back {
        width: 167.5px;
        height: 123px;
        display: flex;
        position: absolute;
        margin-left: 84.5px;

        img {
          width: 100%;
          height: 100%;
        }
      }
      .popup-div-area {
        padding-top: 77px;
        box-sizing: border-box;

        .area-navigation {
          width: 100%;
          height: 90px;
          background: linear-gradient(
            0deg,
            #ed4034 0%,
            #ed3325 51%,
            #da261a 100%
          );
          border-radius: 5px;
          text-align: center;

          .area-navigation-title {
            font-size: 27.5px;
            font-family: Adobe Heiti Std;
            font-weight: normal;
            color: #ffffff;
            padding-top: 48.5px;
          }
        }

        .area-step {
          background: #ffffff;
          padding: 20px 33.5px;
          box-sizing: border-box;
          .area-step-list {
            display: flex;
            padding-bottom: 20px;

            .area-step-list-left {
              width: 27.5px;
              .left-img {
                width: 27.5px;
                height: 27.5px;
              }
              .left-lint {
                height: 100%;
                background: #47b645;
                width: 1px;
                margin: 0 auto;
                margin-top: -7px;
              }
            }

            .area-step-list-right {
              margin-left: 20px;
              width: 100%;

              .right-title {
                font-size: 13.5px;
                font-family: Hiragino Sans GB;
                font-weight: bold;
                color: #e74c45;
              }

              .right-text {
                margin-top: 12px;
                font-size: 11.5px;
                font-family: Hiragino Sans GB;
                font-weight: normal;
                color: #595454;
              }
            }
          }
          .area-step-hint {
            display: flex;
            align-items: center;
            margin-left: 22.5px;
            .area-step-hint-img {
              /dee/ .van-checkbox__icon .van-icon {
                width: 13px !important;
                height: 13px !important;
              }
              /deep/ .van-checkbox__icon {
                font-size: 16px;
              }

              /deep/ .van-checkbox__icon--checked .van-icon {
                width: 16px;
                height: 16px;
                line-height: 16px;
              }

              /deep/ .van-checkbox__label {
                font-size: 12.5px;
                font-family: Hiragino Sans GB;
                font-weight: normal;
                color: #595454;
              }
            }
          }
          .area-step-button {
            width: 228.5px;
            height: 37.5px;
            background: linear-gradient(
              0deg,
              #ed4034 0%,
              #ed3325 51%,
              #da261a 100%
            );
            border-radius: 5px;
            margin-top: 22px;
            font-size: 15px;
            font-family: Hiragino Sans GB;
            font-weight: normal;
            color: #ffffff;
            text-align: center;
            line-height: 37.5px;
          }
        }
      }
    }
  }
}
</style>
<template>
  <div class="tab">
    <van-tabbar
      v-model="active"
      @change="onChange"
      active-color="#F6503C"
      inactive-color="#A8A7A7"
    >
      <van-tabbar-item name="home">
        任务赚钱
        <template #icon="props">
          <img
            :src="
              props.active
                ? require('../../assets/common/common-tabbar-home.png')
                : require('../../assets/common/common-tabbar-home-isActive.png')
            "
          />
        </template>
      </van-tabbar-item>
      <van-tabbar-item name="ranking">
        排行榜
        <template #icon="props">
          <img
            :src="
              props.active
                ? require('../../assets/common/common-tabbar-ranking.png')
                : require('../../assets/common/common-tabbar-ranking-isActive.png')
            "
          />
        </template>
      </van-tabbar-item>
      <van-tabbar-item name="fission"
        >裂变赚钱
        <template #icon="props">
          <img
            :src="
              props.active
                ? require('../../assets/common/common-tabbar-integral.png')
                : require('../../assets/common/common-tabbar-integral-isActive.png')
            "
          />
        </template>
      </van-tabbar-item>
      <van-tabbar-item name="signin"
        >签到红包
        <template #icon="props">
          <img
            :src="
              props.active
                ? require('../../assets/common/common-tabbar-signin.png')
                : require('../../assets/common/common-tabbar-signin-isActive.png')
            "
          />
        </template>
      </van-tabbar-item>
      <van-tabbar-item name="my"
        >我的
        <template #icon="props">
          <img
            :src="
              props.active
                ? require('../../assets/common/common-tabbar-my.png')
                : require('../../assets/common/common-tabbar-my-isActive.png')
            "
          />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: "",
    };
  },
  watch: {
    $route(to) {
      this.active = to.name;
    },
  },
  created() {
    console.log("打印当前路径", this.$route.name);
    this.active = this.$route.name;
  },
  methods: {
    onChange(e) {
      console.log("切换下标", e);
      this.$router.replace({
        name: e,
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .van-tabbar {
  height: 67.5px !important;
  z-index: 2100 !important;
}

/deep/ .van-tabbar-item__icon {
  margin-bottom: 6px;
}
</style>
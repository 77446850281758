<template>
  <div class="rule">
    <streamer :title="title"></streamer>

    <div class="rule-matter" v-html="context">
      
    </div>
  </div>
</template>

<script>
import streamer from "../../components/streamer/streamer.vue";

import { loginAgreement } from "../../api/login";

export default {
  components: {
    streamer,
  },
  data() {
    return {
      title: "", // 标题
      context: "",
    };
  },
  created() {
    this.agreementMet()
  },
  methods: {
    agreementMet() {
      let params = {
        type: 4,
      };

      loginAgreement(params).then((res) => {
        if (res.code == 200) {
          this.context = res.result.content;
          this.title = res.result.title;
        }
      });
    },
  }
};
</script>

<style lang="less" scoped>
.rule {
  height: 100vh;
  background: #ffffff;

  .rule-matter {
    padding: 23px 35px;
    box-sizing: border-box;
    font-size: 14.5px;
    font-family: Hiragino Sans GB;
    font-weight: normal;
    color: #000000;
    line-height: 24px;
    letter-spacing: 1px;
  }
}
</style>
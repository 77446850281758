<template>
  <div class="squeeze">
    <van-swipe style="height: 100vh" vertical :loop="false">
      <van-swipe-item v-for="(item, index) in 4" :key="index">
        <div class="squeeze-back">
          <img
            class="squeeze-back-img"
            :src="
              index == 0
                ? require('../../assets/common/common-squeeze-swiper1.png')
                : index == 1
                ? require('../../assets/common/common-squeeze-swiper2.png')
                : index == 2
                ? require('../../assets/common/common-squeeze-swiper3.png')
                : require('../../assets/common/common-squeeze-swiper4.png')
            "
            alt=""
          />
        </div>
      </van-swipe-item>
    </van-swipe>

    <div class="squeeze-button" @click="dowloadMet">
      <div class="squeeze-button-title">开始赚钱</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    // 点击关闭
    closeBtn() {
      this.$router.go(-1);
    },
    // 用户点击下载
    dowloadMet() {
      // https://www.pgyer.com/TsBS
      this.dowloadApp();
    },
    // 下载app
    dowloadApp() {
      var u = navigator.userAgent;
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
      if (isiOS) {
        console.log("我是ios");
      } else if (isAndroid) {
        console.log("我是安卓");
        window.open('https://www.pgyer.com/TsBS')
      }
    },
  },
};
</script>

<style lang="less" scoped>
.squeeze {
  width: 100%;
  height: 100vh;
  position: relative;

  .squeeze-back {
    width: 100%;
    height: 100%;
    .squeeze-back-img {
      width: 100%;
      height: 100%;
    }
  }

  /deep/ .van-swipe__indicators--vertical {
    right: 20px;
    left: unset;
    position: absolute;
  }

  /deep/ .van-swipe__indicator {
    width: 10px;
    height: 10px;
  }

  /deep/
    .van-swipe__indicators--vertical
    .van-swipe__indicator:not(:last-child) {
    margin-bottom: 10px;
  }

  /deep/ .van-swipe__indicator--active {
    width: 10px;
    height: 20px;
    border-radius: 5px;
    background-color: #fff;
  }

  .squeeze-button {
    position: fixed;
    z-index: 1;
    bottom: 87px;
    width: 100%;

    .squeeze-button-title {
      height: 45px;
      background: linear-gradient(93deg, #ed4034 0%, #ed3325 51%, #d3261a 100%);
      border-radius: 22.5px;
      margin: 0 15px;
      text-align: center;
      line-height: 45px;

      font-size: 18px;
      font-family: Hiragino Sans GB;
      font-weight: normal;
      color: #ffffff;

      letter-spacing: 2px;
    }
  }
}
</style>
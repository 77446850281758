<template>
  <div class="rule">
    <streamer :title="title"></streamer>

    <div class="rule-block">
      <div class="rule-block-title">声望增加项</div>

      <div class="rule-block-display">
        <div class="display-list" v-for="(item, index) in 2" :key="index">
          <div class="display-list-left">
            {{ index == 0 ? "每邀请一个有效好友" : "每审核通过一个任务" }}
          </div>
          <div class="display-list-right">{{ index == 0 ? "5" : "5" }}</div>
        </div>
      </div>
    </div>

    <div class="rule-block">
      <div class="rule-block-title">声望减少项</div>

      <div class="rule-block-display">
        <div class="display-list" v-for="(item, index) in 2" :key="index">
          <div class="display-list-title">
            {{
              index == 0
                ? "报名任务成功,但在规定时间内未提交任务,声望值-5(放弃不减声望)"
                : "在做任务过程中,乱传图,传错图,审核不通过,任务申诉平台客服介入，错的一方声望-10"
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import streamer from "../../components/streamer/streamer.vue";

export default {
  components: {
    streamer,
  },
  data() {
    return {
      title: "声望规则", // 标题
    };
  },
};
</script>

<style lang="less" scoped>
.rule {
  .rule-block {
    margin: 0 25px;
    margin-top: 30px;
    height: 189px;
    background: linear-gradient(
      0deg,
      #ef876c 0%,
      #f88377 0%,
      #f16d63 51%,
      #e74d46 100%
    );
    border-radius: 5px;

    .rule-block-title {
      height: 47px;
      line-height: 47px;
      font-size: 14px;
      font-family: Hiragino Sans GB;
      font-weight: normal;
      color: #ffffff;
      text-align: center;
    }

    .rule-block-display {
      height: 133px;
      background: #ffffff;
      border-radius: 5px;
      margin: 0 4px;

      .display-list {
        display: flex;
        align-items: center;
        height: 66px;
        border-bottom: 1px solid #e74c45;
        .display-list-left {
          text-align: center;
          font-size: 14px;
          font-family: Hiragino Sans GB;
          font-weight: normal;
          color: #000000;
          width: 208px;
        }
        .display-list-right {
          font-size: 14px;
          font-family: Hiragino Sans GB;
          font-weight: normal;
          color: #e74c45;
          height: 100%;
          border-left: 1px solid #e74c45;
          width: 108.5px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .display-list-title {
          padding: 0 20px;
          box-sizing: border-box;
          text-align: center;
          font-size: 13.5px;
          font-family: Hiragino Sans GB;
          font-weight: normal;
          color: #000000;
        }
      }

      .display-list:last-child {
        border-bottom: 0;
      }
    }
  }
}
</style>
<template>
  <div class="inform">
    <van-notice-bar background="rgba(255,255,255,0)" :scrollable="false">
      <template #left-icon>
        <div class="notice-left">
          <img :src="color? require('../../assets/home/home-announcement.png'): require('../../assets/home/home-announcement-1.png')" alt="" />
        </div>
      </template>

      <van-swipe
        vertical
        class="notice-swipe"
        :autoplay="3000"
        :show-indicators="false"
      >
        <van-swipe-item
          v-for="(item, index) in title"
          :key="index"
          :style="{ color: color }"
          >{{ item }}</van-swipe-item
        >
      </van-swipe>
    </van-notice-bar>
  </div>
</template>

<script>
export default {
  props: ["title", "color"],
};
</script>

<style lang="less" scoped>
:focus {
  outline: none !important;
}

.inform {
  width: 100%;
  .notice-left {
    width: 18px;
    height: 14px;
    margin-right: 6.5px;
    display: flex;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
    }
  }

  /deep/ .van-notice-bar {
    height: 20px;
    padding: 0 5px;
    box-sizing: border-box;
  }

  .notice-swipe {
    height: 20px;
    line-height: 20px;
  }
  /deep/ .van-swipe-item {
    font-size: 12.5px;
    font-family: Hiragino Sans GB;
    font-weight: normal;
    color: #fff;
  }
}
</style>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"browsing"},[_c('div',{staticClass:"browsing-streamer"},[_vm._m(0),_c('div',{staticClass:"browsing-streamer-back"},[_vm._m(1),_c('div',{staticClass:"back-display"},[_c('div',{staticClass:"display-left",on:{"click":_vm.backRight}},[_c('img',{attrs:{"src":require("../../assets/common/common-left.png"),"alt":""}})]),_c('div',{staticClass:"display-center"},[_vm._v("浏览记录")]),_c('div',{staticClass:"display-right"})])]),_vm._m(2)]),_c('div',{staticClass:"browsing-lint"}),_c('div',{staticClass:"home-scrolling"},[_c('van-list',{attrs:{"finished":_vm.finished,"finished-text":_vm.list.length == 0 ? '' : ''},on:{"load":_vm.onLoad},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"home-scrolling-list",on:{"click":function($event){return _vm.particularsBtn(item.taskId)}}},[_c('div',{staticClass:"list-left"},[(index == 0 || index == 1 || index == 2)?_c('img',{staticClass:"list-left-back",attrs:{"src":index == 0
                ? require('../../assets/home/home-icon-first.png')
                : index == 1
                ? require('../../assets/home/home-icon-second.png')
                : index == 2
                ? require('../../assets/home/home-icon-thirdly.png')
                : '',"alt":""}}):_vm._e(),_c('img',{staticClass:"list-left-circle",attrs:{"src":item.mainImg,"alt":""}})]),_c('div',{staticClass:"list-right"},[_c('div',{staticClass:"list-right-block"},[_c('div',{staticClass:"block-title"},[_c('div',{staticClass:"block-title-headline"},[_vm._v(_vm._s(item.name))]),_vm._l((item.tabs),function(item,index){return _c('div',{key:index,staticClass:"block-title-explode"},[_vm._v(" "+_vm._s(item == 1 ? "推" : item == 2 ? "顶" : "")+" ")])})],2),_c('div',{staticClass:"block-label"},[_c('div',{staticClass:"block-label-list"},[_vm._v(" "+_vm._s(item.type == 0 ? "小程序体验" : item.type == 1 ? "关注体验" : item.type == 2 ? "砍掉互帮" : item.type == 3 ? "试用试玩" : item.type == 4 ? "高阶任务" : item.type == 5 ? "其他任务" : "")+" ")]),_c('div',{staticClass:"block-label-list"},[_vm._v(" "+_vm._s(item.name)+" ")])]),_c('div',{staticClass:"block-span"},[_vm._v(" 已赚"),_c('span',{staticStyle:{"color":"#e94e44"}},[_vm._v("0")]),_vm._v("人 人均用"+_vm._s(item.usedTimeAverage)+" "),_c('span',{staticStyle:{"color":"#e94e44"}},[_vm._v("--")]),_vm._v(" 平均审核"),_c('span',{staticStyle:{"color":"#e94e44"}},[_vm._v(_vm._s(item.auditTimeAverage))]),_vm._v("分钟 ")])])]),_c('div',{staticClass:"list-relocating"},[_vm._v(_vm._s(item.amountUnit)+"元")])])}),_c('empty',{attrs:{"list":_vm.list}})],2)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"browsing-streamer-img"},[_c('img',{attrs:{"src":require("../../assets/signin/signin-bill-streamer.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"back-img"},[_c('img',{attrs:{"src":require("../../assets/signin/signin-bill-back.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"browsing-streamer-tabs"},[_c('div',{staticClass:"tabs-img"},[_c('img',{attrs:{"src":require("../../assets/my/my-history-message.png"),"alt":""}})]),_c('div',{staticClass:"tabs-title"},[_vm._v("该页面只显示最近15天的浏览记录")])])
}]

export { render, staticRenderFns }
<template>
  <div class="bill">
    <div class="bill-streamer">
      <div class="bill-streamer-img">
        <img src="../../assets/signin/signin-bill-streamer.png" alt="" />
      </div>

      <div class="bill-streamer-back">
        <div class="back-img">
          <img src="../../assets/signin/signin-bill-back.png" alt="" />
        </div>

        <div class="back-display">
          <div class="display-left" @click="backRight">
            <img src="../../assets/common/common-left.png" alt="" />
          </div>
          <div class="display-center">收支账单</div>
          <div class="display-right"></div>
        </div>
      </div>

      <div class="bill-streamer-tabs">
        <van-tabs v-model="active" @click="onClick">
          <van-tab
            v-for="(item, index) in 4"
            :key="index"
            :title="
              index == 0
                ? '交易流水'
                : index == 1
                ? '签到奖励'
                : index == 2
                ? '声望明细'
                : '提现记录'
            "
          ></van-tab>
        </van-tabs>
      </div>
    </div>

    <div class="bill-lint"></div>

    <div class="bill-table">
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="list.length == 0 ? '' : ''"
        @load="onLoad"
      >
        <div class="bill-table-div" v-for="(item, index) in list" :key="index">
          <div class="bill-table-div-left">
            <div class="left-block">
              <div class="left-block-title" v-if="active != 1">
                {{ item.source }}
              </div>
              <div v-if="active == 1">第{{ item.signDay }}天</div>
              <div class="left-block-text">{{ item.createTime }}</div>
            </div>
          </div>
          <div class="bill-table-div-right">
            <div class="right-price" v-if="active == 1">
              +{{ item.signPrice }}金币
            </div>
            <div class="right-price" v-if="active == 2">
              {{ item.type == 0 ? "-" : "+" }}{{ item.popularity }}
            </div>
            <!-- <div class="right-price" v-if="active == 3"> -->
            <div class="right-price" v-if="active == 3 || active == 0">
              {{ item.amount }}元
            </div>
          </div>
        </div>
        <empty :list="list"></empty>
      </van-list>
    </div>
  </div>
</template>

<script>
import { siginDetail, mySiginReputation, myWithdraw } from "../../api/signin";
import { myDealwater } from "../../api/my";

import empty from "@/components/empty/empty.vue";
export default {
  components: {
    empty,
  },
  data() {
    return {
      active: 0, // 标签页
      // 首页列表数组
      page: 1, // 当前页码
      limit: 10, // 当前页数
      list: [], // 当前数组列表
      loading: false, // 数组列表加载圈
      finished: false, // 是否暂时没有更多了
      refreshing: false, // 是否进行滚动加载
      totalNum: 0, // 数量
    };
  },
  created() {
    this.active = Number(this.$route.query.index);
  },
  methods: {
    // 返回上一层
    backRight() {
      this.$router.go(-1);
    },
    // 点击切换标题
    onClick(name, title) {
      this.refreshing = true;
      this.page = 1;
      this.finished = false
      this.onLoad();
    },
    // 获取当前列表中对应的数组数据
    onLoad() {
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      if (this.refreshing) {
        this.list = [];
        this.refreshing = false;
      }

      let params = {
        pageSize: this.limit,
        pageNo: this.page,
      };

      if (this.active == 0) {
        myDealwater(params).then((res) => {
          if (res.code == 200) {
            this.list.push(...res.result.withdrawalLogList);
            this.totalNum = res.result.totalNum;
          }
        });
      } else if (this.active == 1) {
        siginDetail(params).then((res) => {
          if (res.code == 200) {
            this.list.push(...res.result.signLogList);
            this.totalNum = res.result.totalNum;
          }
        });
      } else if (this.active == 2) {
        mySiginReputation(params).then((res) => {
          if (res.code == 200) {
            this.list.push(...res.result.popularityLogList);
            this.totalNum = res.result.totalNum;
          }
        });
      } else if (this.active == 3) {
        myWithdraw(params).then((res) => {
          if (res.code == 200) {
            this.list.push(...res.result.withdrawalLogList);
            this.totalNum = res.result.totalNum;
          }
        });
      }

      // 加载状态结束
      this.loading = false;

      // 数据全部加载完成
      if (this.list.length >= this.totalNum) {
        this.finished = true; // 到了滚动极限则返回true
      } else {
        this.page++;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.bill {
  .bill-streamer {
    height: 139px;
    position: fixed;
    z-index: 9;
    width: 100%;
    .bill-streamer-img {
      height: 139px;
      position: absolute;
      display: flex;
      width: 100%;

      img {
        width: 100%;
        height: 100%;
      }
    }
    .bill-streamer-back {
      height: 90.5px;
      position: relative;
      .back-img {
        width: 100%;
        height: 100%;
        display: flex;
        position: absolute;
        img {
          width: 100%;
          height: 100%;
        }
      }

      .back-display {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 54.5px 10px 0;
        box-sizing: border-box;
        position: relative;
        .display-left {
          width: 9.5px;
          height: 15.5px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .display-center {
          font-size: 15.5px;
          font-family: Hiragino Sans GB;
          font-weight: bold;
          color: #ffffff;
        }
        .display-right {
          width: 9.5px;
          height: 15.5px;
        }
      }
    }
    .bill-streamer-tabs {
      padding: 0 10px;
      box-sizing: border-box;
      /deep/ .van-tabs__nav {
        background-color: rgba(255, 255, 255, 0);
      }

      /deep/ .van-tab {
        color: #393838;
        font-size: 14.5px;
      }

      /deep/ .van-tab--active {
        font-weight: bold;
      }

      /deep/ .van-tabs__line {
        height: 5.5px;
        width: 75.5px;
        bottom: 18px;
      }
    }
  }
  .bill-lint {
    height: 139px;
  }
  .bill-table {
    .bill-table-div {
      height: 80.5px;
      padding: 0 10px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #e9e9e9;

      .bill-table-div-left {
        display: flex;
        align-items: center;
        .left-img {
          width: 40px;
          height: 40px;
          background: #fdebe8;
          border-radius: 50%;
        }
        .left-block {
          margin-left: 12px;
          .left-block-title {
            font-size: 14.5px;
            font-family: Hiragino Sans GB;
            font-weight: bold;
            color: #000000;
          }
          .left-block-text {
            margin-top: 15px;
            font-size: 11.5px;
            font-family: Hiragino Sans GB;
            font-weight: normal;
            color: #696767;
          }
        }
      }
      .bill-table-div-right {
        .right-price {
          font-size: 13.5px;
          font-family: Hiragino Sans GB;
          font-weight: bold;
          color: #e74c45;
        }
      }
    }

    .bill-table-div:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
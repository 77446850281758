<template>
  <div>
    <div class="streamer">
      <div class="streamer-img">
        <img src="../../assets/ranking/ranking-rule-streamer.png" alt="" />
      </div>

      <div class="streamer-display">
        <div class="display-left" @click="backRight">
          <img src="../../assets/common/common-left.png" alt="" />
        </div>
        <div class="display-center">{{ title }}</div>
        <div class="display-right"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title"],
  data() {
    return {};
  },
  methods: {
    // 返回上一层
    backRight() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.streamer {
  position: relative;
  height: 90.5px;
  width: 100%;

  .streamer-img {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .streamer-display {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 54.5px 13.5px 0;
    box-sizing: border-box;
    position: relative;
    .display-left {
      width: 9.5px;
      height: 15.5px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .display-center {
      font-size: 15.5px;
      font-family: Hiragino Sans GB;
      font-weight: bold;
      color: #ffffff;
    }
    .display-right {
      width: 9.5px;
      height: 15.5px;
    }
  }
}
</style>


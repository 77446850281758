<template>
  <div class="regard">
    <streamer :title="title"></streamer>

    <div class="regard-logo">
      <img src="../../assets/common/logo-60@3x.png" alt="" />
    </div>

    <div class="regard-block">
      <div
        class="regard-block-list"
        v-for="(item, index) in 3"
        :key="index"
        @click="regardBtn(index)"
      >
        <van-cell
          :title="
            index == 0 ? '版本更新' : index == 1 ? '用户协议' : '隐私政策'
          "
          :value="index == 0 ? versions : index == 1 ? '' : ''"
          size="large"
          is-link
        />
      </div>
    </div>
  </div>
</template>

<script>
import streamer from "../../components/streamer/streamer.vue";

import { myVersions } from "../../api/my";

export default {
  components: {
    streamer,
  },
  data() {
    return {
      title: "关于我们", // 标题
      versions: ''
    };
  },
  created() {
    var userAgent = navigator.userAgent;
    var isiOS = !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    var isAndroid =
      userAgent.indexOf("Android") > -1 || userAgent.indexOf("Adr") > -1;

      if(isAndroid) {
        this.myVersionsMet(0)
      }
      if(isiOS) {
        this.myVersionsMet(1)
      }
  },
  methods: {
    myVersionsMet(index) {
      let params = {
        type: index,
      };

      myVersions(params).then((res) => {
        if(res.code == 200) {
          this.versions = res.result.versions
        }
      });
    },
    // 点击对应的跳转协议
    regardBtn(index) {
      if (index == 1) {
        this.$router.push({
          path: "/agreement-user",
        });
      } else if (index == 2) {
        this.$router.push({
          path: "/agreement-privacy",
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.regard {
  height: 100vh;
  position: relative;
  .regard-logo {
    width: 60.5px;
    height: 60.5px;
    margin: 65px auto;

    img {
      width: 100%;
      height: 100%;
      border-radius: 5px;
    }
  }

  .regard-block {
    height: 189px;
    background: #ffffff;
    border-radius: 5px;
    margin: 0 12.5px;

    .regard-block-list {
      height: 61px;
      width: 100%;
      border-bottom: 1px solid #e9e9e9;
      padding: 0 28px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;

      /deep/ .van-cell--clickable:active {
        background: #ffffff;
      }
      /deep/ .van-cell--large .van-cell__title {
        font-weight: bold;
      }
    }

    .regard-block-list:last-child {
      border-bottom: 0;
    }
  }
}
</style>
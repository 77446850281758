var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab"},[_c('van-tabbar',{attrs:{"active-color":"#F6503C","inactive-color":"#A8A7A7"},on:{"change":_vm.onChange},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('van-tabbar-item',{attrs:{"name":"home"},scopedSlots:_vm._u([{key:"icon",fn:function(props){return [_c('img',{attrs:{"src":props.active
              ? require('../../assets/common/common-tabbar-home.png')
              : require('../../assets/common/common-tabbar-home-isActive.png')}})]}}])},[_vm._v(" 任务赚钱 ")]),_c('van-tabbar-item',{attrs:{"name":"ranking"},scopedSlots:_vm._u([{key:"icon",fn:function(props){return [_c('img',{attrs:{"src":props.active
              ? require('../../assets/common/common-tabbar-ranking.png')
              : require('../../assets/common/common-tabbar-ranking-isActive.png')}})]}}])},[_vm._v(" 排行榜 ")]),_c('van-tabbar-item',{attrs:{"name":"fission"},scopedSlots:_vm._u([{key:"icon",fn:function(props){return [_c('img',{attrs:{"src":props.active
              ? require('../../assets/common/common-tabbar-integral.png')
              : require('../../assets/common/common-tabbar-integral-isActive.png')}})]}}])},[_vm._v("裂变赚钱 ")]),_c('van-tabbar-item',{attrs:{"name":"signin"},scopedSlots:_vm._u([{key:"icon",fn:function(props){return [_c('img',{attrs:{"src":props.active
              ? require('../../assets/common/common-tabbar-signin.png')
              : require('../../assets/common/common-tabbar-signin-isActive.png')}})]}}])},[_vm._v("签到红包 ")]),_c('van-tabbar-item',{attrs:{"name":"my"},scopedSlots:_vm._u([{key:"icon",fn:function(props){return [_c('img',{attrs:{"src":props.active
              ? require('../../assets/common/common-tabbar-my.png')
              : require('../../assets/common/common-tabbar-my-isActive.png')}})]}}])},[_vm._v("我的 ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// import Bridge from './plugins/dsbridge'
// Vue.prototype.$bridge = Bridge

// const dsBridge = require('dsbridge')
// Vue.prototype.$dsBridge = dsBridge

import VConsole from "vconsole";

if(process.env.NODE_ENV != 'production' ){
  const vConsole = new VConsole();
};

Vue.config.productionTip = false




// 封装请求
import axios from "axios";

import qs from 'qs';
//下面是将$axios和$qs挂在原型上，以便在实例中能用 this.$axios能够拿到
Vue.prototype.$axios = axios;
Vue.prototype.$qs = qs;
Vue.prototype.$http = axios


import 'vant/lib/index.css';

import {  PullRefresh,  Field, List, Tabbar, TabbarItem, Tab, Tabs, Popup, NoticeBar, Swipe, SwipeItem, Checkbox, CheckboxGroup, Step, Steps, Cell, CellGroup, CountDown, Toast, Loading, RadioGroup, Radio, Uploader, Icon,Button  } from 'vant';

Vue.use(Field);
Vue.use(List);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Popup);
Vue.use(NoticeBar);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Step);
Vue.use(Steps);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(CountDown);
Vue.use(Toast);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Uploader);
Vue.use(PullRefresh);
Vue.use(Loading);
Vue.use(Icon);
Vue.use(Button);





new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

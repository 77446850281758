import axios from 'axios'
import Qs from 'qs';
import { Toast } from 'vant';

// 环境的切换:开发，测试，生产
// if (process.env.NODE_ENV === 'development') {
//     // axios.defaults.baseURL = 'https://after.byqp99.com/jeecg-boot/'
//     axios.defaults.baseURL = 'https://backendweb.661222.com/jeecg-boot/'
// } else if (process.env.NODE_ENV === 'debug') {
//     axios.defaults.baseURL = 'https://after.byqp99.com/jeecg-boot/'
// } else if (process.env.NODE_ENV === 'production') {
//     // axios.defaults.baseURL = 'https://after.byqp99.com/jeecg-boot/'
//     axios.defaults.baseURL = 'https://backendweb.661222.com/jeecg-boot/'
// }
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
axios.defaults.timeout = 100000;
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';

// // 请求拦截器
axios.interceptors.request.use(config => {
    // 在发送请求之前做些什么 验证token之类的
    config.headers['X-Access-Token'] = localStorage.getItem('token')

    return config;
}, error => {
    // 对请求错误做些什么
    return Promise.error(error);
})

// 响应拦截
axios.interceptors.response.use(response => {
    // 对响应数据做点什么
    return response;
}, error => {
    // 对响应错误做点什么
    return Promise.reject(error);
});

// 封装get方法和post方法

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params
        }).then(res => {
            resolve(res.data);
        }).catch(err => {
            console.log('错误', err)
            Toast('登录失效')
            setTimeout(() => {
                localStorage.clear()
                location.reload();
            }, 1000)
        })
    });
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params) {
    return new Promise((resolve, reject) => {
        axios.post(url, params)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                Toast('登录失效')
                setTimeout(() => {
                    localStorage.clear()
                    location.reload();
                }, 1000)
            })
    });
}

/**
 * put方法，对应put请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function put(url, params) {
    return new Promise((resolve, reject) => {
        axios.put(url, params)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                Toast('登录失效')
                setTimeout(() => {
                    localStorage.clear()
                    location.reload();

                }, 1000)
            })
    });
}


/**
 * delete
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function deletefn(url, params) {
    return new Promise((resolve, reject) => {
        axios.delete(url, params)
            .then(res => {
                resolve(res.data);

            })
            .catch(err => {
                Toast('登录失效')
                setTimeout(() => {
                    localStorage.clear()
                    location.reload();

                }, 1000)
            })
    });
}

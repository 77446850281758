<template>
  <div class="information">
    <streamer :title="title"></streamer>
    <input v-show="false" ref="h5_upload" type="file" id="image" name="image" @change="handleUpload"/>
    <div
      class="information-list"
      v-for="(item, index) in 5"
      :key="index"
      @click="informationBtn(index)"
    >
      <div class="information-list-left">
        {{ index == 0 ? "头像" : index == 1 ? "用户ID" : index == 2 ? "上级ID" : index == 3 ? "昵称" : "关于我们" }}
      </div>
      <div class="information-list-right">
        <div class="right-block" v-if="index == 0">
          <div class="right-block-head" @click="uploadingMet">
            <img class="right-block-head-img" :src="userInfo.userIcon" alt="" />
          </div>
          <div class="right-block-img">
            <img src="../../assets/my/my-earnings-black-right.png" alt="" />
          </div>
        </div>
        <div class="right-block" v-if="index == 1">
          <div class="right-block-title">{{ userInfo.id }}</div>
          <div class="right-block-img"></div>
        </div>
        <div class="right-block" v-if="index == 2">
          <div class="right-block-title">
            {{ userInfo.inviteUserId == null ? "" : userInfo.inviteUserId }}
          </div>
          <div class="right-block-img" v-if="userInfo.inviteUserId != null">
            <img src="../../assets/my/my-earnings-black-right.png" alt="" />
          </div>
        </div>
        <div class="right-block" v-if="index == 3">
          <div class="right-block-title">{{ userInfo.username }}</div>
          <div class="right-block-img">
            <img src="../../assets/my/my-earnings-black-right.png" alt="" />
          </div>
        </div>
        <div class="right-block" v-if="index == 4">
          <div class="right-block-img">
            <img src="../../assets/my/my-earnings-black-right.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="information-button">
      <div class="information-button-title" @click="outMet">退出登录</div>
    </div>
  </div>
</template>

<script>
import streamer from "../../components/streamer/streamer.vue";
import { my, myIcon } from "../../api/my";
import { Toast } from "vant";
import { Upload } from '@/api/apiService'

export default {
  components: {
    streamer,
  },
  data() {
    return {
      title: "我的信息", // 标题
      headShow: false, // 更换头像弹出框
      //---个人信息存储------------
      userInfo: {},
      // 图片暂缓
      // imgs: "https://after.byqp99.com/jeecg-bootsys/common/static/",
      // imgs: "https://backendweb.661222.com/jeecg-boot/sys/common/static/",
      imgs: process.env.VUE_APP_IMAGE_URL,
      fileimg: "", //上传的文件列表
      h5_upload:null
    };
  },
  created() {
    // 获取用户个人信息
    this.myMet();
  },
  mounted() {
    window.transferConsultResult = this.transferConsultResult;
  },
  methods: {
    transferConsultResult(value) {
      console.log("接收到ios参数1", value);
      let result = JSON.parse(value);
      if (result.img != null) {
        if (result.img.substr(0, 3) != "htt") {
          this.fileimg = this.imgs + result.img;
        }
      }
      console.log("查询11111", this.fileimg);
      this.headMet(this.fileimg);
    },
    // 点击第一个头像开启弹出框
    informationBtn(index) {
      if (index == 0) {
        this.headShow = true;
      } else if (index == 3) {
        this.nicknameBtn();
      } else if (index == 4) {
        this.regardBtn();
      }
    },

    outMet() {
      localStorage.clear();
      location.href = '/login'
      // this.$router.replace({
      //   path: "/login",
      // });

      var u = navigator.userAgent,
        app = navigator.appVersion;
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android终端或者uc浏览器
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isAndroid) {
        console.log("安卓模块");
        window.androidShangJIn.outMet("1");
      } else if (isiOS) {
        console.log("ios模块");
        window.webkit.messageHandlers.outMet.postMessage("1");
      }
    },
    // 点击取消
    cancelBtn() {
      this.headShow = false;
    },

    // 获取个人信息接口数据
    myMet() {
      my().then((res) => {
        if (res.code == 200) {
          if (res.result.userIcon != null) {
            if (res.result.userIcon.substr(0, 3) != "htt") {
              res.result.userIcon = this.imgs + res.result.userIcon;
              return;
            }
          }
          this.userInfo = res.result;
        }
      });
    },

    // 点击昵称修改
    nicknameBtn() {
      this.$router.push({
        path: "/my-modification",
        query: {
          name: this.userInfo.username,
        },
      });
    },
    // 点击关于我们
    regardBtn() {
      this.$router.push({
        path: "/my-regard",
      });
    },
    // 用户修改头像成功
    headMet(fileimg) {
      console.log("修改头像成功", fileimg);
      let params = {
        userIcon: fileimg,
      };
    console.log(params);
      myIcon(params).then((res) => {
        console.log('update',res);
        if (res.code == 200) {
          Toast(res.message);
          this.myMet();
        }
      });
    },

    // 用户点击上传
    uploadingMet() {
      var u = navigator.userAgent,
        app = navigator.appVersion;
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android终端或者uc浏览器
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isAndroid) {
        try{
          window.androidShangJIn.uploadingImgid("1");
          console.log("安卓模块");
        }catch(err){
          this.$refs.h5_upload.click();
          console.log("H5");
        }
      } else if (isiOS) {
        try {
          window.webkit.messageHandlers.uploadingImgId.postMessage("1");
          console.log("ios模块");
        } catch (error) {
          this.$refs.h5_upload.click();
          console.log("H5");
        }
      }
    },
    handleUpload(){
      var fileInput = document.getElementById("image");
      var file = fileInput.files[0];
      var formData = new FormData();
      formData.append("file", file);
      formData.append("biz",'temp')
      Upload(formData).then(res=>{
          this.transferConsultResult(JSON.stringify({img:res.data.result}));
      })
    }
  },
};
</script>

<style lang="less" scoped>
.information {
  height: 100vh;
  background: #ffffff;
  .information-list {
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e9e9e9;
    padding: 0 41.5px;
    box-sizing: border-box;

    .information-list-left {
      width: 100px;
      text-align: left;
      font-size: 15px;
      font-family: Hiragino Sans GB;
      font-weight: normal;
      color: #000000;
    }

    .information-list-right {
      .right-block {
        display: flex;
        align-items: center;
        .right-block-head {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          position: relative;

          .right-block-head-img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
          }

          .right-block-head-file {
            position: absolute;
            width: 40px;
            height: 40px;
            left: 0;
            opacity: 0;
          }

          // img {
          //   width: 100%;
          //   height: 100%;
          // }
        }

        .right-block-img {
          width: 8px;
          margin-left: 4px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .right-block-title {
          font-size: 15px;
          font-family: Hiragino Sans GB;
          font-weight: normal;
          color: #696767;
        }
      }
    }
  }

  .information-list:last-child {
    border-bottom: 0;
  }
  .information-button {
    width: 100%;
    position: fixed;
    bottom: 70px;
    display: flex;
    justify-content: center;
    .information-button-title {
      font-size: 18.5px;
      font-family: Hiragino Sans GB;
      font-weight: normal;
      color: #ffffff;

      width: 290px;
      height: 42px;
      background: linear-gradient(0deg, #ed4034 0%, #ed3325 51%, #da261a 100%);
      border-radius: 5px;

      text-align: center;
      line-height: 42px;
    }
  }
}
</style>
<template>
  <div class="card">
    <div class="card-stremer">
      <streamer :title="title"></streamer>
    </div>

    <div class="card-lint"></div>

    <div class="card-block">
      <div class="card-block-title">
        <div class="title">我的银行卡</div>
        <div class="display" @click="bindingBtn">
          <img
            class="display-img"
            src="../../assets/my/my-brank-new.png"
            alt=""
          />
          <div class="display-title">新增银行卡</div>
        </div>
      </div>
      <div
        class="card-block-list"
        v-for="(info, num) in bankList"
        :key="num"
        @click="cardBtn(info)"
      >
        <div class="list-back">
          <img src="../../assets/my/my-Industry-Commerce-back.png" alt="" />
        </div>
        <div class="list-block" style="postion: reactive">
          <div class="list-block-top">
            <div class="top-left">
              <div class="top-left-img"></div>
              <div class="top-left-div">
                <div class="top-left-div-title">{{ info.bank }}</div>
                <div class="top-left-div-sign">储蓄卡</div>
              </div>
            </div>
            <div
              class="top-right"
              v-if="info.status == 1"
              @click.stop="defaultShowMet(info)"
            >
              <div class="top-right-title">
                {{ info.isDefault == 1 ? "默认" : "设置为默认提现方式" }}
              </div>
              <div class="top-right-img" v-if="num == 1">
                <img src="../../assets/common/common-whilt-right.png" alt="" />
              </div>
            </div>
            <div class="top-status" v-else>
              {{ info.status == 0 ? "待审核" : info.status == 2 ? "审核不通过" : "" }}
            </div>
          </div>
          <div class="list-block-center">
            <div class="center-title">{{ info.bankNo }}</div>
          </div>
          <span
            style="position: absolute; top: 5px; left: 5px; z-index: 8"
            @click.stop="handleDelete(info.id)"
          >
            <van-icon name="delete-o" size="20" color="#ffff" />
          </span>
        </div>
      </div>
    </div>

    <div class="card-zfb">
      <!-- <div class="card-zfb-title">
        <div class="title-left">
          <div class="title-left-img">
            <img src="../../assets/my/my-brank-zfb.png" alt="" />
          </div>
          <div class="title-left-title">提现至支付宝</div>
        </div>
        <div class="title-right">
          <img src="../../assets/my/my-brank-check.png" alt="" />
        </div>
      </div> -->

      <div
        class="card-block-list"
        v-for="(info, num) in ailList"
        :key="num"
        @click="cardBtn(info)"
      >
        <div class="list-back">
          <img src="../../assets/my/my-Industry-Commerce-back.png" alt="" />
        </div>
        <div class="list-block">
          <div class="list-block-top">
            <div class="top-left">
              <div class="top-left-img"></div>
              <div class="top-left-div">
                <div class="top-left-div-title">{{ info.alipayname }}</div>
                <div class="top-left-div-sign">支付宝</div>
              </div>
            </div>
            <div class="top-right" v-if="info.status == 1">
              <div class="top-right-title">
                {{ info.isDefault == 1 ? "默认" : "设置为默认提现方式" }}
              </div>
              <div class="top-right-img" v-if="num == 1">
                <img src="../../assets/common/common-whilt-right.png" alt="" />
              </div>
            </div>

            <div class="top-status" v-else>
              {{
                info.status == 0
                  ? "待审核"
                  : info.status == 2
                  ? "审核不通过"
                  : ""
              }}
            </div>
          </div>

          <div class="list-block-center">
            <div class="center-title">{{ info.alipayno }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="card-block" v-if="bankMessageObj.dvalue == 1">
      <div class="card-block-title">我的USDT</div>
      <div
        class="card-block-list"
        v-for="(info, num) in ustdList"
        :key="num"
        @click="cardBtn(info)"
      >
        <div class="list-back">
          <img src="../../assets/my/my-Industry-Commerce-back.png" alt="" />
        </div>
        <div class="list-block">
          <div class="list-block-top">
            <div class="top-left">
              <div class="top-left-img"></div>
              <div class="top-left-div">
                <div class="top-left-div-title">{{ info.bank }}</div>
                <div class="top-left-div-sign">储蓄卡</div>
              </div>
            </div>
            <div class="top-right" v-if="info.status == 1">
              <div class="top-right-title">
                {{ info.isDefault == 1 ? "默认" : "设置为默认提现方式" }}
              </div>
              <div class="top-right-img" v-if="num == 1">
                <img src="../../assets/common/common-whilt-right.png" alt="" />
              </div>
            </div>

            <div class="top-status" v-else>
              {{
                info.status == 0
                  ? "待审核"
                  : info.status == 2
                  ? "审核不通过"
                  : ""
              }}
            </div>
          </div>

          <div class="list-block-center">
            <div class="center-title">{{ info.bankNo }}</div>
          </div>
        </div>
      </div>
    </div>

    <!--默认-->
    <div class="default">
      <van-popup v-model="defaultShow">
        <div class="default-div">
          <div class="default-div-img">
            <img src="../../assets/my/my-withdrawPopup.png" alt="" />
          </div>
          <div class="default-div-close" @click="closeBtn">
            <img src="../../assets/common/common-close.png" alt="" />
          </div>

          <div class="default-div-block">
            <div class="div-block-title">
              确定将尾号{{ defaultNum }}的账号设置为默认提现方式
            </div>
            <div class="div-block-button">
              <div
                class="button-list"
                v-for="(item, index) in 2"
                :key="index"
                @click="defaultMet(index)"
              >
                {{ index == 0 ? "确定" : "取消" }}
              </div>
            </div>
          </div>
        </div>
      </van-popup>
    </div>

    <!-- 点击提现事件 -->
    <div class="withdraw">
      <van-popup v-model="withdrawShow">
        <div class="withdraw-div">
          <div class="withdraw-div-img">
            <img src="../../assets/my/my-withdrawPopup-2.png" alt="" />
          </div>
          <div class="withdraw-div-close" @click="closeBtn">
            <img src="../../assets/common/common-close.png" alt="" />
          </div>

          <div class="withdraw-div-block">
            <div class="div-block-title">
              确定发起提现申请到尾号{{ defaultNum }}的银行卡？
            </div>

            <!-- <div class="div-block-input">
              <van-field
                v-model="priceValue"
                type="digit"
                label="提现金额"
                placeholder="请输入提现金额"
              />
            </div> -->
            <div class="btn-group">
              <van-button
                type="danger"
                size="small"
                v-for="item in coinsBtn.amount"
                :key="item"
                :plain="coinsBtn.selectBtn !== item"
                @click="handleInputAmount(item)"
              >
                {{ item }}
              </van-button>
            </div>
            <div class="div-block-input">
              <van-field
                v-model="priceValue"
                type="digit"
                label="提现金额"
                readonly
              />
            </div>
            <div class="div-block-button">
              <div
                class="button-list"
                v-for="(item, index) in 2"
                :key="index"
                @click="withdrawMet(index)"
              >
                {{ index == 0 ? "确定" : "更换提现卡" }}
              </div>
            </div>
          </div>
        </div>
      </van-popup>
    </div>

    <!-- 有提现事件 -->
    <div class="deposit">
      <van-popup v-model="depositShow">
        <div class="deposit-block">
          <div class="deposit-block-img">
            <img src="../../assets/my/my-withdrawPopup-deposit.png" alt="" />
          </div>
          <div class="deposit-block-div">
            <div
              class="block-div-list"
              v-for="(item, index) in bankType != 2 ? 6 : 3"
              :key="index"
            >
              <div class="block-div-list-left" v-if="bankType != 2">
                {{
                  index == 0
                    ? "账号类型"
                    : index == 1
                    ? "银行名称"
                    : index == 2
                    ? "分行名称"
                    : index == 3
                    ? "持卡人姓名"
                    : index == 4
                    ? "银行账号"
                    : bankMessageObj.dvalue == 1
                    ? "ustd"
                    : ""
                }}
              </div>

              <div class="block-div-list-left" v-if="bankType == 2">
                {{
                  index == 0
                    ? "账号类型"
                    : index == 1
                    ? "支付宝账户"
                    : "支付宝姓名"
                }}
              </div>

              <div class="block-div-list-right">
                <div class="right-input" v-if="index == 0">
                  <van-radio-group v-model="bankType" direction="horizontal">
                    <van-radio name="0">银行卡</van-radio>
                    <van-radio name="1" v-if="bankMessageObj.dvalue == 1"
                      >USDT</van-radio
                    >
                    <!-- <van-radio name="2">支付宝</van-radio> -->
                  </van-radio-group>
                </div>
                <div class="right-input" v-if="index == 1 && bankType != 2">
                  <van-field v-model="bank" placeholder="请输入银行名称" />
                </div>
                <div class="right-input" v-if="index == 2 && bankType != 2">
                  <van-field
                    v-model="bankDetail"
                    placeholder="请输入分行名称"
                  />
                </div>
                <div class="right-input" v-if="index == 3 && bankType != 2">
                  <van-field
                    v-model="bankUser"
                    placeholder="请输入持卡人姓名"
                  />
                </div>
                <div class="right-input" v-if="index == 4 && bankType != 2">
                  <van-field v-model="bankNo" placeholder="请输入银行账户" />
                </div>
                <div
                  class="right-input"
                  v-if="
                    index == 5 && bankMessageObj.dvalue == 1 && bankType != 2
                  "
                >
                  <van-field
                    v-model="usdtAddress"
                    placeholder="请输入usdt收款地址"
                  />
                </div>

                <div class="right-input" v-if="index == 1 && bankType == 2">
                  <van-field
                    v-model="alipayno"
                    placeholder="请输入支付宝账户"
                  />
                </div>

                <div class="right-input" v-if="index == 2 && bankType == 2">
                  <van-field
                    v-model="alipayname"
                    placeholder="请输入支付宝姓名"
                  />
                </div>
              </div>
            </div>

            <div class="block-div-button1">
              <div class="block-div-button" @click="submitMet">提交</div>
            </div>
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import streamer from "../../components/streamer/streamer.vue";

import {
  mybank,
  mywithdrawConfirm,
  mydefaultConfirm,
  myNewbank,
  mybankMessage,
} from "../../api/my";
import { Toast } from "vant";
import { deleteBankAccount } from "@/api/apiService";
export default {
  components: {
    streamer,
  },
  data() {
    return {
      title: "我的银行卡", // 标题
      withdrawShow: false, // 点击提现弹出框
      defaultShow: false, // 点击默认
      defaultNum: "", // 数字尾号
      priceValue: 100, // 输入提现金额

      // ----银行卡列表----------
      bankList: [], // 银行卡列表
      ustdList: [], // ustd列表
      bankObj: {}, // 点击提现对象
      // ---银行卡绑定------
      depositShow: false, // 提现弹出框
      bankType: "0", // 银行卡类型 0银行卡 1USDT 2支付宝
      bank: "", // 银行名称
      bankDetail: "", // 分行名称
      bankUser: "", //	持卡人姓名
      bankNo: "", // 银行账户
      usdtAddress: "", // usdt收款地址
      isDefault: "", // 是否默认提现方式 1是
      coinsBtn: {
        selectBtn: 100,
        amount: [100, 200, 500, 1000],
      },
      //----------------------------
      bankMessageObj: {}, // 银行卡配置信息
      // 支付宝
      alipayno: "",
      alipayname: "",
    };
  },
  created() {
    this.bankMet();
    // 获取银行卡配置信息
    this.mybankMessageMet();
  },
  methods: {
    handleDelete(_id) {
      deleteBankAccount({ id: _id }).then((res) => {
        if (res.result === 1) {
          Toast.success("成功删除");
          const index = this.bankList.findIndex((item) => item.id == _id);
          this.bankList.splice(index, 1);
        } else {
          return Toast.error("删除失败");
        }
      });
    },
    handleInputAmount(_val) {
      this.priceValue = _val;
      this.coinsBtn.selectBtn = _val;
    },
    // 获取银行卡信息
    bankMet() {
      let params = {};
      mybank(params).then((res) => {
        if (res.code == 200) {
          this.bankList = res.result.bankList;
          this.ustdList = res.result.ustdList;
          this.ailList = res.result.ailList;
        } else {
          Toast(res.message);
        }
      });
    },
    // 获取银行卡配置信息
    mybankMessageMet() {
      let params = {};
      mybankMessage(params).then((res) => {
        console.log("获取银行卡配置信息", res);
        if (res.code == 200) {
          this.bankMessageObj = res.result[0];
        }
      });
    },
    // 当前银行卡设置默认弹出框
    defaultShowMet(item) {
      console.log("开启默认弹出框", item);
      this.defaultNum = item.bankNo.substr(-4);
      this.bankObj = item;
      this.defaultShow = true;
    },
    // 当前银行卡设置默认确定  和 关闭
    defaultMet(index) {
      if (index == 0) {
        this.defaultConfirm();
      } else {
        this.closeBtn();
      }
    },
    // 设置默认
    defaultConfirm() {
      let params = {
        id: this.bankObj.id,
      };

      mydefaultConfirm(params).then((res) => {
        if (res.code == 200) {
          Toast.success(res.message);
          this.bankMet();
        }

        this.closeBtn();
      });
    },
    // 点击开启提现弹出框
    cardBtn(item) {
      if (item.status != 1) {
        return;
      }

      this.withdrawShow = true;
      this.bankObj = item;
      this.defaultNum = item.bankNo.substr(-4);
      console.log("点击提现", item);
    },

    // 提现弹出框  确认 和 取消
    withdrawMet(index) {
      if (index == 0) {
        this.withdrawConfirm();
      } else {
        this.closeBtn();
      }
    },
    // 确认提现操作
    withdrawConfirm() {
      let params = {
        id: this.bankObj.id, // 银行卡id
        amount: this.priceValue, // 金额
      };

      mywithdrawConfirm(params).then((res) => {
        if (res.code == 200) {
          Toast.success(res.message);
          this.closeBtn();
          this.bankMet();
        } else {
          Toast(res.message);
        }
        this.closeBtn();
      });
    },
    // 点击 关闭
    closeBtn() {
      this.withdrawShow = false;
      this.defaultShow = false;
      this.priceValue = "";
    },
    // 点击去绑定
    bindingBtn() {
      this.depositShow = true;
    },
    submitMet() {
      if (this.bankType != 2) {
        if (this.bank == "") {
          Toast("请输入银行名称 ~");
          return;
        }
        if (this.bankDetail == "") {
          Toast("请输入分行名称 ~");
          return;
        }
        if (this.bankUser == "") {
          Toast("请输入持卡人姓名 ~");
          return;
        }
        if (this.bankNo == "") {
          Toast("请输入银行账户 ~");
          return;
        }
        if (this.bankType == 1 && this.usdtAddress == "") {
          Toast("请输入usdt收款地址 ~");
          return;
        }
      }

      if (this.bankType == 2) {
        if (this.alipayno == "") {
          Toast("请输入支付宝账户 ~");
          return;
        }
        if (this.alipayname == "") {
          Toast("请输入支付宝名称 ~");
          return;
        }
      }

      let params = {
        bankType: this.bankType, // 银行卡类型 0银行卡 1USDT
        bank: this.bank, // 银行名称
        bankDetail: this.bankDetail, // 分行名称
        bankUser: this.bankUser, //	持卡人姓名
        bankNo: this.bankNo, // 银行账户
        usdtAddress: this.usdtAddress, // usdt收款地址
        isDefault: "0", // 是否默认提现方式 1是
        alipayno: this.bankType == 2 ? this.alipayno : "", // 支付宝账户
        alipayname: this.bankType == 2 ? this.alipayname : "", // 支付宝蒙层
      };

      myNewbank(params).then((res) => {
        if (res.code == 200) {
          Toast.success(res.message);
          this.bankMet();
        } else {
          Toast(res.message);
        }
        this.depositShow = false;
      });

      console.log("提交参数", params);
    },
  },
};
</script>

<style lang="less" scoped>
.btn-group {
  display: flex;
  justify-content: space-around;
  padding-top: 10px;
  .van-button {
    flex: 0 1 22%;
  }
}
.card {
  padding-bottom: 20px;
  .card-stremer {
    width: 100%;
    height: 90.5px;
    position: fixed;
    z-index: 9;
    top: 0;
  }

  .card-lint {
    height: 90.5px;
  }

  .card-block {
    margin: 11px 12px;
    padding: 0 17px 29.5px;
    box-sizing: border-box;
    background: #fff;
    border-radius: 5px;
    .card-block-title {
      height: 58.5px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        font-size: 16px;
        font-family: Hiragino Sans GB;
        font-weight: bold;
        color: #000000;
      }
      .display {
        height: 24px;
        width: 86.5px;
        background: #ffffff;
        border: 1px solid #e74c45;
        border-radius: 5px;

        display: flex;
        justify-content: center;
        align-items: center;
        .display-img {
          width: 11px;
          height: 11px;
          margin-right: 3px;
        }
        .display-title {
          font-size: 11.5px;
          font-family: Hiragino Sans GB;
          font-weight: normal;
          color: #df271b;
        }
      }
    }
    .card-block-list {
      position: relative;
      width: 100%;
      height: 123px;
      margin-bottom: 15px;
      .list-back {
        position: absolute;
        display: flex;
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .list-block {
        position: relative;
        padding: 23px;
        box-sizing: border-box;
        .list-block-top {
          display: flex;
          justify-content: space-between;
          .top-left {
            display: flex;
            .top-left-img {
              width: 21px;
              height: 21px;

              img {
                width: 100%;
                height: 100%;
              }
            }
            .top-left-div {
              margin-left: 8.5px;
              .top-left-div-title {
                font-size: 14px;
                font-family: Hiragino Sans GB;
                font-weight: bold;
                color: #ffffff;
              }
              .top-left-div-sign {
                margin-top: 3.5px;
                font-size: 11.5px;
                font-family: Hiragino Sans GB;
                font-weight: bold;
                color: #ffffff;
              }
            }
          }

          .top-right {
            display: flex;
            align-items: baseline;
            height: 20px;
            .top-right-title {
              font-size: 11.5px;
              font-family: Hiragino Sans GB;
              font-weight: bold;
              color: #ffffff;
            }

            .top-right-img {
              width: 6px;
              height: 10px;
              margin-left: 3px;

              img {
                width: 100%;
                height: 10px;
              }
            }
          }

          .top-status {
            width: 62px;
            height: 24px;
            background: #ffa912;
            border-radius: 5px;
            text-align: center;
            line-height: 24px;
            font-size: 11.5px;
            font-family: Hiragino Sans GB;
            font-weight: normal;
            color: #ffffff;
          }
        }

        .list-block-center {
          padding-left: 28.5px;
          box-sizing: border-box;
          margin-top: 20.5px;
          .center-title {
            font-size: 15.5px;
            font-family: Hiragino Sans GB;
            font-weight: bold;
            color: #ffffff;
            letter-spacing: 1px;
          }
        }
      }
    }
  }

  .card-zfb {
    margin: 0 12.5px;
    padding: 0 20px;
    box-sizing: border-box;
    background: #ffffff;

    .card-zfb-title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      height: 69px;
      border-radius: 5px;
      .title-left {
        display: flex;
        align-items: center;
        .title-left-img {
          width: 29.5px;
          height: 29.5px;

          img {
            width: 100%;
            height: 100%;
          }
        }
        .title-left-title {
          font-size: 14px;
          font-family: Hiragino Sans GB;
          font-weight: normal;
          color: #000000;
          margin-left: 9.5px;
        }
      }
      .title-right {
        width: 12px;
        height: 12px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .card-block-list {
      position: relative;
      width: 100%;
      height: 123px;
      margin-bottom: 15px;
      .list-back {
        position: absolute;
        display: flex;
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .list-block {
        position: relative;
        padding: 23px;
        box-sizing: border-box;
        .list-block-top {
          display: flex;
          justify-content: space-between;
          .top-left {
            display: flex;
            .top-left-img {
              width: 21px;
              height: 21px;

              img {
                width: 100%;
                height: 100%;
              }
            }
            .top-left-div {
              margin-left: 8.5px;
              .top-left-div-title {
                font-size: 14px;
                font-family: Hiragino Sans GB;
                font-weight: bold;
                color: #ffffff;
              }
              .top-left-div-sign {
                margin-top: 3.5px;
                font-size: 11.5px;
                font-family: Hiragino Sans GB;
                font-weight: bold;
                color: #ffffff;
              }
            }
          }

          .top-right {
            display: flex;
            align-items: baseline;
            height: 20px;
            .top-right-title {
              font-size: 11.5px;
              font-family: Hiragino Sans GB;
              font-weight: bold;
              color: #ffffff;
            }

            .top-right-img {
              width: 6px;
              height: 10px;
              margin-left: 3px;

              img {
                width: 100%;
                height: 10px;
              }
            }
          }

          .top-status {
            width: 62px;
            height: 24px;
            background: #ffa912;
            border-radius: 5px;
            text-align: center;
            line-height: 24px;
            font-size: 11.5px;
            font-family: Hiragino Sans GB;
            font-weight: normal;
            color: #ffffff;
          }
        }

        .list-block-center {
          padding-left: 28.5px;
          box-sizing: border-box;
          margin-top: 20.5px;
          .center-title {
            font-size: 15.5px;
            font-family: Hiragino Sans GB;
            font-weight: bold;
            color: #ffffff;
            letter-spacing: 1px;
          }
        }
      }
    }
  }

  // 未绑定弹出框
  .withdraw {
    /deep/ .van-popup {
      border-radius: 10px !important;
    }

    /deep/.van-popup {
      background-color: rgba(255, 255, 255, 0) !important;
    }

    .withdraw-div {
      width: 285px;
      // height: 364.5px;
      height: 430.5px;
      position: relative;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      .withdraw-div-img {
        width: 100%;
        // height: 364.5px;
        height: 100%;
        position: absolute;
        display: flex;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .withdraw-div-close {
        width: 12px;
        height: 12px;
        position: absolute;
        right: 20.5px;
        top: 58px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .withdraw-div-block {
        position: relative;
        // padding-top: 204px;
        padding: 0 30px;
        box-sizing: border-box;
        margin-bottom: 20px;
        .div-block-title {
          font-size: 17px;
          font-family: Hiragino Sans GB;
          font-weight: bold;
          color: #000000;
          text-align: center;
        }

        .div-block-input {
          margin: 15px 0;
          border: 1px solid #e9e9e9;
        }
        .div-block-button {
          display: flex;
          justify-content: center;
          .button-list {
            width: 96px;
            height: 37.5px;
            background: linear-gradient(
              0deg,
              #ffff00 0%,
              #ef876c 0%,
              #f25344 51%,
              #e42a1e 100%
            );
            border-radius: 5px;
            margin-right: 16px;
            text-align: center;
            line-height: 37.5px;

            font-size: 16px;
            font-family: Hiragino Sans GB;
            font-weight: normal;
            color: #ffffff;
          }

          .button-list:last-child {
            margin-right: 0;
            background: #ffffff;
            border: 2px solid #e52c20;
            color: #ed4034;
          }
        }
      }
    }
  }

  // 点击默认
  .default {
    /deep/ .van-popup {
      border-radius: 10px !important;
    }

    /deep/.van-popup {
      background-color: rgba(255, 255, 255, 0) !important;
    }

    .default-div {
      width: 285px;
      height: 364.5px;
      position: relative;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      .default-div-img {
        width: 100%;
        height: 364.5px;
        position: absolute;
        display: flex;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .default-div-close {
        width: 12px;
        height: 12px;
        position: absolute;
        right: 20.5px;
        top: 58px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .default-div-block {
        position: relative;
        // padding-top: 204px;
        padding: 0 30px;
        box-sizing: border-box;
        margin-bottom: 40px;
        .div-block-title {
          font-size: 17px;
          font-family: Hiragino Sans GB;
          font-weight: bold;
          color: #000000;
          text-align: center;
        }

        .div-block-button {
          display: flex;
          justify-content: center;
          margin-top: 41px;

          .button-list {
            width: 96px;
            height: 37.5px;

            background: linear-gradient(
              0deg,
              #ffff00 0%,
              #ef876c 0%,
              #f25344 51%,
              #e42a1e 100%
            );
            border-radius: 5px;
            margin-right: 16px;
            display: flex;
            justify-content: center;
            align-items: center;

            font-size: 16px;
            font-family: Hiragino Sans GB;
            font-weight: normal;
            color: #ffffff;
          }

          .button-list:last-child {
            margin-right: 0;
            background: #ffffff;
            border: 1px solid #e52c20;
            color: #ed4034;
          }
        }
      }
    }
  }

  // 已绑定弹出框
  .deposit {
    /deep/ .van-popup {
      border-radius: 10px !important;
    }

    /deep/.van-popup {
      background-color: rgba(255, 255, 255, 0) !important;
    }

    /deep/ .van-field__control {
      padding-left: 10px;
    }

    .deposit-block {
      width: 319px;
      height: 434.5px;
      position: relative;
      .deposit-block-img {
        height: 142.5px;
        width: 100%;
        position: absolute;
        display: flex;
        z-index: 1;
        img {
          width: 100%;
          height: 100%;
        }
      }

      .deposit-block-div {
        background: #ffffff;
        height: 359px;
        width: 100%;
        position: relative;
        top: 75px;
        padding-top: 90px;
        box-sizing: border-box;

        .block-div-list {
          width: 100%;
          height: 23px;
          margin-bottom: 10px;
          padding: 0 20px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .block-div-list-left {
            font-size: 15px;
            font-family: Hiragino Sans GB;
            font-weight: normal;
            color: #000000;
            text-align: left;
            width: 100px;
          }
          .block-div-list-right {
            .right-input {
              width: 181px;
              display: flex;
              align-items: center;
              /deep/ .van-cell {
                padding: 0;
                background-color: #efeeee;
              }

              /deep/ .van-radio__icon .van-icon {
                width: 16px;
                height: 16px;
                line-height: 16px;
                margin-right: 4px;
              }

              /deep/ .van-radio__icon.van-icon {
                width: 16px;
                height: 16px;
                line-height: 16px;
                margin-right: 4px;
              }
              /deep/ .van-radio__icon {
                height: 16px !important;
                font-size: 13px;
              }

              /deep/ .van-icon-success:before {
                margin-left: 1px;
              }
            }
          }
        }

        .block-div-list:last-child {
          margin-bottom: 0;
        }

        .block-div-button1 {
          display: flex;
          align-items: center;
          justify-content: center;
          .block-div-button {
            width: 133.5px;
            height: 37.5px;
            background: linear-gradient(
              0deg,
              #ffff00 0%,
              #ef876c 0%,
              #f25344 51%,
              #e42a1e 100%
            );
            border-radius: 5px;
            margin: 0 auto;

            font-size: 16px;
            font-family: Hiragino Sans GB;
            font-weight: normal;
            color: #ffffff;
            text-align: center;
            line-height: 37.5px;
            margin-top: 19px;
            position: absolute;
            bottom: 20px;
          }
        }
      }
    }
  }
}
</style>
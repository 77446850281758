// 登录界面接口
import { get, post } from "../api/request";

// 登录界面接口 登录
export const loginPhone = params => post(`v1/front/loginPhone`, params)

// // 登录界面接口 注册
export const loginRegister = params => post(`v1/front/loginRegister`, params)

// 登录界面接口 验证码
export const loginVerification = params => post(`v1/front/loginPhone`, params)

// 用户上传
export const loginDownload = params => post(`sys/common/upload`, params)

// 协议
export const loginAgreement = params => get(`reward/rewardAgreementManagement/queryByType`, params)



<template>
  <div class="history">
    <div class="history-streamer">
      <streamer :title="title"></streamer>
    </div>

    <div class="history-streamer-lint"></div>

    <div class="history-scrolling">
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="list.length == 0 ? '' : ''"
        @load="onLoad"
      >
        <div class="history-scrolling-div">
          <div class="div-left">
            <div class="div-left-title">排名</div>
            <div class="div-left-nickname">会员头像/昵称</div>
          </div>
          <div class="div-right">
            <div class="div-right-title">总奖励</div>
          </div>
        </div>

        <div
          class="history-scrolling-list"
          v-for="(item, index) in list"
          :key="index"
        >
          <div class="list-left">
            <div class="list-left-title">{{ index + 1 }}</div>
            <div class="list-left-center">
              <div class="list-left-center-img">
                <img :src="item.userIcon" alt="" />
              </div>
              <div class="list-left-center-text">{{ item.username }}</div>
            </div>
          </div>

          <div class="list-price">{{ tabActive == 0? item.withdrawalPrice: item.taskPrice  }}元</div>
        </div>

        <empty :list="list"></empty>
      </van-list>
    </div>
  </div>
</template>

<script>
import streamer from "../../components/streamer/streamer.vue";

import { rankinglastWeek } from "../../api/ranking";

import empty from "@/components/empty/empty.vue";

export default {
  components: {
    streamer,
    empty,
  },
  data() {
    return {
      title: "历史排行（上周）", // 标题
      // 首页列表数组
      page: 1, // 当前页码
      limit: 10, // 当前页数
      list: [], // 当前数组列表
      loading: false, // 数组列表加载圈
      finished: false, // 是否暂时没有更多了
      refreshing: false, // 是否进行滚动加载
      // --------界面切换-----
      tabActive: 0, // 下标
      rankingObj: {},
      // 图片暂缓
      // imgs: "https://after.byqp99.com/jeecg-bootsys/common/static/",
      // imgs: "https://backendweb.661222.com/jeecg-boot/sys/common/static/",
      imgs: process.env.VUE_APP_IMAGE_URL,
    };
  },
  created() {
    this.tabActive = this.$route.query.index;
  },
  methods: {
    // 返回上一层
    backRight() {
      this.$router.go(-1);
    },
    // 获取当前列表中对应的数组数据  上周排名
    onLoad() {
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      if (this.refreshing) {
        this.list = [];
        this.refreshing = false;
      }

      let params = {
        rankingType: this.tabActive, // 排行榜类型 0提现榜 1赏金榜
        isBenWeek: "0", // 是否本周 0上周 1本周
        pageSize: this.limit, // 	页码
        pageNo: this.page, //页数
      };

      rankinglastWeek(params).then((res) => {
        if (res.code == 200) {
          this.rankingObj = res.result;

          res.result.rankingList.forEach((item) => {
            if (item.userIcon != null) {
              if (item.userIcon.substr(0, 3) != "htt") {
                item.userIcon = this.imgs + item.userIcon;
              }
            item.username = item.username.replace(/(\d{3})\d{4}/, '$1****');
            }
          });

          this.list.push(...res.result.rankingList);
          // 加载状态结束
          this.loading = false;

          // 数据全部加载完成
          if (this.list.length >= res.result.totalNum) {
            this.finished = true; // 到了滚动极限则返回true
          } else {
            this.page++;
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.history {
  .history-streamer {
    width: 100%;
    height: 90.5px;
    position: fixed;
    z-index: 9;
    top: 0;
  }
  .history-streamer-lint {
    height: 90.5px;
  }

  .history-scrolling {
    width: 349.5px;
    margin: 0 auto;
    margin-top: 7px;
    .history-scrolling-div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 40px;
      background: #ffffff;
      padding: 0 24px;
      box-sizing: border-box;
      .div-left {
        display: flex;
        .div-left-title {
          width: 40px;
          font-size: 14px;
          font-family: Hiragino Sans GB;
          font-weight: normal;
          color: #e74c45;
          text-align: center;
        }
        .div-left-nickname {
          margin-left: 32px;
          font-size: 14px;
          font-family: Hiragino Sans GB;
          font-weight: normal;
          color: #e74c45;
        }
      }
      .div-right {
        .div-right-title {
          font-size: 14px;
          font-family: Hiragino Sans GB;
          font-weight: normal;
          color: #e74c45;
        }
      }
    }
    .history-scrolling-list {
      position: relative;
      height: 40px;
      padding: 10px 24px;
      box-sizing: border-box;
      background: #ffffff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .list-left {
        display: flex;
        align-items: center;
        .list-left-title {
          width: 40px;
          text-align: center;
          font-size: 12.5px;
          font-family: Hiragino Sans GB;
          font-weight: bold;
          color: #e74c45;
        }
        .list-left-center {
          display: flex;
          align-items: center;
          margin-left: 20px;
          .list-left-center-img {
            width: 24.5px;
            height: 24.5px;
            background: linear-gradient(
              0deg,
              #ef876c 0%,
              #fee0c7 0%,
              #fdd0bc 51%,
              #fcc4b5 100%
            );
            border-radius: 50%;

            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }
          .list-left-center-text {
            font-size: 12.5px;
            font-family: Hiragino Sans GB;
            font-weight: normal;
            color: #141414;
            margin-left: 5px;
          }
        }
      }

      .list-price {
        font-size: 13px;
        font-family: Hiragino Sans GB;
        font-weight: normal;
        color: #141414;
      }
    }

    .history-scrolling-list:last-child {
      border-bottom: 0;
    }
  }
}
</style>
<template>
  <div class="invitation">
    <div class="invitation-hierarchy">
      <div class="invitation-hierarchy-left" @click="backRight">
        <img src="../../assets/common/common-block-left.png" alt="" />
      </div>
      <div class="invitation-hierarchy-title">无限级代理 收益无限叠加</div>

      <div class="invitation-hierarchy-block">
        <div class="block-right" @click="threadBtn">
          <div class="block-right-title">我的上线</div>
          <div class="block-right-img">
            <img src="../../assets/common/common-whilt-right.png" alt="" />
          </div>
        </div>
      </div>

      <div class="invitation-hierarchy-module">
        <div class="module-list" v-for="(item, index) in 3" :key="index">
          <div class="module-list-title">
            {{
              index == 0
                ? "一级下线， 提成5%"
                : index == 1
                ? "二级下线， 提成3% "
                : "三级下线， 提成1% "
            }}
          </div>
          <div
            class="module-list-img"
            :style="{
              height: index == 0 ? '216px' : index == 1 ? '166px' : '118.5px',
            }"
          >
            <img
              :src="
                index == 0
                  ? require('../../assets/fission/fission-invitation-icon-1.png')
                  : index == 1
                  ? require('../../assets/fission/fission-invitation-icon-2.png')
                  : require('../../assets/fission/fission-invitation-icon-3.png')
              "
              alt=""
            />
          </div>
        </div>
      </div>

      <div class="invitation-hierarchy-text">
        第4级代理-第n级代理，提成=上级代理提成
        <span style="color: #bf120a; font-size: 13.5px">*10%</span>
      </div>

      <div class="invitation-hierarchy-button" @click="invitationMet">
        立即邀请
      </div>

      <div class="invitation-hierarchy-yard">
        我的邀请码：{{ userInfo.inviteCode == null ? "" : userInfo.inviteCode }}
      </div>

      <div class="invitation-hierarchy-rule">
        <div class="rule-title">裂变赚钱规则</div>
        <div class="rule-text" v-html="context"></div>
      </div>
    </div>

    <!--我的上线-->
    <div class="invitation-popup">
      <van-popup v-model="popupShow">
        <div class="invitation-popup-div">
          <div class="popup-div-back">
            <img
              src="../../assets/fission/fission-invitation-online.png"
              alt=""
            />
          </div>

          <div
            class="popup-div-block"
            :style="{ '--height': moduleText == 2 ? '324.5px' : '217px' }"
          >
            <div class="popup-div-block-img" @click="closeMet">
              <img
                src="../../assets/fission/fission-invitation-close.png"
                alt=""
              />
            </div>

            <div
              class="popup-div-block-module"
              :style="{ '--var': moduleText == 2 ? '261px' : '153px' }"
            >
              <div class="module-area" v-if="moduleText == 0">
                <div class="module-area-title">我的上线</div>
                <div class="module-area-text">
                  昵称：{{ userInfo.inviteUserId }}
                </div>
              </div>

              <div class="module-area" v-if="moduleText == 1">
                <div class="module-area-title">您当前没有上线</div>
                <div class="module-area-display">
                  <div
                    class="module-area-display-list"
                    v-for="(item, index) in 2"
                    :key="index"
                    @click="bingbanMet(index)"
                  >
                    {{ index == 0 ? "" : "马上绑定" }}
                    <van-field
                      v-if="index == 0"
                      v-model="inviteCode"
                      placeholder="请输入邀请码"
                    />
                  </div>
                </div>
              </div>

              <div class="module-area" v-if="moduleText == 2">
                <div class="module-area-title1">
                  复制下列文案，发送到微信朋友圈，轻松躺赚一个亿!
                </div>
                <div class="module-area-text1">邀请文案</div>
                <div class="module-area-kuai">
                  <div class="kuai-title" v-html="context1"></div>
                  <div class="kuai-text">
                    我的邀请码: {{ userInfo.inviteCode }}
                  </div>
                </div>

                <div class="module-area-buttom" @click="BindingMet">
                  复制文案
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import { fissionBinding } from "../../api/fission";

import { my } from "../../api/my";

import { Toast } from "vant";

import { loginAgreement } from "../../api/login";
export default {
  data() {
    return {
      // 任务详情弹出框
      popupShow: false,
      // 上线有 0 有上线 1 无上限 2 邀请好友
      moduleText: 2,
      // --裂变对象---
      informationObj: {},
      inviteCode: "",
      userInfo: {},
      context: "",
      context1: "",
    };
  },
  created() {
    // 获取用户个人信息
    this.myMet();
    // 用户协议
    this.agreementMet(2);
    // 用户协议
    this.agreementMet(5);
  },
  methods: {
    agreementMet(index) {
      let params = {
        type: index,
      };

      loginAgreement(params).then((res) => {
        if (res.code == 200) {
          if (index == 2) {
            this.context = res.result.content;
          } else {
            this.context1 = res.result.content;
          }
          console.log("查询", this.context);
        }
      });
    },
    // 返回上一层
    backRight() {
      this.$router.go(-1);
    },
    // 获取个人信息接口数据
    myMet() {
      my().then((res) => {
        console.log("获取个人信息", res);
        if (res.code == 200) {
          this.userInfo = res.result;
        }
      });
    },

    // 用户绑定上级Id
    fissionBindingMet() {
      console.log("绑定成功", this.inviteCode);

      let params = this.inviteCode;

      console.log("查询", params);

      fissionBinding(params).then((res) => {
        if (res.code == 200) {
          Toast("绑定成功");
          this.popupShow = false;

          // 获取用户个人信息
          this.myMet();
          // 用户协议
          this.agreementMet();
        } else {
          Toast(res.message);
        }
      });
    },
    // 点击查看上线弹出框开启
    threadBtn() {
      if (this.userInfo.inviteUserId == null) {
        this.moduleText = 1;
      } else {
        this.moduleText = 0;
      }

      this.popupShow = true;
    },

    // 用户点击弹出框关闭
    closeMet() {
      this.popupShow = false;
    },
    // 用户点击马上绑定
    bingbanMet(index) {
      if (index == 1) {
        this.fissionBindingMet();
      }
    },
    // 用户点击立即邀请
    invitationMet() {
      this.moduleText = 2;
      this.popupShow = true;
    },

    // 用户点击复制文案
    BindingMet() {
      let text = this.context1;
      let item = `${text}https://661222.com/?invitation=${this.userInfo.inviteCode}`;
      // let item =
      //   text +
      //   "https://661222.com/?invitation=" +
      //   this.userInfo.inviteCode;
      // this.moduleText = 1;

      this.copy(item);
    },
    // 复制功能
    copy(data) {
      // 存储传递过来的数据
      let OrderNumber = data;
      // 创建一个input 元素
      // createElement() 方法通过指定名称创建一个元素
      let newInput = document.createElement("input");
      // 讲存储的数据赋值给input的value值
      newInput.value = OrderNumber;
      // appendChild() 方法向节点添加最后一个子节点。
      document.body.appendChild(newInput);
      // 选中input元素中的文本
      // select() 方法用于选择该元素中的文本。
      newInput.select();
      // 执行浏览器复制命令
      //  execCommand方法是执行一个对当前文档，当前选择或者给出范围的命令
      document.execCommand("Copy");
      // 清空输入框
      newInput.remove();
      // 下面是element的弹窗 不需要的自行删除就好
      Toast("复制成功");
    },
  },
};
</script>

<style lang="less" scoped>
.invitation {
  background: #fff6e8;
  .invitation-hierarchy {
    padding-top: 63px;
    overflow: hidden;
    position: relative;

    .invitation-hierarchy-left {
      width: 20px;
      height: 20px;
      position: absolute;
      z-index: 9;
      left: 13.5px;
      top: 40px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .invitation-hierarchy-title {
      font-size: 20px;
      font-family: Hiragino Sans GB;
      font-weight: bold;
      color: #000000;
      text-align: center;
    }
    .invitation-hierarchy-block {
      display: flex;
      justify-content: flex-end;
      height: 30px;
      margin-top: 15.5px;
      .block-right {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 88px;
        height: 100%;
        background: #ff9f3a;
        border-radius: 5px 0px 0px 5px;

        .block-right-title {
          font-size: 10px;
          font-family: Hiragino Sans GB;
          font-weight: normal;
          color: #ffffff;
        }
        .block-right-img {
          width: 5.5px;
          margin-left: 6px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .invitation-hierarchy-module {
      display: flex;
      align-items: flex-end;
      margin-left: 52.5px;
      .module-list {
        .module-list-title {
          width: 67.5px;
          font-size: 12.5px;
          font-family: Hiragino Sans GB;
          font-weight: normal;
          color: #292626;
          margin-left: 14px;
          margin-bottom: 2.5px;
        }
        .module-list-img {
          width: 113.5px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .invitation-hierarchy-text {
      font-size: 13px;
      font-family: Hiragino Sans GB;
      font-weight: bold;
      color: #212121;
      margin-top: 10px;
      text-align: center;
    }
    .invitation-hierarchy-button {
      width: 130px;
      height: 39.5px;
      background: linear-gradient(0deg, #ed4034 0%, #ed3325 51%, #ce190d 100%);
      border-radius: 5px;

      font-size: 15px;
      font-family: Hiragino Sans GB;
      font-weight: normal;
      color: #ffffff;
      line-height: 39.5px;
      text-align: center;
      margin: 0 auto;
      margin-top: 22px;
    }
    .invitation-hierarchy-yard {
      font-size: 12.5px;
      font-family: Hiragino Sans GB;
      font-weight: bold;
      color: #000000;
      text-align: center;
      margin-top: 15.5px;
    }
    .invitation-hierarchy-rule {
      height: 322.5px;
      background: linear-gradient(
        0deg,
        #ffff00 0%,
        #ef876c 0%,
        #f25344 51%,
        #e42a1e 100%
      );
      opacity: 0.84;
      border-radius: 15px 15px 0px 0px;
      margin-top: 15.5px;
      padding: 29px 27.5px 0 27.5px;
      box-sizing: border-box;
      .rule-title {
        font-size: 17.5px;
        font-family: Hiragino Sans GB;
        font-weight: bold;
        color: #ffffff;
        text-align: center;
        margin-bottom: 29px;
      }

      .rule-text {
        font-size: 14px;
        font-family: Hiragino Sans GB;
        font-weight: normal;
        color: #ffffff;
        line-height: 30px;
      }
    }
  }
  .invitation-popup {
    /deep/.van-popup {
      background-color: rgba(255, 255, 255, 0) !important;
    }

    /deep/ .van-overlay {
      background-color: rgba(0, 0, 0, 0.45) !important;
    }
    .invitation-popup-div {
      width: 293.5px;
      position: relative;
      display: flex;
      justify-content: center;

      .popup-div-back {
        width: 156px;
        height: 112.5px;
        position: absolute;
        margin: 0 auto;

        img {
          width: 100%;
          height: 100%;
          position: absolute;
          display: flex;
          z-index: 1;
        }
      }
      .popup-div-block {
        height: var(--height);
        background: linear-gradient(
          0deg,
          #f94937 0%,
          #f25d4f 51%,
          #de261a 100%
        );
        border-radius: 5px;
        width: 100%;
        margin-top: 58.5px;
        position: relative;

        .popup-div-block-img {
          width: 12px;
          height: 12px;
          position: absolute;
          right: 19.5px;
          top: 20px;
          img {
            width: 100%;
            height: 100%;
          }
        }

        .popup-div-block-module {
          height: var(--var);
          background: #ffffff;
          border-radius: 5px;
          margin: 54px 10px 0;
          display: flex;
          align-items: center;
          justify-content: center;

          .module-area {
            text-align: center;
            .module-area-title {
              font-size: 20px;
              font-family: Hiragino Sans GB;
              font-weight: bold;
              color: #000000;
            }
            .module-area-text {
              margin-top: 24px;
              font-size: 16px;
              font-family: Hiragino Sans GB;
              font-weight: bold;
              color: #4c4b4b;
            }

            .module-area-display {
              display: flex;
              justify-content: space-between;
              margin-top: 28px;

              .module-area-display-list {
                width: 114.5px;
                height: 33px;
                background: #ffffff;
                border-radius: 5px;

                font-size: 16px;
                font-family: Hiragino Sans GB;
                font-weight: normal;
                color: #4c4b4b;
                margin-right: 7px;
                display: flex;
                align-items: center;
                justify-content: center;

                /deep/ .van-cell {
                  padding: 0 0 0 10px;
                  border: 1px solid #4c4b4b;
                  border-radius: 5px;
                  height: 33px;
                }

                /deep/ .van-field__value {
                  display: flex;
                  align-items: center;
                }
              }

              .module-area-display-list:last-child {
                margin-right: 0;
                background: #e74c45;
                color: #ffffff;
                border: 0;
              }
            }
            .module-area-title1 {
              font-size: 16px;
              font-family: Hiragino Sans GB;
              font-weight: bold;
              color: #e74c45;
            }

            .module-area-text1 {
              font-size: 15px;
              font-family: Hiragino Sans GB;
              font-weight: bold;
              color: #000000;
              margin: 13.5px 0 6.5px;
            }

            .module-area-kuai {
              padding: 10.5px 8px 11px;
              box-sizing: border-box;
              width: 240px;
              // height: 101.5px;
              height: auto;
              background: #ffffff;
              border: 1px solid #686868;
              border-radius: 5px;
              margin: 0 auto;
              text-align: left;

              .kuai-title,
              .kuai-text {
                font-size: 13px;
                font-family: Hiragino Sans GB;
                font-weight: normal;
                color: #4e4d4d;
              }

              .kuai-text {
                text-align: center;
                margin-top: 10px;
              }
            }

            .module-area-buttom {
              font-size: 16px;
              font-family: Hiragino Sans GB;
              font-weight: normal;
              color: #ffffff;

              width: 113.5px;
              height: 32px;
              background: #e74c45;
              border-radius: 5px;
              text-align: center;
              line-height: 32px;

              margin: 0 auto;
              margin-top: 12.5px;
            }
          }
        }
      }
    }
  }
}
</style>
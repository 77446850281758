<template>
  <div class="home">
    <div class="home-streamer">
      <div class="home-streamer-img">
        <img src="../../assets/home/home-streamer.png" alt="" />
      </div>

      <div class="home-streamer-navigation">
        <div class="navigation-first">
          <div class="navigation-first-left" @click="showPopupMet">
            <div class="left-img">
              <img src="../../assets/home/home-money.gif" alt="" />
            </div>
            <div class="left-title">
              赚{{ recommendObj.amountUnit == undefined ? "0" : recommendObj.amountUnit }}元
            </div>
          </div>
          <div class="navigation-first-center" @click="searchBtn">
            <van-field
              v-model="searchTask"
              :left-icon="searchImg"
              placeholder="搜索任务标题、编号"
            />
          </div>
        </div>

        <div class="navigation-second">
          <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
            <van-swipe-item v-for="(item, index) in swiperList" :key="index">
              <img :src="item.imgUrl" alt="" @click="swiperMet(item)" />
            </van-swipe-item>
          </van-swipe>
        </div>

        <div class="navigation-thirdly">
          <div class="navigation-thirdly-notice">
            <inform :title="informList"></inform>
          </div>
        </div>
        <div
          class="navigation-fourthly"
          :style="{
            '--top': homeIndex == 0 ? '17.5px' : '15px',
          }"
        >
          <div
            class="navigation-fourthly-list"
            v-for="(item, index) in 2"
            :key="index"
            @click="tabsBtn(index)"
          >
            <div
              class="list-title"
              :style="{ color: tabsIndex == index ? '#000000' : '' }"
            >
              {{ index == 0 ? "推荐赚钱" : "热门任务" }}
            </div>
            <div class="list-lint" v-if="tabsIndex == index"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="home-streamer-lint"></div>

    <div class="home-scrolling">
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="list.length == 0 ? '' : ''"
        @load="onLoad"
      >
        <div
          class="home-scrolling-list"
          v-for="(item, index) in list"
          :key="index"
          @click="particularsBtn(item.id)"
        >
          <div class="list-left">
            <img
              class="list-left-back"
              v-if="index == 0 || index == 1 || index == 2"
              :src="
                index == 0
                  ? require('../../assets/home/home-icon-first.png')
                  : index == 1
                  ? require('../../assets/home/home-icon-second.png')
                  : index == 2
                  ? require('../../assets/home/home-icon-thirdly.png')
                  : ''
              "
              alt=""
            />
            <img class="list-left-circle" :src="item.mainImg" alt="" />
          </div>
          <div class="list-right">
            <div class="list-right-block">
              <div class="block-title">
                <div class="block-title-headline">{{ item.title }}</div>
                <div
                  class="block-title-explode"
                  v-for="(item, index) in item.tabs"
                  :key="index"
                >
                  {{ item == 1 ? "推" : item == 2 ? "顶" : "" }}
                </div>
              </div>
              <div class="block-label">
                <div class="block-label-list">
                  {{
                    item.type == 0
                      ? "小程序体验"
                      : item.type == 1
                      ? "关注体验"
                      : item.type == 2
                      ? "砍掉互帮"
                      : item.type == 3
                      ? "试用试玩"
                      : item.type == 4
                      ? "高阶任务"
                      : item.type == 5
                      ? "其他任务"
                      : ""
                  }}
                </div>

                <div class="block-label-list">
                  {{ item.name }}
                </div>
              </div>
              <div class="block-span">
                已赚<span style="color: #e94e44">{{ item.totalEarnPrice }}</span>元
                接单限时 <span style="color: #e94e44">{{ formatTime(item.receivingTimeLimit) }}</span>
                <!-- 已赚<span style="color: #e94e44">{{ item.auditNumTotal }}</span>人  -->
                <!-- 人均用{{ formatTime(item.usedTimeAverage) }}
                <span style="color: #e94e44">--</span> 平均审核
                <span style="color: #e94e44">{{ formatTime(item.auditTimeAverage) }}</span>分钟 -->
              </div>
            </div>
          </div>
          <div class="list-relocating">{{ item.amountUnit == null ? '': item.amountUnit }}元</div>
        </div>
        <empty :list="list"></empty>
      </van-list>
    </div>
    <!-- <suspension></suspension> -->
    <!--弹出框-->
    <!-- <div class="home-popup">
      <van-popup v-model="popupShow">
        <div class="home-popup-block">
          <div class="block-top">
            <img
              class="block-top-img"
              src="../../assets/common/common-popup-top.png"
              alt=""
            />

            <img
              class="block-top-close"
              src="../../assets/common/common-close.png"
              alt=""
              @click="popupShow = false"
            />

            <div class="top-title">
              <div class="title">
                完成后立得
                <span style="color: #ffec18; font-size: 19px; font-weight: bold"
                  >￥{{ recommendObj.totalPrice }}</span
                >
              </div>
              <div class="text">
                下一波倒计时:
                <van-count-down :time="recommendObj.residueTime" />
              </div>
            </div>
          </div>
          <div class="block-center">
            <div
              class="block-center-list"
              v-for="(item, index) in recommendList"
              :key="index"
              @click="particularsBtn(item.id)"
            >
              <div class="list-up">
                <div class="list-up-portrait">
                  <img :src="item.mainImg" alt="" />
                </div>
                <div
                  class="list-up-display"
                  v-for="(item, index) in item.tabs"
                  :key="index"
                >
                  {{ item == 1 ? "推" : item == 2 ? "顶" : "" }}
                </div>
              </div>
              <div class="list-centre">
                <div
                  class="list-centre-display"
                  v-for="(info, num) in 2"
                  :key="num"
                >
                  <div class="display-title">
                    {{
                      num == 0
                        ? item.type == 0
                          ? "小程序体验"
                          : item.type == 1
                          ? "关注体验"
                          : item.type == 2
                          ? "砍掉互帮"
                          : item.type == 3
                          ? "试用试玩"
                          : item.type == 4
                          ? "高阶任务"
                          : item.type == 5
                          ? "其他任务"
                          : ""
                        : item.name
                    }}
                  </div>
                  <div class="display-time">
                    {{
                      num == 0 ? item.usedTimeAverage : item.auditTimeAverage
                    }}分钟
                  </div>
                  <div class="display-text">
                    {{ num == 0 ? "人均用时" : "平均审核" }}
                  </div>
                </div>
              </div>
              <div class="list-price">+{{ item.amountUnit }}元</div>
            </div>
          </div>
        </div>
      </van-popup>
    </div> -->
  </div>
</template>

<script>
// import suspension from "../../components/suspension/suspension.vue"; // 悬浮点击
import inform from "../../components/inform/inform.vue"; // 消息通知组件
import { homeRecommend, homeTask, homeSwiper } from "../../api/home";
import { timeVerification } from "../../plugins/date";

import empty from "@/components/empty/empty.vue";
export default {
  components: {
    // suspension,
    inform,
    empty,
  },

  data() {
    return {
      // 第一区域 搜索区域  左侧图标 本地图片
      searchImg: require("../../assets/home/home-seek.png"),
      // 第一区域 动态设置背景高度 判断当前是需要那个分类  0 1 2
      homeIndex: 0,
      // 搜索任务标题、编号
      searchTask: "",
      // 标签栏tabs中下标选中
      tabsIndex: 0,
      // 首页列表数组
      page: 1, // 当前页码
      limit: 10, // 当前页数
      list: [], // 当前数组列表
      loading: false, // 数组列表加载圈
      finished: false, // 是否暂时没有更多了
      refreshing: false, // 是否进行滚动加载
      //-----首页弹出框区域----------------------------
      // popupShow: false,
      //-----组件传递通知------------------
      informList: [
        "严禁一切侵犯个人信息，网络安全等违法行为",
        "严禁一切侵犯个人信息，网络安全等违法行为",
        "严禁一切侵犯个人信息，网络安全等违法行为",
      ],
      //------推荐列表----------
      recommendList: [],
      recommendObj: {},
      // ----轮播图列表-------
      swiperList: [],
      totalNum: 0,
      // 图片暂缓
      // imgs: "https://after.byqp99.com/jeecg-bootsys/common/static/",
      // imgs: "https://backendweb.661222.com/jeecg-boot/sys/common/static/",
      imgs: process.env.VUE_APP_IMAGE_URL,
    };
  },
  created() {
    // 获取推荐列表
    this.homeRecommendMet();
    // 获取轮播图列表
    this.homeSwiperMet();
  },
  methods: {
    // 获取当前轮播图列表
    homeSwiperMet() {
      let params = {};
      homeSwiper(params).then((res) => {
        if (res.code == 200) {
          res.result.forEach((item) => {
            if (item.imgUrl != null) {
              if (item.imgUrl.substr(0, 3) != "htt") {
                item.imgUrl = this.imgs + item.imgUrl;
              }
            }
          });
          this.swiperList = res.result;
        }
      });
    },
    // 获取推荐列表接口数据
    homeRecommendMet() {
      homeRecommend().then((res) => {
        console.log("排查", res);
        if (res.code == 200) {
          this.recommendObj = res.result.taskList == null? '': res.result.taskList;
        }
      });
    },

    // 点击赚30开启弹出框
    showPopupMet() {
      // this.popupShow = true;
      console.log("---", this.recommendObj);
      this.particularsBtn(this.recommendObj.id);
    },

    // 分类选中下标切换
    tabsBtn(index) {
      console.log('点击是否可用', index)
      this.tabsIndex = index;
      this.page = 1
      this.finished = true;
      this.refreshing = true;
      console.log('点击是否可用', this.tabsIndex)
      this.onLoad();
    },
    // 获取当前列表中对应的数组数据
    onLoad() {
      // 加载状态结束
      // this.loading = false;
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      if (this.refreshing) {
        this.list = [];
        this.refreshing = false;
      }
      let params = {
        keyword: "", //关键字
        isTopped: this.tabsIndex, // 是否极速审核 1是
        pageSize: this.limit, // 页码
        pageNo: this.page, // 页数
      };
      homeTask(params).then((res) => {
        if (res.code == 200) {
          this.loading = false;
          this.finished = false;
          res.result.linkLogList.forEach((item) => {
            console.log("排查");
            item.tabs = item.tag.length > 0 ? item.tag.split(",") : '';
            if (item.mainImg != null) {
              if (item.mainImg.substr(0, 3) != "htt") {
                item.mainImg = this.imgs + item.mainImg;
              }
            }
          });
          this.list.push(...res.result.linkLogList);
          console.log("获取list", this.list);
        } else {
        };
        // 数据全部加载完成
        if (this.list.length >= res.result.totalNum) {
          this.finished = true; // 到了滚动极限则返回true
        } else {
          this.page++;
        }
      });
    },
    // 点击搜索页进入搜索框
    searchBtn() {
      this.$router.push({
        path: "/search",
      });
    },
    // 点击任务详情列表 跳转任务详情
    particularsBtn(id) {
      this.$router.push({
        path: "/particulars",
        query: {
          id: id,
        },
      });
    },
    // 轮播图点击跳转
    swiperMet(item) {
      console.log("用户点击轮播图", item);
      window.open(item.url, "_self");
    },
    formatTime(_seconds){
      return timeVerification(_seconds);
    }
  },
};
</script>


<style lang="less" scoped>
.home {
  .home-streamer {
    height: 275px !important;
    position: fixed;
    top: 0;
    display: flex;
    width: 100%;
    z-index: 9;
    .home-streamer-img {
      width: 100%;
      height: 275px !important;
      position: absolute;
      display: flex;

      img {
        width: 100%;
        height: 100%;
      }
    }
    .home-streamer-navigation {
      position: relative;
      padding: 0 11px;
      padding-top: 49px;
      box-sizing: border-box;
      width: 100%;
      height: 230px;
      background: red;
      border-bottom-left-radius: 30px;
      border-bottom-right-radius: 30px;
      .navigation-first {
        height: 31px;
        display: flex;
        justify-content: space-between;

        .navigation-first-left {
          display: flex;
          align-items: center;
          .left-img {
            width: 21.5px;
            height: 21.5px;
            img {
              width: 100%;
              height: 100%;
            }
          }

          .left-title {
            font-size: 15.5px;
            font-family: Hiragino Sans GB;
            font-weight: normal;
            color: #ffffff;
            margin-left: 6px;
            letter-spacing: 1px;
          }
        }
        .navigation-first-center {
          width: 235.5px;
          /deep/ .van-cell {
            padding: 0px;
            height: 31px;
            display: flex;
            align-items: center;
            border-radius: 10px;
            padding: 0 6px;
            box-sizing: border-box;
          }

          /deep/ .van-cell__value {
            margin-left: 5px;
          }

          /deep/ .van-field__control {
            font-size: 11.5px;
            font-family: Hiragino Sans GB;
            font-weight: normal;
            color: #e74c45 !important;
          }

          /deep/ .van-icon__image {
            width: 11.5px;
            height: 12px;
            opacity: 1;
            z-index: 1;
          }

          /deep/ .van-field__control::-webkit-input-placeholder {
            color: #e74c45;
          }
        }
      }
      .navigation-second {
        height: 100px;
        border-radius: 7.5px;
        margin-top: 7.5px;
        padding: 0 4.5px;
        box-sizing: border-box;
        img {
          height: 100px;
          width: 100%;
          border-radius: 7.5px;
        }
      }
      .navigation-thirdly {
        margin-top: 9.5px;
        border-radius: 7.5px;
        box-sizing: border-box;

        .navigation-thirdly-notice {
          display: flex;
          align-items: center;
        }
      }
      .navigation-fourthly {
        margin-top: var(--top) !important;
        display: flex;
        justify-content: center;
        .navigation-fourthly-list {
          width: 75.5px;
          height: 34px;
          margin-left: 110px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;

          .list-title {
            font-size: 14.5px;
            font-family: Hiragino Sans GB;
            font-weight: bold;
            color: #656463;
          }
          .list-lint {
            width: 100%;
            height: 5.5px;
            background: #e52b1e;
            position: absolute;
            bottom: 0;
          }
        }

        .navigation-fourthly-list:first-child {
          margin-left: 0;
        }
      }
    }
  }

  .home-streamer-lint {
    height: 275px;
  }
  .home-scrolling {
    margin-bottom: 67.5px;
    margin-top: 10px;
    .home-scrolling-list {
      position: relative;
      height: 91px;
      border-bottom: 1px solid #e9e9e9;
      display: flex;
      margin: 0 12.5px;
      box-sizing: border-box;
      .list-left {
        width: 38.5px;
        height: 53px;
        margin-top: 8.5px;
        position: relative;
        display: flex;
        justify-content: center;

        .list-left-back {
          width: 100%;
          height: 100%;
          position: absolute;
        }

        .list-left-circle {
          width: 35px;
          height: 35px;
          position: absolute;
          bottom: 1.5px;
          z-index: 1;
          border-radius: 50%;
        }
      }
      .list-right {
        margin-left: 9px;

        display: flex;
        align-items: center;
        .list-right-block {
          .block-title {
            display: flex;
            align-items: center;
            .block-title-headline {
              font-size: 14.5px;
              font-family: Hiragino Sans GB;
              font-weight: bold;
              color: #000000;
              margin-right: 12px;
            }
            .block-title-explode {
              width: 16.5px;
              height: 17px;
              line-height: 17px;
              text-align: center;
              border: 1px solid #f6503c;
              border-radius: 3.5px;
              font-size: 10.5px;
              font-family: Hiragino Sans GB;
              font-weight: normal;
              color: #f6503c;
              margin-right: 5px;
            }

            .block-title-explode:last-child {
              margin-left: 0;
            }
          }

          .block-label {
            display: flex;
            margin-top: 11.5px;
            .block-label-list {
              height: 18px;
              background: #e9e9e9;
              border-radius: 6px;
              padding: 0 3px;
              box-sizing: border-box;
              font-size: 11.5px;
              font-family: Hiragino Sans GB;
              font-weight: normal;
              color: #a8a7a7;
              margin-right: 5px;
            }
          }
          .block-span {
            margin-top: 11.5px;
            font-size: 11.5px;
            font-family: Hiragino Sans GB;
            font-weight: normal;
            color: #a8a7a7;
          }
        }
      }

      .list-relocating {
        position: absolute;
        right: 12.5px;
        top: 8.5px;
        font-size: 13px;
        font-family: Hiragino Sans GB;
        font-weight: bold;
        color: #f6503c;
      }
    }

    .home-scrolling-list:last-child {
      border-bottom: 0;
    }
  }
  .home-popup {
    /deep/ .van-popup {
      border-radius: 10px !important;
    }
    .home-popup-block {
      width: 290.5px;
      background: #fce2df;
      position: relative;
      .block-top {
        height: 68.5px;
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        .block-top-img {
          width: 100%;
          height: 68.5px;
          position: absolute;
        }

        .block-top-close {
          position: absolute;
          right: 11.5px;
          width: 10.5px;
          height: 10.5px;
          z-index: 1;
          top: 8px;
        }

        .top-title {
          position: relative;
          margin-left: 70px;
          .title {
            font-size: 16px;
            font-family: Hiragino Sans GB;
            font-weight: normal;
            color: #fce2df;
            text-align: center;
          }
          .text {
            display: flex;
            font-size: 13.5px;
            font-family: Hiragino Sans GB;
            font-weight: normal;
            color: #fce2df;
            margin-top: 3.5px;

            /deep/ .van-count-down {
              margin-left: 5px;
              color: #fce2df;
            }
          }
        }
      }
      .block-center {
        height: 149px;
        display: flex;
        justify-content: center;
        align-items: center;
        .block-center-list {
          width: 128px;
          height: 129px;
          background: #ffffff;
          border-radius: 5px;
          margin-right: 9.5px;
          .list-up {
            display: flex;
            align-items: flex-end;
            .list-up-portrait {
              width: 29.5px;
              height: 29.5px;
              border-radius: 50%;
              margin-left: 49.5px;
              margin-right: 2px;

              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
              }
            }
            .list-up-display {
              width: 15px;
              height: 15px;
              border: 1px solid #f6503c;
              border-radius: 3.5px;
              margin-right: 3px;
              font-size: 7px;
              font-family: Hiragino Sans GB;
              font-weight: normal;
              color: #f6503c;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .list-up-display:last-child {
              margin-right: 0;
            }
          }
          .list-centre {
            display: flex;
            justify-content: space-between;
            padding: 0 9px;
            box-sizing: border-box;
            margin-top: 4.5px;
            .list-centre-display {
              .display-title {
                font-size: 9.5px;
                font-family: Hiragino Sans GB;
                font-weight: normal;
                color: #a8a7a7;
                background: #e9e9e9;
                text-align: center;
              }
              .display-time {
                font-size: 12px;
                font-family: Hiragino Sans GB;
                font-weight: normal;
                color: #f6503c;
                text-align: center;
                margin: 6.5px 0;
              }
              .display-text {
                font-size: 10.5px;
                font-family: Hiragino Sans GB;
                font-weight: normal;
                color: #a8a7a7;
                text-align: center;
              }
            }
          }
          .list-price {
            font-size: 12.5px;
            font-family: Hiragino Sans GB;
            font-weight: bold;
            color: #f6503c;
            text-align: center;
            margin-top: 6px;
          }
        }

        .block-center-list:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
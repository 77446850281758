<template>
  <div class="advice">
    <div class="advice-stremer">
      <streamer :title="title"></streamer>
    </div>

    <div class="advice-lint"></div>

    <div class="advice-scrolling">
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="list.length == 0 ? '' : ''"
        @load="onLoad"
      >
        <div class="advice-scrolling-block">
          <div class="block-list" v-for="(item, index) in list" :key="index">
            <div class="block-list-top">
              <div class="top-left">
                <div class="top-left-img">
                  <img src="../../assets/my/my-advice-message.png" alt="" />
                </div>
                <div class="top-left-title">{{ item.title }}</div>
              </div>
              <div class="top-right">{{ item.createTime }}</div>
            </div>
            <div class="block-list-center">
              <div class="center-title">
                {{ item.content }}
              </div>
            </div>
          </div>
        </div>
        <empty :list="list"></empty>
      </van-list>
    </div>
  </div>
</template>

<script>
import streamer from "../../components/streamer/streamer.vue";

import empty from "@/components/empty/empty.vue";

import { myinformationMessgin } from "../../api/my";
export default {
  components: {
    streamer,
    empty,
  },
  data() {
    return {
      title: "接单通知", // 标题

      // 首页列表数组
      page: 1, // 当前页码
      limit: 10, // 当前页数
      list: [], // 当前数组列表
      loading: false, // 数组列表加载圈
      finished: false, // 是否暂时没有更多了
      refreshing: false, // 是否进行滚动加载
      totalNum: 0,
      // --------用户接单通知-------------
    };
  },
  created() {},
  methods: {
    // 获取当前列表中对应的数组数据
    onLoad() {
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      if (this.refreshing) {
        this.list = [];
        this.refreshing = false;
      }

      let params = {
        pageSize: this.limit, // 页码
        pageNo: this.page, // 页数
      };

      myinformationMessgin(params).then((res) => {
        if (res.code == 200) {
          this.list.push(...res.result.informationList);
          this.totalNum = res.result.totalNum;
        }

        // 加载状态结束
        this.loading = false;

        // 数据全部加载完成
        if (this.list.length >= this.totalNum) {
          this.finished = true; // 到了滚动极限则返回true
        } else {
          this.page++;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.advice {
  .advice-stremer {
    width: 100%;
    height: 90.5px;
    position: fixed;
    z-index: 9;
    top: 0;
  }

  .advice-lint {
    height: 90.5px;
  }
  .advice-scrolling {
    margin-top: 10px;
    .advice-scrolling-block {
      margin: 0 13px;
      .block-list {
        background: #ffffff;
        border-radius: 5px;
        margin-bottom: 10px;
        .block-list-top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 43.5px;
          border-bottom: 1px solid #e9e9e9;
          padding: 0 10px;
          box-sizing: border-box;
          .top-left {
            display: flex;
            align-items: center;

            .top-left-img {
              width: 13px;
              height: 18.5px;
              margin-right: 5px;

              img {
                width: 100%;
                height: 100%;
              }
            }
            .top-left-title {
              font-size: 15px;
              font-family: Hiragino Sans GB;
              font-weight: normal;
              color: #000000;
            }
          }
          .top-right {
            font-size: 12.5px;
            font-family: Hiragino Sans GB;
            font-weight: normal;
            color: #696767;
          }
        }
        .block-list-center {
          text-align: left;
          padding: 14px 22px;
          box-sizing: border-box;
          .center-title {
            font-size: 14px;
            font-family: Hiragino Sans GB;
            font-weight: normal;
            color: #4e4d4d;
            line-height: 24px;
          }
        }
      }
    }
  }
}
</style>
<template>
  <div class="rule">
    <div class="rule-stremer">
      <streamer :title="title"></streamer>
    </div>

    <div class="rule-stremer-lint"></div>

    <div class="rule-matter">
      <div class="rule-matter-block" v-html="context">  
      </div>
    </div>
  </div>
</template>

<script>
import streamer from "../../components/streamer/streamer.vue";
import { loginAgreement } from "../../api/login";

export default {
  components: {
    streamer,
  },
  data() {
    return {
      title: "", // 标题
      context: "",
    };
  },
  created() {
    this.agreementMet()
  },
  methods: {
    agreementMet() {
      let params = {
        type: 3,
      };

      loginAgreement(params).then((res) => {
        if (res.code == 200) {
          this.context = res.result.content;
          this.title = res.result.title;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.rule {
  background: #ffffff;

  .rule-stremer {
    width: 100%;
    height: 90.5px;
    position: fixed;
    z-index: 9;
    top: 0;
  }
  .rule-stremer-lint {
    height: 90.5px;
  }

  .rule-matter {
    padding: 28.5px 30.5px;
    box-sizing: border-box;
    .rule-matter-block {
      margin-bottom: 40px;
      .block-headline {
        display: flex;
        align-items: center;
        .block-headline-img {
          width: 19px;
          height: 19px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .block-headline-title {
          font-size: 14.5px;
          font-weight: bold;
          color: #e74c45;
          margin-left: 10px;
        }
      }

      .block-text {
        font-size: 14.5px;
        color: #000000;
        margin-top: 10px;
        text-indent: 30px;
        font-family: Hiragino Sans GB;
        font-weight: 600;
        line-height: 25px;
        letter-spacing: 0.5px;
      }
    }
  }
}
</style>
<template>
  <div class="empty">
    <div class="empty-block" v-if="list.length == 0">
      <div v-if="index != 2">
        <img
          class="empty-block-img"
          :src="
            index == 1
              ? require('../../assets/common/common-empty-1.png')
              : require('../../assets/common/common-empty.png')
          "
          alt=""
        />
        <div
          class="empty-block-title"
          :style="{ color: index == 1 ? '#FAD9D9' : '' }"
        >
          暂无数据
        </div>
      </div>

      <div v-else>
        <div class="back">
          <img
            class="empty-block-img-1"
            src="../../assets/common/common-empty-2.png"
            alt=""
          />
          <div class="empty-block-title-1">哎呀～没找到</div>
        </div>
      </div>
    </div>
    <div class="empty-line" v-if="list.length != 0">
      <img
        class="empty-line-img"
        src="../../assets/common/common-baseline.png"
        alt=""
      />
      <div class="empty-line-title">我也是有底线的～</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["list", "index"],
};
</script>

<style lang="less" scoped>
.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  .empty-block {
    margin-top: 35px;
    text-align: center;
    div {
      .empty-block-img {
        width: 80.5px;
        height: 83px;
      }
      .empty-block-title {
        font-size: 14px;
        font-family: Hiragino Sans GB;
        font-weight: normal;
        color: #5f5f5f;
        margin-top: 19.5px;
      }

      .back {
        width: 288.5px;
        height: 288px;
        position: relative;
        .empty-block-img-1 {
          width: 288.5px;
          height: 288px;
        }

        .empty-block-title-1 {
          font-size: 14px;
          font-family: Hiragino Sans GB;
          font-weight: normal;
          color: #e74c45;
          position: absolute;
          bottom: 28px;
          width: 100%;
          margin: 0 auto;
        }
      }
    }
  }
  .empty-line {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    .empty-line-img {
      width: 15px;
      height: 12.5px;

      img {
        width: 100%;
        height: 100%;
      }
    }
    .empty-line-title {
      font-size: 12.5px;
      font-family: Hiragino Sans GB;
      font-weight: normal;
      color: #e74c45;
      margin-left: 10px;
    }
  }
}
</style>
// 首页界面接口
import { get, post, deletefn } from "../api/request";

// 任务大厅界面接口 推荐列表
export const homeRecommend = params => get(`v1/front/task/recommend`, params)

// 任务大厅界面接口 任务列表
export const homeTask = params => get(`v1/front/task`, params)

// 任务大厅界面接口 任务详情
export const homeParticulars = params => get(`v1/front/task/${params}`)

//  任务大厅界面接口 点击报名
export const homeApply = params => post(`v1/front/task`, params)

//  任务大厅界面接口 放弃
export const homeAbandon = params => deletefn(`v1/front/task/${params}`)

//  任务大厅界面接口 提交审核
export const homeSubreview = params => post(`v1/front/task/audit`, params)

// 任务大厅 轮播图列表
export const homeSwiper= params => get(`v1/front/rotation`, params)





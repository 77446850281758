// 界面接口
import { get, post } from "../api/request";

// 超级裂变界面接口 我的上级
export const fissionInformation = params => get(`v1/front/myInvite`, params)

// 超级裂变界面接口 个人信息
export const fissionInfo = params => get(`v1/front/fission`, params)

// 超级裂变界面接口 好友提现巨额赏金底部列表
export const fissionInfoList = params => get(`v1/front/fission/list`, params)

// 超级裂变界面接口 绑定上级
export const fissionBinding = params => post(`v1/front/myInvite?inviteCode=${params}` )

export function timeVerification(time) {
    const oneMinute = 60;
    const oneHour = 60 * 60;
    const onDay = 60 * 60 * 24;
    if (time != null && time !== '') {
        switch(true) {
            case (time > oneMinute && time < oneHour):
                return parseInt(time / oneMinute) + '分钟';
            case (time >= oneHour && time < onDay):
                return parseInt(time / oneHour) + '小时' + parseInt((parseFloat(time / oneHour) - parseInt(time / oneHour)) * 60) + '分钟';
            case (time >= onDay):
                let day = parseInt(time / onDay);
                // let hours = time % onDay < oneHour ? 0 : parseInt(hours / oneHour);
                return `${day}天`;
            default:
                return time > 0 ? '1分钟' : 0
        }
        // if (time > 60 && time < 60 * 60) {
        //     time = parseInt(time / 60.0) + '分钟'
        // } else if (time >= 60 * 60 && time < 60 * 60 * 24) {
        //     time = parseInt(time / 3600.0) + '小时' + parseInt((parseFloat(time / 3600.0) -
        //         parseInt(time / 3600.0)) * 60) + '分钟'
        // }else if(time >= 86400){
        //     let day = parseInt(time / 86400);
        //     let hours = time % 86400 < 3600 ? 0 : parseInt(hours / 3600);
        //     return `${day}天`
        // }else{
        //     return time > 0 ? '1分钟' : 0
        // }
    }
    return time
}
<template>
  <div class="set">
    <div class="streamer">
      <div class="streamer-img">
        <img src="../../assets/ranking/ranking-rule-streamer.png" alt="" />
      </div>

      <div class="streamer-display">
        <div class="display-left" @click="backRight">
          <img src="../../assets/common/common-left.png" alt="" />
        </div>
        <div class="display-center">{{ title }}</div>
        <div class="display-right"></div>
      </div>
    </div>

    <div class="set-list">
      <div class="set-list-left">关于我们</div>

      <div class="set-list-right">
        <div class="right-block">
          <div class="right-block-img">
            <img src="../../assets/my/my-earnings-black-right.png" alt="" />
          </div>
        </div>
      </div>
    </div>

    <div class="set-button">
      <div class="set-button-title" @click="outMet">退出登录</div>
    </div>
  </div>
</template>


<script>
import streamer from "../../components/streamer/streamer.vue";

export default {
  components: {
    streamer,
  },
  data() {
    return {
      title: "设置", // 标题
    };
  },
  methods: {
    outMet() {
      localStorage.clear();
      location.reload();
    },
    // 返回上一层
    backRight() {
      this.$router.replace({
        name: "my",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.set {
  .streamer {
    position: relative;
    height: 90.5px;
    width: 100%;

    .streamer-img {
      width: 100%;
      height: 100%;
      position: absolute;
      display: flex;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .streamer-display {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 54.5px 13.5px 0;
      box-sizing: border-box;
      position: relative;
      .display-left {
        width: 9.5px;
        height: 15.5px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .display-center {
        font-size: 15.5px;
        font-family: Hiragino Sans GB;
        font-weight: bold;
        color: #ffffff;
      }
      .display-right {
        width: 9.5px;
        height: 15.5px;
      }
    }
  }
  .set-list {
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e9e9e9;
    padding: 0 41.5px;
    box-sizing: border-box;

    .set-list-left {
      width: 100px;
      text-align: left;
      font-size: 15px;
      font-family: Hiragino Sans GB;
      font-weight: normal;
      color: #000000;
    }

    .set-list-right {
      .right-block {
        display: flex;
        align-items: center;
        .right-block-img {
          width: 8px;
          margin-left: 4px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .set-button {
    width: 100%;
    position: fixed;
    bottom: 70px;
    display: flex;
    justify-content: center;
    .set-button-title {
      font-size: 18.5px;
      font-family: Hiragino Sans GB;
      font-weight: normal;
      color: #ffffff;

      width: 290px;
      height: 42px;
      background: linear-gradient(0deg, #ed4034 0%, #ed3325 51%, #da261a 100%);
      border-radius: 5px;

      text-align: center;
      line-height: 42px;
    }
  }
}
</style>
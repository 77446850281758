<template>
  <div class="fission">
    <div class="fission-back">
      <div class="fission-back-right">
        <img
          class="right-img"
          src="../../assets/fission/fission-back-icon-1.png"
          alt=""
        />
      </div>

      <div class="fission-back-icon">
        <img
          class="icon-1"
          src="../../assets/fission/fission-back-icon-2.png"
          alt=""
        />
      </div>

      <div class="fission-back-center">
        <div class="back-center-img">
          <div class="center-img-1">
            <img src="../../assets/fission/fission-back-icon-3.png" alt="" />
          </div>

          <div class="center-img-2">
            <img src="../../assets/fission/fission-back-icon-4.png" alt="" />
          </div>

          <div class="center-img-3">
            <div class="center-img-3-rule" @click="inviteBtn">
              <div class="rule-title">裂变赚钱规则</div>
              <div class="rule-img">
                <img src="../../assets/fission/fission-right.png" alt="" />
              </div>
            </div>
            <div class="center-img-3-title">好友提现巨额赏金</div>
            <div class="center-img-3-text">好友团队总人数</div>
            <div class="center-img-3-num">{{ fissionInfoObj.userNum }}人</div>

            <div class="center-img-3-display">
              <div class="display-list" v-for="(item, index) in 2" :key="index">
                <div class="display-list-title">
                  {{ index == 0 ? "历史总收益" : "昨日收益" }}
                </div>
                <div class="display-list-price">
                  {{
                    index == 0
                      ? fissionInfoObj.totalPrice
                      : fissionInfoObj.yesterdayPrice
                  }}
                </div>
              </div>
            </div>

            <div class="center-img-3-button" @click="inviteBtn">立即邀请</div>
          </div>

          <div class="center-img-4">
            <div class="center-img">
              <div class="icon1-back">
                <img
                  src="../../assets/fission/fission-back-icon-6.png"
                  alt=""
                />
              </div>

              <div class="icon2-back">
                <img
                  src="../../assets/fission/fission-back-icon-5.png"
                  alt=""
                />
              </div>

              <div class="icon3-back">
                <img
                  src="../../assets/fission/fission-back-icon-7.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="fission-back-table">
        <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="list.length == 0 ? '' : ' '"
          @load="onLoad"
        >
          <div class="table-div">
            <div class="table-div-list">
              <div class="table-div-list-left">
                <div class="left-ranking">排名</div>
                <div class="left-nickname">会员昵称</div>
              </div>
              <div class="table-div-list-right">
                <div class="right-spread">推广人数</div>
                <div class="right-earnings">上月收益</div>
              </div>
            </div>
          </div>

          <div class="table-list" v-for="(item, index) in list" :key="index">
            <div class="table-div-list">
              <div class="table-div-list-left">
                <div class="left-ranking">{{ index + 1 }}</div>
                <div class="left-nickname">
                  <div class="left-nickname-title">{{ item.username }}</div>
                </div>
              </div>
              <div class="table-div-list-right">
                <div class="right-spread">{{ item.inviteUserNum }}人</div>
                <div class="right-earnings">{{ item.lastMonthPrice }}元</div>
              </div>
            </div>
          </div>

          <empty :list="list" :index="1"></empty>
        </van-list>
      </div>
    </div>
  </div>
</template>

<script>
import { fissionInfo, fissionInfoList } from "../../api/fission";
import empty from "@/components/empty/empty.vue";

export default {
  components: {
    empty,
  },
  data() {
    return {
      // 首页列表数组
      page: 1, // 当前页码
      limit: 10, // 当前页数
      list: [], // 当前数组列表
      loading: false, // 数组列表加载圈
      finished: false, // 是否暂时没有更多了
      refreshing: false, // 是否进行滚动加载
      //-----超级裂变个人信息---------
      fissionInfoObj: {},
    };
  },
  created() {
    this.fissionMet();
  },
  methods: {
    fissionMet() {
      let params = {};

      fissionInfo(params).then((res) => {
        if (res.code == 200) {
          this.fissionInfoObj = res.result;
        }
      });
    },

    // 获取当前列表中对应的数组数据
    onLoad() {
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      if (this.refreshing) {
        this.list = [];
        this.refreshing = false;
      }

      let params = {
        pageSize: this.limit, // 页码
        pageNo: this.page, // 页数
      };

      fissionInfoList(params).then((res) => {
        if (res.code == 200) {
          this.list.push(...res.result.fissionList);
          this.totalNum = res.result.totalNum;
        }

        // 加载状态结束
        this.loading = false;

        // 数据全部加载完成
        if (this.list.length >= this.totalNum) {
          this.finished = true; // 到了滚动极限则返回true
        } else {
          this.page++;
        }
      });
    },
    // 用户点击立即邀请详情
    inviteBtn() {
      console.log("点击立即邀请");

      this.$router.push({
        path: "/fission-invitation",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.fission {
  background: linear-gradient(
    180deg,
    #000000 0%,
    #ffff00 0%,
    #fffff3 0%,
    #e62e21 0%,
    #fa5f56 17%,
    #feb26f 41%,
    #feb370 100%
  );
  min-height: 100vh;

  .fission-back {
    padding: 26.5px 0 0;
    position: relative;
    .fission-back-right {
      width: 81px;
      height: 183px;
      position: absolute;
      right: 0;
      .right-img {
        width: 100%;
        height: 100%;
      }
    }

    .fission-back-icon {
      width: 54px;
      height: 130px;
      margin-left: 7px;
      position: absolute;
      margin-top: 21px;

      .icon-1 {
        width: 100%;
        height: 100%;
      }
    }

    .fission-back-center {
      position: relative;
      margin-top: 25px;
      height: 322px;

      .back-center-img {
        display: flex;
        justify-content: center;

        .center-img-1 {
          width: 300px;
          height: 312.5px;
          position: absolute;
          display: flex;
          z-index: 1;
          img {
            width: 100%;
            height: 100%;
          }
        }

        .center-img-2 {
          width: 279px;
          height: 310.5px;
          position: absolute;
          z-index: 1;
          display: flex;
          margin-top: 8px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .center-img-3 {
          width: 279px;
          position: relative;
          padding-top: 62.5px;
          z-index: 3;
          text-align: center;

          .center-img-3-rule {
            position: absolute;
            top: 21.5px;
            right: 4.5px;
            display: flex;
            align-items: baseline;

            .rule-title {
              font-size: 12.5px;
              font-family: Hiragino Sans GB;
              font-weight: normal;
              color: #e4a11b;
            }

            .rule-img {
              width: 7.5px;
              height: 10px;
              margin-left: 5px;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }
          .center-img-3-title {
            font-size: 19px;
            font-family: Hiragino Sans GB;
            font-weight: bold;
            color: #e74c45;
          }

          .center-img-3-text {
            font-size: 15px;
            font-family: Hiragino Sans GB;
            font-weight: 500;
            color: #000000;
            margin: 16px 0 14.5px;
          }

          .center-img-3-num {
            font-size: 15px;
            font-family: Hiragino Sans GB;
            font-weight: 500;
            color: #000000;
          }

          .center-img-3-display {
            padding: 0 40px;
            box-sizing: border-box;
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
            .display-list {
              .display-list-title {
                font-size: 15px;
                font-family: Hiragino Sans GB;
                font-weight: 600;
                color: #000000;
              }
              .display-list-price {
                font-size: 15px;
                font-family: Hiragino Sans GB;
                font-weight: bold;
                color: #e74c45;
                margin-top: 5px;
              }
            }
          }

          .center-img-3-button {
            font-size: 15px;
            font-family: Hiragino Sans GB;
            font-weight: 600;
            color: #ffffff;
            text-align: center;
            width: 100px;
            height: 35px;
            background: #e4343f;
            border-radius: 5px;
            line-height: 35px;
            margin: 0 auto;
            margin-top: 18px;
          }
        }
        .center-img-4 {
          position: absolute;
          top: 177px;
          width: 100%;
          height: 147.5px;

          .center-img {
            width: 100%;
            position: relative;
            .icon1-back {
              position: absolute;
              display: flex;
              height: 144.5px;
              width: 100%;
              img {
                width: 95%;
                height: 100%;
                margin: 0 10px;
              }
            }

            .icon2-back {
              height: 185.5px;
              position: relative;
              z-index: 2;
              bottom: 40px;
              margin: 0 10px;

              img {
                width: 100%;
                height: 100%;
              }
            }
            .icon3-back {
              height: 98px;
              position: relative;
              bottom: 140px;
              z-index: 1;
              margin: 0 10px;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }
    .fission-back-table {
      position: relative;
      z-index: 2;
      margin: -3px 10px 67.5px;
      background: #f35647;
      min-height: 40vh;

      /deep/ .van-list {
        position: relative;
      }

      /deep/ .van-list__finished-text {
        color: #fff;
      }

      .table-div {
        position: relative;
        padding: 10px 5px 0;
        box-sizing: border-box;
        background: #f35647;

        .table-div-list {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .table-div-list-left {
            display: flex;
            align-items: center;
            .left-ranking {
              width: 40px;
              font-size: 14px;
              font-family: Hiragino Sans GB;
              font-weight: bold;
              color: #fff;
              text-align: center;
            }
            .left-nickname {
              margin-left: 20px;
              font-size: 14px;
              font-family: Hiragino Sans GB;
              font-weight: bold;
              color: #fff;
            }
          }

          .table-div-list-right {
            display: flex;
            align-items: center;
            .right-spread {
              font-size: 14px;
              font-family: Hiragino Sans GB;
              font-weight: bold;
              color: #fff;
              width: 60px;
              text-align: center;
            }
            .right-earnings {
              margin-left: 20px;
              font-size: 14px;
              font-family: Hiragino Sans GB;
              font-weight: bold;
              color: #fff;
              width: 60px;
              text-align: center;
            }
          }
        }
      }

      .table-list {
        padding: 0 5px;
        box-sizing: border-box;
        position: relative;
        background: #ef876c;

        .table-div-list {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 50px;

          .table-div-list-left {
            display: flex;
            align-items: center;
            .left-ranking {
              width: 40px;
              text-align: center;
              font-size: 14px;
              font-family: Hiragino Sans GB;
              font-weight: bold;
              color: #fff;
            }

            .left-nickname {
              margin-left: 5px;
              display: flex;
              align-items: center;
              .left-nickname-img {
                width: 24.5px;
                height: 24.5px;
                background: linear-gradient(
                  0deg,
                  #ef876c 0%,
                  #fee0c7 0%,
                  #fdd0bc 51%,
                  #fcc4b5 100%
                );
                border-radius: 50%;

                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;
                }
              }
              .left-nickname-title {
                font-size: 12.5px;
                font-family: Hiragino Sans GB;
                font-weight: normal;
                color: #fff;
                margin-left: 5px;
              }
            }
          }

          .table-div-list-right {
            display: flex;
            align-items: center;

            .right-spread {
              font-size: 14px;
              font-family: Hiragino Sans GB;
              font-weight: bold;
              color: #fff;
              width: 60px;
              text-align: center;
            }
            .right-earnings {
              margin-left: 20px;
              font-size: 14px;
              font-family: Hiragino Sans GB;
              font-weight: bold;
              color: #fff;
              width: 60px;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
</style>
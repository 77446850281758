<template>
  <div class="user">
    <div class="user-stremer">
      <streamer :title="title"></streamer>
    </div>

    <div class="user-stremer-lint"></div>

    <div class="user-block" v-html="context"></div>
  </div>
</template>

<script>
import streamer from "../../components/streamer/streamer.vue";
import { loginAgreement } from "../../api/login";

export default {
  components: {
    streamer,
  },
  created() {
    // 用户协议
    this.agreementMet();
  },
  data() {
    return {
      title: "", // 标题
      context: "",
    };
  },
  methods: {
    agreementMet() {
      let params = {
        type: 0,
      };

      loginAgreement(params).then((res) => {
        if (res.code == 200) {
          this.context = res.result.content;
          this.title = res.result.title;
        }
      });
    },
  },
};
</script>


<style lang="less" scoped>
.user {
  .user-stremer {
    width: 100%;
    height: 90.5px;
    position: fixed;
    z-index: 9;
    top: 0;
  }
  .user-stremer-lint {
    height: 90.5px;
  }
  .user-block {
    padding: 20px;
    box-sizing: border-box;
    .user-block-p {
      text-align: left;
      font-size: 12.5px;
      font-family: Hiragino Sans GB;
      font-weight: normal;
      color: #000000;
      line-height: 24px;
      text-indent: 30px;
      margin-bottom: 20px;
      letter-spacing: 1px;
    }

    .user-block-display {
      display: flex;
      align-items: center;
      margin: 0 0 20px;
      .display-img {
        height: 19px;
        width: 19px;
        margin-right: 10px;

        img {
          width: 100%;
          height: 100%;
        }
      }
      .display-title {
        color: #e74c45;
        font-size: 14.5px;
        font-weight: bold;
      }
    }
    .user-block-h {
      font-size: 13.5px;
      font-weight: bold;
      color: #000000;
      text-align: left;
      margin-bottom: 10px;
    }
  }
}
</style>
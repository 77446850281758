var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"order"},[_c('div',{staticClass:"order-streamer"},[_vm._m(0),_c('div',{staticClass:"order-streamer-back"},[_vm._m(1),_c('div',{staticClass:"back-display"},[_c('div',{staticClass:"display-left",on:{"click":_vm.backRight}},[_c('img',{attrs:{"src":require("../../assets/common/common-left.png"),"alt":""}})]),_c('div',{staticClass:"display-center"},[_vm._v("接单管理")]),_c('div',{staticClass:"display-right"})])]),_c('div',{staticClass:"order-streamer-tabs"},[_c('van-tabs',{on:{"change":_vm.activeMet},model:{value:(_vm.activeTabs),callback:function ($$v) {_vm.activeTabs=$$v},expression:"activeTabs"}},_vm._l((5),function(item,index){return _c('van-tab',{key:index,attrs:{"title":index == 0
              ? '全部'
              : index == 1
              ? '待提交'
              : index == 2
              ? '审核中'
              : index == 3
              ? '已通过'
              : index == 4
              ? '未通过'
              : ''}})}),1)],1)]),_c('div',{staticClass:"order-lint"}),_c('div',{staticClass:"order-table"},[_c('van-list',{attrs:{"finished":_vm.finished,"finished-text":_vm.list.length == 0 ? '' : ''},on:{"load":_vm.onLoad},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('div',{staticClass:"order-table-block"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"block-list"},[_c('div',{staticClass:"block-list-left"},[_c('div',{staticClass:"left-img"},[_c('img',{attrs:{"src":item.mainImg,"alt":""}})]),_c('div',{staticClass:"left-center"},[_c('div',{staticClass:"left-center-title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"left-center-text"},[_c('div',{staticClass:"text-list"},[_vm._v(" "+_vm._s(item.type == 0 ? "小程序体验" : item.type == 1 ? "关注体验" : item.type == 2 ? "砍掉互帮" : item.type == 3 ? "试用试玩" : item.type == 4 ? "高阶任务" : item.type == 5 ? "其他任务" : "")+" ")]),_c('div',{staticClass:"text-list"},[_vm._v(" "+_vm._s(item.name)+" ")])]),(item.status == 1)?_c('div',{staticClass:"left-center-lint"}):_vm._e()])]),_c('div',{staticClass:"block-list-right"},[_c('div',{staticClass:"right-title"},[_vm._v("+"+_vm._s(item.amountUnit))]),_c('div',{staticClass:"right-status",style:({
                  background:
                    item.status == 1
                      ? '#F7D134'
                      : item.status == 2
                      ? '#FF7E27'
                      : item.status == 3
                      ? '#47B645'
                      : item.status == 4
                      ? '#DE1B12'
                      : item.status == 5
                      ? '#999999'
                      : '',
                })},[_vm._v(" "+_vm._s(item.status == 1 ? "待提交" : item.status == 2 ? "审核中" : item.status == 3 ? "已通过" : item.status == 4 ? "未通过" : item.status == 5 ? "已放弃" : "")+" ")])]),(item.status == 1)?_c('div',{staticClass:"block-list-time"},[_vm._v(" 请在 "),_c('span',{staticStyle:{"color":"#d52921","margin":"0 5px"}},[_c('van-count-down',{attrs:{"time":item.submitResidueTime}})],1),_vm._v(" 内完成提交，逾期自期自期自期自动取消 ")]):_vm._e()])}),0),_c('empty',{attrs:{"list":_vm.list}})],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"order-streamer-img"},[_c('img',{attrs:{"src":require("../../assets/signin/signin-bill-streamer.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"back-img"},[_c('img',{attrs:{"src":require("../../assets/signin/signin-bill-back.png"),"alt":""}})])
}]

export { render, staticRenderFns }